import React, { useState,useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import first_bg from "../../assets/images/First_bg.png";
import yellow_line from "../../assets/images/Yellow_line.png";
import raiffeisen from "../../assets/images/Raiffeisen_bg.png";
import donika from "../../assets/images/Donika.png";
import fitim from "../../assets/images/Fitim.png";
import ribk from "../../assets/images/Ribk.png";
import man_walking from "../../assets/images/Man_walking.png";
import man_walking2 from "../../assets/images/man_walking2.png";
import PrisigLogo from "../../assets/icons/Prisig.svg";
import SiguriaLogo from "../../assets/icons/Siguria.svg";
import ElsigLogo from "../../assets/icons/Elsig.svg";
import IllyriaLogo from "../../assets/icons/Illyria.svg";
import ScardianLogo from "../../assets/icons/Scardian.svg";
import SigalLogo from "../../assets/icons/Sigal.svg";
import SigkosLogo from "../../assets/icons/Sigkos.svg";
import DukagjiniLogo from "../../assets/icons/Dukagjini.svg";
import EurosigLogo from "../../assets/icons/Eurosig.svg";
import SigmaLogo from "../../assets/icons/Sigma.svg";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookie from 'js-cookie';
import { use } from "i18next";
import Cookies from "js-cookie";

const AboutUs = () => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState("al");
  const [isLoggedIn,setIsLoggedIn] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const paragraphs = [
    t("ribk_description"),
    t("ribk_description"),
    t("ribk_description"),
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  
  useEffect(() => {
    const token = Cookies.get('token');
    if (token){
      setIsLoggedIn(true);
    }
    else{
      setIsLoggedIn(false);
    }
  })
 
  return (
    <div className="overflow-hidden">
       {isLoggedIn ? (
    <div className="bg-white fixed w-full shadow-md z-50  ">
        <Navbar type="authentication" authType="loggedIn" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
      </div>
      ):(
      <div>
      <Navbar selectedValue={selectedValue} handleSelectChange={handleSelectChange}/>
      </div>
     )}
      <div className="relative">
        <img
          src={first_bg}
          alt="Big"
          className="h-[18vh] md:h-[22vh] lg:h-auto w-full"
        />
        <div className="text-center absolute inset-0 flex justify-center items-center text-white text-4xl md:text-6xl">
          <h1 className="md:w-[80%] px-16 font-sans text-xs md:text-xl lg:text-5xl leading-snug">
            {t("right_insurance_policy")}
          </h1>
        </div>
      </div>
      <div className="relative">
        <img
          src={raiffeisen}
          alt="raiffeisenbg"
          className="w-full h-[170px] md:h-[420px] lg:h-auto object-cover"
        />
        <img
          src={yellow_line}
          alt="yellowline"
          className="absolute md:ml-5 lg:ml-0 top-8 w-[10%] md:w-auto md:top-16 lg:top-24 left-1/2 transform-translate-x-1/2 -translate-y-1/2 translate-x-[-46%]"
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center w-[80%] mx-auto">
          <h1 className="text-[#171d25] text-[8px] md:text-lg lg:text-3xl  font-sans pt-8 md:pt-10 lg:pt-0 md:pb-4 lg:pb-6">
            {t("independent_entity")}
          </h1>
          <p className="font-regular text-[6px] md:text-sm lg:text-base items-end pt-2 lg:pb-[16px]">
            {t("achieve_the_goal")}
          </p>
        </div>
      </div>

      <div className="w-1440px h-764px flex flex-col justify-start items-center mt-546px p-80px md:p-144px bg-[#f5f6fa]">
        <h1 className="w-[60%] text-center text-[#212b36] font-sans text-sm md:text-2xl lg:text-4xl leading-40 pt-10 lg:pt-20 ">
          {t("management_board")} <br></br> {t("ibk")}
        </h1>
        <div className="md:flex gap-8 pb-10 md:pb-40 pt-10 lg:pt-20 md:w-[80%] lg:w-auto md:mx-auto lg:mx-0 ">
          <div className="p-2 bg-white rounded-lg shadow-lg w-[50%] mx-auto  md:mx-0">
            <h3 className="pt-4 font-semibold text-sm md:text-lg lg:text-2xl text-center">
              Donika Hasani
            </h3>
            <p className="text-[#637381] font-regular pb-4 text-center text-xs md:text-base lg:text-lg">
              {t("executive_director")}
            </p>
            <img
              src={donika}
              alt="donikaphoto"
              className="w-306 md:w-70 h-auto rounded-lg "
            />
          </div>
          <div className="p-2 bg-white rounded-lg shadow-lg w-[50%]  mx-auto  md:mx-0 mt-4 md:mt-0">
            <h3 className="pt-4 font-semibold text-center text-sm md:text-lg lg:text-2xl">
              Fitim Goga
            </h3>
            <p className="text-[#637381] font-regular pb-4 text-center text-xs md:text-base lg:text-lg">
              {t("director_of_operations")}
            </p>
            <img
              src={fitim}
              alt="fitimphoto"
              className="w-306 md:w-70 h-auto rounded-lg "
            />
          </div>
        </div>
      </div>
      <div className="relative flex items-center justify-center">
        <img src={ribk} alt="img bg" />
        <h1 className="w-[80%] mx-auto absolute inset-0 flex items-center text-white md:text-2xl lg:text-5xl font-sans">
          {t("ribk")}
        </h1>
        <div className="absolute top-0 right-0 h-full w-1/2 ">
          <Slider {...settings} className="h-full w-[80%] md:w-[90%]  ">
            {paragraphs.map((paragraph, index) => (
              <div
                key={index}
                className="h-full flex items-center justify-center lg:my-1  "
              >
                <p className="text-white text-[6px]  md:text-[10px] lg:text-xl mt-10 md:mt-28  font-regular lg:mt-[35%] backdrop-blur-lg py-4  px-4  lg:pt-16  md:px-5 rounded-xl ">{paragraph}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="lg:flex">
        <img
          src={man_walking2}
          alt="manwalking2"
          className="lg:hidden mx-auto lg:mx-0 md:w-[70%]"
        ></img>
        <img
          src={man_walking}
          alt="man walking"
          className="hidden lg:block pt-4 lg:pt-36 mx-auto lg:ml-14 w-[70%] lg:w-auto"
        ></img>

        <div className="flex-grow font-sans lg:pt-56">
          <h1 className="md:text-4xl lg:text-5xl md:px-10 lg:px-0 font-worksans font-extrabold items-right  px-6 md:pl-10 text-center md:text-justify-right lg:text-left">
            {t("our_experience")}
            <br className="hidden  lg:inline"></br>
            {t("collaborations")}
            <br className="hidden lg:inline"></br>
            {t("insurance_broker")}
          </h1>
          <p className="md:text-16 font-regular px-6 md:px-10 lg:px-0 lg:pr-40 md:pl-10 pt-6 text-center md:text-justify-center lg:text-left">
            {t("experience_description")}
          </p>
        </div>
      </div>
      <div className="relative">
        <div className=" mx-auto mt-[15%] md:mt-[10%] lg:mt-[10%] w-[230px] h-[230px] rounded-[100%]  portfolio-bg  text-center text-[#313a3c] px-1 relative">
          <p className="font-bold text-[40px] pt-[26%] z-50">{t("partners")}</p>
        </div>
        <div className="bg-[#2b2e33] h-[1100px] md:h-[380px] lg:h-[450px] pt-10 pb-20 mt-[-25%] md:mt-[-5%] lg:mt-[-7%]">
          <div className="grid md:grid-cols-5 mt-[20%] md:mt-[10%] gap-[20%] md:gap-[5%] ml-[32%] md:mx-[4%] lg:mx-[15%]">
            <img
              src={PrisigLogo}
              alt="prisiglogo"
              className="cursor-pointer pt-6 md:pt-0"
            />
            <img
              src={SiguriaLogo}
              alt="sigurialogo"
              className="cursor-pointer"
            />
            <img src={ElsigLogo} alt="elsiglogo" className="cursor-pointer" />
            <img
              src={IllyriaLogo}
              alt="illyriaLogo"
              className="cursor-pointer"
            />
            <img
              src={ScardianLogo}
              alt="scardianLogo"
              className="cursor-pointer"
            />
          </div>
          <div className="grid md:grid-cols-5 mt-[40%] md:mt-[5%] gap-[20%] md:gap-[1%] ml-[32%] md:mx-[7%] lg:mx-[20%]">
            <img
              src={SigalLogo}
              alt="sigalLogo"
              className="cursor-pointer pt-16 md:pt-0"
            />
            <img src={SigkosLogo} alt="sigalLogo" className="cursor-pointer" />
            <img
              src={DukagjiniLogo}
              alt="dukagjiniLogo"
              className="cursor-pointer"
            />
            <img
              src={EurosigLogo}
              alt="eurosigLogo"
              className="cursor-pointer"
            />
            <img
              src={SigmaLogo}
              alt="sigmaLogo"
              className="md:mt-[-12%] cursor-pointer"
            />
          </div>
        </div>
      </div>
      <div className="relative bottom-0 landing-font">
        <Footer landing="landing" />
      </div>
    </div>
  );
};

export default AboutUs;
