import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Background from "../../assets/images/raiffeisen-bg.png";
import axios from 'axios';
import InsurexLogoX from "../../assets/images/insurex-logo-x-green.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import config from '../../config';
import api from '../../services/api';



const LogIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);

  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');

  const [isLoading, setIsLoading] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    // const newUsername = event.target.value;
    // setUsername(newUsername);
    Cookies.set('username', { expires: 7 });
 
    if (!username) {
      setErrorMessage('Please enter a username');
      return;
    }
  
    if (!password) {
      setErrorMessage('Please enter a password');
      return;
    }

    // const recaptchaValue = recaptchaRef.current.getValue();
    // if (!recaptchaValue) {
    //   setErrorMessage('Please complete the reCAPTCHA');
    //   return;
    // }
    setIsLoading(true);
    try {

      // Get all cookies
      const allCookies = Cookies.get();

      // Loop through and remove each cookie
      for (let cookieName in allCookies) {
        Cookies.remove(cookieName);
      }

      const loginResponse = await api.post('/users/api-login/', {
        username: username,
        password: password,
      });

      Cookies.set('response_username', loginResponse.data.username, { expires: 1 });
      Cookies.set("token", loginResponse.data.token, { expires: 1 });
      Cookies.set("refresh_token", loginResponse.data.refresh_token, { expires: 1 });
      Cookies.set("legal_number", loginResponse.data.legal_number, { expires: 1 });
      Cookies.set("user_id", loginResponse.data.user_id, { expires: 1 });

      if (loginResponse.status === 200) {
        try {
          const localStorageLegalNumber = Cookies.get('legal_number');
          const doesCustomerExistsResponse = await axios.get(
            `${config.API_BASE_URL}/customers/${localStorageLegalNumber}/does-customer-exists-api`, {
          }
          );
          // Cookies.set("id", response2.data.id, { expires: 1 });
          // Cookies.set("bank_account_number_locally", response2.data.bank_account_number, { expires: 1 });
          if (doesCustomerExistsResponse.status !== 200) {
            throw new Error('Customer does not exist');
          }

          navigate(`/home?username=${username}`);



        } catch (error) {
          // console.error(error);
          Object.keys(Cookies.get()).forEach(cookieName => Cookies.remove(cookieName));
          setErrorMessage('This customer does not exist');
        }
      }
    } catch (error) {
      // console.error(error);
      setErrorMessage('Invalid username or password');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const savedUsername = Cookies.get('username');
    if (savedUsername === null) {
      setUsername(savedUsername);
    }
  }, []);


  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/home');
    }
  }, [navigate]);

  const YourCircularIndicatorComponent = () => {
    return (
      <div className="flex justify-center items-center">
        <div style={{ borderColor: '#FFDD00' }} className="w-6 h-6 border-t-4 border-yellow-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  };

  return (
    <div>
      <img src={Background} alt="" className="bg-[#726f6f] fixed w-full h-full object-cover overflow-hidden" />
      <div className="relative">
        <Navbar type="authentication" landing="landing" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
      </div>
      <form onSubmit={handleLogin}>
        <div className="md:flex md:flex-col items-center justify-center px-6 md:py-6 mx-auto lg:py-0 relative ">
          <img className=" pb-4 mx-auto" src={InsurexLogoX} alt="" />
          <p className="text-[#9b9b9b] pb-8 text-sm up text-center font-regular">{t('insurance_platform')}</p>
          <div className="bg-white mt-8 md:mt-8 lg:mt-2 rounded-md lg:mx-auto mb-10 px-[25px] md:px-[30px] ">
            <div>
              <h1 className="font-semibold  text-center text-[#282d30] pt-8 pb-4 md:pb-10 md:pt-14 text-lg md:text-2xl"> {t('sign_into')} </h1>
            </div>
            <div className="justify-center items-center mb-2 md:mb-4  ">
              <input className="text-[#0f1d31] bg-[#f5f6fa]  font-regular p-3 w-full md:w-[380px] h-[52px] text-sm"
                value={username} onChange={(event) => setUsername(event.target.value)}
                type="username" placeholder={t('username')} id="username" name="username" />
            </div>
            <div className="md:mt-2 ">
              <input className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-full md:w-[380px] h-[52px] text-sm "
                placeholder={t('password')} id="password" name="password"
                type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
            </div>
            {/* <Link to="/reset-password">
              <div className="flex justify-end">
                <p className="text-[15px]  lg:pb-2 md:pt-4 text-[#171d25] leading-[24px] lg:text-right hover:underline">
                  {t('forgot_password')}
                </p>
              </div>
            </Link> */}
            {/* <ReCAPTCHA
              className="mb-4"
              ref={recaptchaRef}
              sitekey="6LfPuxkkAAAAAF8PdODsInhKtCMTuZo8SpxsuwWM"
              onChange={() => setErrorMessage('')} // Clear reCAPTCHA error on change
            /> */}
            {errorMessage && <p className="text-sm font-medium text-red-700">{errorMessage}</p>}

            {/* <div className="flex justify-start">
              <div className="flex items-center md:ml-2  pt-1 pr-2">
                <input id="remember" name="remember" type="checkbox" className="h-4 w-4 text-[#313a3c]  rounded accent-yellow-300" />
              </div>
              <div className="">
                <label htmlFor="remember" className="color-[#313a3c] text-[14px] md:pb-6 font-bold">
                  {t('keep_me_signed_in')}
                </label>
              </div>
            </div> */}
            <div className="mb-2 md:mb-0 ">
              <button type="submit" className="w-full md:w-[380px] h-[52px]  rounded-md text-[#fff] mt-4  font-medium uppercase bg-[#313a3c] hover:bg-[#374151]">
                {isLoading === true ? <YourCircularIndicatorComponent /> : t('sign_in')}
              </button>
              <Link to="/register">
                <div className="pb-2">
                  <button type="button" className="w-full md:w-[380px] h-[52px]  rounded-md text-[#313a3c] mt-2   mb-2 font-medium uppercase bg-[#fff] hover:bg-[#f5f6fa]">
                    {t('register')}
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </form>
      <div className="bottom-0 w-full  fixed">
        <Footer />
      </div>
    </div>
  );
};

export default LogIn;
