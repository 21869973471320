import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import LandingPage from './pages/landingPage/LandingPage';
import Home from './pages/home/Home';
import ProductDetails from './pages/home/ProductDetails';
import LogIn from './pages/auth/LogIn';
import ForgotPassword from './pages/auth/ForgotPassword';
import Register from './pages/auth/Register';
import NonRBClientRegister from './pages/auth/NonRBClientRegister';
import CliRequest from './pages/request/CliRequest';
import ResetPassword from './pages/auth/ResetPassword';
import ChangePassword from './pages/auth/ChangePassword';
import OtpPasswordForm from './pages/auth/OtpPasswordForm';
import Profile from "./pages/user/Profile";
import TermsOfUse from './pages/terms-of-use/TermsOfUse';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Contact from './pages/contact/Contact';
import Help from './pages/help-and-faqs/HelpAndFAQs';
import AboutUs from './pages/about-us/AboutUs';
import SigurimiKaskoIAutomjetit from './components/products/SigurimiKaskoIAutomjetit';
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import Cookie from "./pages/cookie/Cookie";
import { useTranslation } from 'react-i18next';
import Documents from './pages/documents/Documents';
import ClaimReports from './pages/claim-report/ClaimReports';
import ReportedClaim from './pages/claim-report/ReportedClaim';



function App() {
  const { t } = useTranslation();

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/product-details/:productIndex" element={<ProductDetails />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/login/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/nonrbclient-register" element={<NonRBClientRegister />} />
          <Route path="/cli-request" element={<CliRequest />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path='/change-password' element={<ChangePassword />} />
          <Route path='/otp-password' element={<OtpPasswordForm />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/help" element={<Help />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/sigurimi-kasko-i-automjetit" element={<SigurimiKaskoIAutomjetit />} />
          <Route path="/cookie" element={<Cookie />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/claim-report" element={<ClaimReports />} />
          <Route path="/reported-claim" element={<ReportedClaim />} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
