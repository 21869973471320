import React, { useState, useRef, useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import Minus from '../../assets/icons/minus-icon.svg'
import Plus from '../../assets/icons/plus-icon.svg'
import Video from '../../assets/video/tutorial-video.mp4'
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie';

const HelpAndFAQs = () => {
    const [activeQuestionId, setActiveQuestionId] = useState(null);
    const manualiRef = useRef(null);

    const toggleCollapse = (questionId) => {
        setActiveQuestionId(activeQuestionId === questionId ? null : questionId);
    };

    const scrollToManuali = () => {
        if (manualiRef.current) {
            manualiRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState('al');
    const [isLoggedIn, setIsLoggedIn] = useState(false);


    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };


    useEffect(() => {
        const token = Cookie.get('token');
        if (token) {
            setIsLoggedIn(true);
        }
        else {
            setIsLoggedIn(false);
        }
    })

    return (
        <div className='overflow-hidden'>
            {isLoggedIn ? (
                <div className="bg-white fixed w-full shadow-md z-50  ">
                    <Navbar type="authentication" authType="loggedIn" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
                </div>
            ) : (
                <div>
                    <Navbar selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
                </div>
            )}
            <div className='bg-[#161c24]'>
                <div className='h-[300px] flex justify-center items-center'>
                    <div>
                        <div className='text-center'>
                            <h1 className='text-white text-2xl md:text-5xl   WorkSans-Bold items-center pb-10'> {t('how_can_we_help')} </h1>
                        </div>
                        <div className="relative ">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" className="w-5 h-5 text-gray-500 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <input type="search" id="default-search" className="block w-[340px] md:w-[741px] p-4 pl-10 text-sm text-white border-[1px] rounded-lg bg-[#161c24]" placeholder="Search Support" required  ></input>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-[50px] md:pt-[116px]' >
                <h1 className='text-3xl md:text-5xl font-extrabold text-[#171d25] text-center'> {t('user_manual')} </h1>
                <p className='text-center justify-center pt-4  md:pb-10  lg:pb-16 text-[#171d25] px-6 text-[14px] worksans-font'>Vitae auctor phasellus sit lectus quis. Mattis cursus ut sed amet. Facilisis turpis enim eget <br></br> lectus eget. Convallis sit amet vel et erat. Morbi tortor gravida blandit in eleifend.</p>
            </div>

            <div className='flex justify-center'>
                <video className=' h-[300px] w-[350px] md:h-[536px] md:w-[750px]  lg:h-[536px] lg:w-[1400px] mb-16 md:mb-32' controls>
                    <source src={Video} type="video/mp4" />
                </video>
            </div>
            <div className='bg-[#f5f6fa] md:justify-center md:items-center pt-[ 10px] md:pt-[70px] '>
                <h1 className='text-3xl md:text-5xl font-extrabold text-[#171d25] text-center pb-10 pt-10 md:pb-12 md:pt-0'>{t('asked_questions')}</h1>
                <div className='md:flex container  md:justify-center mx-auto workSans-font px-6 md:px-0'>
                    <div className='md:pl-0 lg:pl-0 md:px-4'>
                        <div className='bg-white rounded-xl shadow-xl flex md:justify-left  md:mr-0 py-2 mb-6 '>
                            <div className='px-8'>
                                <div className='flex items-center'>
                                    <p className='text-[#171d25] text-[16px] md:text-[20px] text-left pt-[25px] pb-6'>{t('types_of_policies')}</p>
                                    <div className='ml-auto flex lg:pl-56'>
                                        {activeQuestionId === 'question1' ? (
                                            <img src={Minus} className='' alt="Close" onClick={() => toggleCollapse('question1')} />
                                        ) : (
                                            <img src={Plus} className='' alt="Open" onClick={() => toggleCollapse('question1')} />
                                        )}
                                    </div>
                                </div>
                                {activeQuestionId === 'question1' && (
                                    <p className='text-[#6f6c90] text-[14px] md:text-[16px] text-left pb-14'>
                                        Ut enim ad minim veniam quis nostrud <br></br> exercitation ullamco laboris nisi ut aliquip ex ea <br></br>commodo consequat aute irure dolor
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='bg-white rounded-xl shadow-xl py-2'>
                            <div className='px-8'>
                                <div className='flex'>
                                    <p className='text-[#171d25] text-[16px] md:text-[20px] text-left pt-[25px] md:items-center md:pt[25px] pb-6'> {t('best_rate')} </p>
                                    <div className='ml-auto md:items-center md:justify-center 
                                     flex'>
                                        {activeQuestionId === 'question2' ? (
                                            <img src={Minus} className='' alt="Close" onClick={() => toggleCollapse('question2')} />
                                        ) : (
                                            <img src={Plus} className='' alt="Open" onClick={() => toggleCollapse('question2')} />
                                        )}
                                    </div>
                                </div>
                                {activeQuestionId === 'question2' && (
                                    <p className='text-[#6f6c90] text-[14px] md:text-[16px] text-left pb-14'>
                                        Ut enim ad minim veniam quis nostrud <br></br> exercitation ullamco laboris nisi ut aliquip ex ea <br></br>commodo consequat aute irure dolor
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='pt-6 pb-[25px]'>
                            <div className='bg-white rounded-xl shadow-xl py-2'>
                                <div className='px-8'>
                                    <div className='flex'>
                                        <p className='text-[#171d25] text-[16px] md:text-[20px] text-left pt-[25px] pb-6 '> {t('claims_process')} </p>
                                        <div className='ml-auto flex md:items-center md:justify-center'>
                                            {activeQuestionId === 'question3' ? (
                                                <img src={Minus} alt="Close" onClick={() => toggleCollapse('question3')} />
                                            ) : (
                                                <img src={Plus} alt="Open" onClick={() => toggleCollapse('question3')} />
                                            )}
                                        </div>
                                    </div>
                                    {activeQuestionId === 'question3' && (
                                        <p className='text-[#6f6c90]  text-[14px] md:text-[16px] text-left pb-14'>
                                            Ut enim ad minim veniam quis nostrud <br></br> exercitation ullamco laboris nisi ut aliquip ex ea <br></br>commodo consequat aute irure dolor
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='md:pl-0 lg:pl-0 md:px-4'>
                        <div className=''>
                            <div className='bg-white rounded-xl shadow-xl py-2'>
                                <div className='px-8'>
                                    <div className='flex'>
                                        <p className='text-[#171d25]  text-[16px] md:text-[20px] text-left pt-[25px] pb-6'> {t('policy_changes')} </p>
                                        <div className='ml-auto flex md:items-center md:justify-center lg:pl-56'>
                                            {activeQuestionId === 'question4' ? (
                                                <img src={Minus} alt="Close" onClick={() => toggleCollapse('question4')} />
                                            ) : (
                                                <img src={Plus} alt="Open" onClick={() => toggleCollapse('question4')} />
                                            )}
                                        </div>
                                    </div>
                                    {activeQuestionId === 'question4' && (
                                        <p className='text-[#6f6c90] text-[14px] md:text-[16px] text-left pb-14'>
                                            Ut enim ad minim veniam quis nostrud <br></br> exercitation ullamco laboris nisi ut aliquip ex ea <br></br>commodo consequat aute irure dolor
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='pt-6 pb-4 md:pb-4'>
                            <div className='bg-white rounded-xl shadow-xl py-2'>
                                <div className='px-8'>
                                    <div className='flex'>
                                        <p className='text-[#171d25] text-[16px] md:text-[20px] text-left pt-[25px] md:pt-[25px] pb-6'> {t('coverage')} </p>
                                        <div className='ml-auto flex'>
                                            {activeQuestionId === 'question6' ? (
                                                <img src={Minus} alt="Close" onClick={() => toggleCollapse('question6')} />
                                            ) : (
                                                <img src={Plus} alt="Open" onClick={() => toggleCollapse('question6')} />
                                            )}
                                        </div>
                                    </div>
                                    {activeQuestionId === 'question6' && (
                                        <p className='text-[#6f6c90] text-[14px] md:text-[16px] text-left pb-14'>
                                            Ut enim ad minim veniam quis nostrud <br></br> exercitation ullamco laboris nisi ut aliquip ex ea <br></br>commodo consequat aute irure dolor
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='pt-2 md:pt-2 pb-8'>
                            <div className='bg-white rounded-xl shadow-xl py-2'>
                                <div className='px-8'>
                                    <div className='flex'>
                                        <p className='text-[#171d25]  text-[16px] md:text-[20px] text-left pt-[25px] pb-6'> {t('discounts_available')} </p>
                                        <div className='ml-auto flex'>
                                            {activeQuestionId === 'question5' ? (
                                                <img src={Minus} alt="Close" onClick={() => toggleCollapse('question5')} />
                                            ) : (
                                                <img src={Plus} alt="Open" onClick={() => toggleCollapse('question5')} />
                                            )}
                                        </div>
                                    </div>
                                    {activeQuestionId === 'question5' && (
                                        <p className='text-[#6f6c90] text-[14px] md:text-[16px] text-left pb-14'>
                                            Ut enim ad minim veniam quis nostrud <br></br> exercitation ullamco laboris nisi ut aliquip ex ea <br></br>commodo consequat aute irure dolor
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative mt-[25%] md:mt-[10%] lg:mt-[0%] bottom-0 landing-font">
                <Footer landing="landing" />
            </div>
        </div>
    )
}



export default HelpAndFAQs


