import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import config from '../../config';
import Cookies from 'js-cookie';
import Success from '../../assets/icons/success-icon.svg';
import Email from '../../assets/icons/Email.png';
import Modal from 'react-modal';
import XButton from '../../assets/icons/x-icon.svg';

Modal.setAppElement(document.getElementById('root'));

const Documents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [documentCheckboxes, setDocumentCheckboxes] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [WaitingMessage, setWaitingMessage] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoadingForCancelingPolicy, setIsLoadingForCancelingPolicy] =
    useState(false);
  const [isLoadingForPurchase, setIsLoadingForPurchase] = useState(false);
  const [currentDate] = useState(new Date().toISOString().slice(0, 10));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [documentId, setDocumentId] = useState('');
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [keepDocumentOpenModal, setKeepDocumentOpenModal] = useState(false);
  const [isDocumentSigned, setIsDocumentSigned] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = 'Are you sure you want to leave?';
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestId = Cookies.get('request_id');
        const apiUrl = `/requests/api/filled-documents/${requestId}/`;
        const response = await api.get(apiUrl);

        if (
          Array.isArray(response.data) &&
          response.data.length > 0 &&
          'title' in response.data[0]
        ) {
          setDocuments(response.data);

          const checkboxes = {};
          response.data.forEach((document) => {
            checkboxes[document.id] = false;
          });
          setDocumentCheckboxes(checkboxes);
        } else {
        }
      } catch (error) { }
    };
    fetchData();
  }, []);

  const fetchCancelPolicy = async () => {
    setIsLoadingForCancelingPolicy(true);
    try {
      const requestId = Cookies.get('request_id');
      const ribkCode = Cookies.get('ribk_code');
      const body = {
        ribk: ribkCode,
        cancel_reason: '4',
        cancel_date: currentDate,
        cancel_notes: 'Canceled automatically from the system',
      };
      const apiUrl = `/requests/api/request-cancel/${requestId}/`;
      const cancelPolicyResponse = await api.post(apiUrl, body);
      if (cancelPolicyResponse.status === 200) {
      } else {
        setErrorMessage('Ka ndodhur një gabim. Ju lutem provoni përsëri.');
      }
    } catch (error) { }
    setIsLoadingForCancelingPolicy(false);
    navigate('/');
  };

  const handleSuccessClose = async () => {
    setIsLoadingForPurchase(true);
    try {
      const requestId = Cookies.get('request_id');
      const sendEmailApiUrl = `/requests/api/send-email-documents/${requestId}/`;

      const sendEmailResponse = await api.post(sendEmailApiUrl);

      if (sendEmailResponse.status === 200) {
      } else {
        const responseText = await sendEmailResponse.text();
        setErrorMessage(
          `Failed to send email. Server response: ${responseText}`
        );
      }
    } catch (error) {
      console.error('An error occurred while sending the email:', error);
      setErrorMessage('Ka ndodhur një gabim. Ju lutem provoni përsëri.');
    }

    setIsLoadingForPurchase(false);
    setShowSuccess(true);

    setTimeout(() => {
      setShowSuccess(false);
      setWaitingMessage(true);

      setTimeout(() => {
        setWaitingMessage(false);
        navigate('/');
      }, 2000);
    }, 0);
  };

  const handleDocumentClick = async (pdfUrl) => {

    try {
      const response = await api.get(pdfUrl, {
        responseType: 'blob',
      });

      const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      setPdfUrl(fileURL);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const handleDocumentId = (documentId) => {
    setDocumentId(documentId);
  };

  const closeModal = () => {
    setPdfUrl('');
    setIsModalOpen(false);
    setShowConfirmation(false);
  };

  const handleCheckboxChange = () => {
    const updatedCheckboxes = {
      ...documentCheckboxes,
      [documentId]: !documentCheckboxes[documentId],
    };
    setDocumentCheckboxes(updatedCheckboxes);
    const allChecked = Object.values(updatedCheckboxes).every(value => value);
    setIsChecked(allChecked);
    const checkboxValue = updatedCheckboxes[documentId];
    if (checkboxValue) {
      setShowConfirmation(true);
    } else {
      setKeepDocumentOpenModal(true);
    }
  };

  const handleDocumentSigned = () => {
    setIsDocumentSigned(true);
  };

  const keepDocumentOpen = () => {
    setIsModalOpen(true);
    setKeepDocumentOpenModal(false);
  };

  const closeDocument = () => {
    setIsModalOpen(false);
    setKeepDocumentOpenModal(false);
  };

  const handleCancelButtonClick = () => {
    setErrorMessage('');
    setDocumentCheckboxes({});
    setCancelConfirmation(true);
    closeModal();
  };

  const handleFinishButtonClick = () => {
    if (!isChecked) {
      setErrorMessage(
        'Ju lutem lexoni dokumentin dhe jep nënshkrimin elektronik në të gjitha dokumentet!'
      );
    } else {
      setErrorMessage('');
      const allChecked = Object.values(documentCheckboxes).every(
        (value) => value
      );
      if (allChecked) {
        setShowSuccess(true);
      }
    }
  };

  const handleContinue = () => {
    setCancelConfirmation(false);
  };

  const YourCircularIndicatorComponent = () => {
    return (
      <div className='flex justify-center items-center'>
        <div
          style={{ borderColor: '#FFDD00' }}
          className='w-6 h-6 border-t-4 border-yellow-500 border-solid rounded-full animate-spin'></div>
      </div>
    );
  };

  return (
    <div className='bg-[#f5f6fa] min-h-screen'>
      <div className='pt-[150px] container mx-auto'>
        <div className='bg-white p-10 rounded-xl mx-auto shadow-xl'>
          <p className='text-2xl mb-6 font-semibold'>{t('confirm')}</p>
          <div className=''>
            {documents.map((document) => (
                <div key={document.id} className='pb-5 flex'> 
                <div
                  className={`flex-grow items-center p-4 rounded-md border border-[#e5e9f2] ${documentCheckboxes[document.id]
                    ? 'border-green-500 text-[#008a1c] hover:bg-[#f8fffa]'
                    : 'border-[#e5e9f2]'
                    }`}>
                  <div
                    className={`text-black text-left cursor-pointer text-[14px] ${documentCheckboxes[document.id]
                      ? 'border-green-500 text-[#008a1c]'
                      : 'border-[#e5e9f2]'
                      }`}>
                    {document.title}
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      handleDocumentClick(
                        `${config.API_BASE_URL}${document.product_file}`
                      );
                      handleDocumentId(document.id);
                    }}
                    className={`ml-3 text-black cursor-pointer text-[14px] border p-4 mt-0.5 rounded-md hover:bg-[#dedede22] ${documentCheckboxes[document.id]
                      ? 'border-green-500 text-[#008a1c] hover:bg-[#f8fffa]'
                      : 'border-[#e5e9f2]'
                      }`}>
                    {documentCheckboxes[document.id]
                      ? t('document_signed')
                      : t('open_document')}
                  </button>
                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel='PDF Modal'
                    className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md w-4/5 h-4/5 pl-40 ml-16'>
                    <iframe
                      src={pdfUrl}
                      width='75%'
                      height='100%'
                      title='PDF Document'
                    />
                    <div>
                      <div className=' pt-3 rounded-md text-black'>
                        <label className='flex text-[11px]'>
                          <input
                            type='checkbox'
                            checked={documentCheckboxes[documentId]}
                            onChange={() => handleCheckboxChange()}
                            className='mr-1 text-[#313a3c] border-gray-300 rounded accent-yellow-300'
                          />
                          <span className='mr-auto'>
                            {t('electronic_signature')}
                          </span>
                        </label>

                        {showConfirmation && (
                          <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                            <div className='bg-white rounded-md p-[60px]'>
                              <h1 className='mb-4 font-semibold text-2xl text-center text-[#313a3c]'>
                                {t('confirm')}
                              </h1>
                              <p className='mb-4 text-center text-sm font-medium'>
                                {t('modal_signed_electronically')}!
                              </p>
                              <div className=''>
                                <button
                                  type='button'
                                  className='w-[380px] h-[52px] rounded-md text-[#fff] mt-6 uppercase bg-[#313a3c] hover:bg-[#374151]'
                                  onClick={closeModal}>
                                  {t('continue')}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {keepDocumentOpenModal && (
                        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                          <div className='bg-white rounded-md p-12 relative'>
                            <button
                              className='absolute top-2 right-2 p-4'
                              onClick={keepDocumentOpen}>
                              <img
                                src={XButton}
                                alt='x-button'
                                className='w-[21px] px-1 h-[21px]'
                              />
                            </button>

                            <p className='text-center text-sm font-medium'>
                              {t('sign_document_electronically')}!
                            </p>

                            <div className='mt-6'>
                              <button
                                type='button'
                                className='w-[380px] h-[52px] rounded-md text-white uppercase bg-[#313a3c] hover:bg-[#374151]'
                                onClick={closeDocument}>
                                {t('cancel')}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Modal>
                </div>
              </div>
            ))}
          </div>
          {errorMessage && (
            <div className='text-red-500 mt-4 text-xs'>{errorMessage}</div>
          )}
        </div>
        <div className='fixed right-0 flex  justify-end p-4 space-x-4 mr-[120px] mt-6'>
          <button
            className='text-[#0f1d31] hover:bg-[#efefef] px-[22px] py-[11px] rounded-lg font-medium'
            onClick={handleCancelButtonClick}>
            {t('cancel')}
          </button>
          {cancelConfirmation && (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
              <div className='bg-white rounded-md p-[60px]'>
                <h1 className='mb-4 font-semibold text-2xl text-center text-[#313a3c]'>
                  {' '}
                  {t('cancelation')}{' '}
                </h1>
                <p className='mb-4 text-center text-sm font-medium'>
                  {t('cancel_police')}
                </p>
                <button
                  type='submit'
                  className='w-[380px] h-[52px] rounded-md text-[#fff] mt-6 uppercase bg-[#313a3c] hover:bg-[#374151]'
                  onClick={fetchCancelPolicy}>
                  {isLoadingForCancelingPolicy ? (
                    <YourCircularIndicatorComponent />
                  ) : (
                    t('cancel')
                  )}
                </button>
                <div className=''>
                  <button
                    type='button'
                    className='w-[380px] h-[52px] rounded-md text-[#313a3c] mt-2 font-semibold uppercase bg-[#fff] hover:bg-[#f5f6fa]'
                    onClick={handleContinue}>
                    {t('continue')}
                  </button>

                  {errorMessage && (
                    <p className='text-sm font-medium text-red-700'>
                      {errorMessage}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          <button
            className='bg-[#f7ee00] hover:bg-[#E0D815] text-[#0f1d31] px-[22px] py-[11px] rounded-lg font-medium'
            onClick={handleFinishButtonClick}>
            {t('finish')}
          </button>
          {showSuccess && (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
              <div className='bg-white rounded-lg w-[492px] h-[349px] p-12 flex flex-col items-center'>
                <img src={Email} alt='' className='w-[60px] mx-auto mb-4' />
                <h3 className='text-center font-bold mb-4'>
                  {t('successfully_message')}
                </h3>
                <p className='text-center text-sm font-medium mb-8'>
                  {t('send_email_message')}
                </p>
                <button
                  type='button'
                  className='w-[380px] h-[52px] rounded-md text-[#fff] uppercase bg-[#313a3c] hover:bg-[#374151]'
                  onClick={handleSuccessClose}>
                  {isLoadingForPurchase ? (
                    <YourCircularIndicatorComponent />
                  ) : (
                    t('finish')
                  )}
                </button>
              </div>
            </div>
          )}
          {WaitingMessage && (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
              <div className='bg-white rounded-lg w-[462px] h-[300px] p-8 flex flex-col items-center'>
                <img src={Success} alt='' className='mx-auto mt-4 block mb-4' />
                <h1 className='mb-4 font-semibold text-[24px] text-center text-[#313a3c]'>
                  {' '}
                  {t('wiht_success')}{' '}
                </h1>
                <p className='text-center font-medium text-[18px] mb-8'>
                  {' '}
                  {t('successfully_accepted')}{' '}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Documents;
