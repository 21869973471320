import React, { useEffect } from 'react';

const UpcPaymentComponent = () => {
    useEffect(() => {
        const loadUpcPaymentScript = () => {
          const script = document.createElement('script');
          script.src = 'https://ecconnect.upc.ua/pub/utils/upc-payment-sdk/upc-payment.js';
          script.async = true;
    
          script.onload = () => {
            initializeUpcPayment();
          };
    
          document.body.appendChild(script);
    
          return () => {
            document.body.removeChild(script);
          };
        };
    
        const initializeUpcPayment = () => {
          if (typeof window.UpcPayment !== 'undefined') {
            window.UpcPayment({
              url: 'https://ecg.test.upc.ua/go/pay',
              payment: {
                Version: 1,
                MerchantID: "YourMerchantID",
                TerminalID: "YourTerminalID",
                locale: "sq",
                Signature: "YourSignature",
                Currency: 978,
                TotalAmount: "prim_total",
                PurchaseTime: new Date().getTime(),
                OrderID: "YourOrderID",
                PurchaseDesc: "YourPurchaseDesc"
              },
              options: {
                modal: false,
                modalHeight: '500px',
                modalWidth: '200px',
                modalTop: '100%',
              }
            });
          } else {
            console.error('UpcPayment is not available.');
          }
        };
    
        loadUpcPaymentScript();
      }, []);

  return (
    <div>
      {/* Your React component content goes here */}
    </div>
  );
};

export default UpcPaymentComponent;
