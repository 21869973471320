import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, NavLink } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Background from "../../assets/images/raiffeisen-bg.png";
import InsurexLogo from "../../assets/icons/insurex-logo.svg";
import ForgotPasswordIcon from "../../assets/icons/forgot-password-icon.svg";
import InsurexLogoX from "../../assets/images/insurex-logo-x-green.png";
import { useTranslation } from 'react-i18next';

const initialValues = {
  email: "",
};
const onSubmit = (values) => {
};

const validate = (values) => {
  let errors = {};
  if (!values.email) {
    errors.email = "Email is required!";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email format!";
  }
  return errors;
};

const ForgotPassword = () => {
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="">
            <img src={Background} alt="background" className="bg-[#726f6f] fixed w-full h-full  object-cover" />
      <div className="relative">
        <Navbar type="authentication" landing="landing" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
      </div>
      <div className="md:flex md:flex-col items-center  px-6 md:py-8 mx-auto lg:py-0 relative ">
        <div className="pt-0 md:pt-2 items-center ">
          <div className=" justify-center items-center">
          <img className=" pb-4 mx-auto" src={InsurexLogoX} alt="" />
          <p className="text-[#9b9b9b] pb-8 text-sm up text-center font-regular">{t('insurance_platform')}</p>
          </div>  
           <div className="bg-white sm:max-w-md   rounded-md">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-8 space-y-4 md:space-y-6 sm:p-8 ">
                <div className="">
                  <p className="text-xl md:text-2xl text-[#282d30] text-center font-bold  mb-4 mt-4 pt-2">
                    {t('forgot_password')}
                  </p>
                  <img
                    className="mx-auto mb-6 md:mb-10"
                    src={ForgotPasswordIcon}
                    alt=""
                  />
                  <p className="text-sm font-medium md:text-[14px] text-center px-4 md:px-0 text-[#313a3c] mb-4">
                    {t('verification_proccess')}
                  </p>
                  <p className="text-[14px] font-medium text-[#828282] mb-4 ">E-mail</p>
                  <div>
                  <input
                      className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-96 text-sm mx-auto block"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      type="email"
                      placeholder="name@gmail.com"
                      id="email"
                      name="email"
                    />

                    {formik.errors.email ? (
                      <div className="text-red-600 text-xs mt-1">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                  {/* <input className='text-[#0f1d31] bg-[#f5f6fa] p-3 w-72 md:w-96 text-sm' type="email" placeholder="name@gmail.com" id="email" name="email"/>              */}
                </div>
                <div>
                  <button
                    type="submit"
                    className="focus:outline-none font-semibold text-[#ffffff] bg-[#313a3c] hover:bg-[#374151] mx-auto block  rounded-md text-xs md:text-sm px-5 py-3  mb-8 w-72 md:w-96 uppercase"
                  >
                    {t('submit')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bottom-0 w-full  fixed ">
        <Footer />
      </div>
    </div>
  );
};

export default ForgotPassword;
