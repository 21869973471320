import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import api from '../../services/api';
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer';
import SigalLogoIcon from "../../assets/icons/sigal-symbol.png";
import Success from '../../assets/icons/success-icon.svg';
import EurosigColor from '../../assets/images/eurosig.png'
import SigkosLogoIcon from "../../assets/icons/sigos-symbol.png";
import ScardianLogoIcon from "../../assets/icons/scardian-symbol.png";
import SiguriaLogoIcon from "../../assets/icons/siguria-symbol.png";
import ElsigLogoIcon from "../../assets/icons/elsig-symbol.png";
import PrisigLogoIcon from "../../assets/icons/prisig-symbol.png";
import DukagjiniLogoIcon from "../../assets/icons/dukagjini-symbol.png";
import EurosigLogoIcon from "../../assets/icons/eurosig-symbol.png";
import IlyriaLogoIcon from "../../assets/icons/illyria-symbol.png";
import SigmaLogoIcon from "../../assets/icons/sigma-symbol.png";
import InfoLogo from "../../assets/icons/info-icon.png";
import SigalLifeLogoIcon from "../../assets/icons/sigal-life-symbol.png";
import UpcPaymentComponent from '../../components/UpcPayment/UpcPaymentComponent';

const ProductDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [termsChecked, setTermsChecked] = useState(false);
  const [signatureChecked, setSignatureChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedValue, setSelectedValue] = useState('al');
  const [showUpcPaymentComponent, setShowUpcPaymentComponent] = useState(false);
  

  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState('');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleTermsChange = () => {
    setTermsChecked(!termsChecked);
    setError('');
  };
  const handleSignatureChange = () => {
    setSignatureChecked(!signatureChecked);
    setError('');
  };

  const handleSubmit = () => {
    // if (!termsChecked || !signatureChecked) {
    //   setError('Pranoni kushtet e përdorimit, politikën e privatësisë dhe jepni nënshkrimin.');
    //   return;
    // } else if (!healthProblems || !healthProblemsLastFiveYears || !criticalDiseases || !disabilities) {
    //   setError('Ju lutem plotësoni të gjitha fushat e kërkuara në seksionin e shëndetit.');
    //   return;
    // } else if (mortage_status_value === '1' && (!apsInsuredNumber || !namePropertyInsured || !insuredPropertyAddress)) {
    //   setError('Ju lutem plotësoni të gjitha fushat e kërkuara në seksionin e detajeve të hipotekës.');
    //   return;
    // } else if (vehicle_details_status_value === '1' && (!vinNumber || !plateNumber || !vehicleCategory || !vehicleBrand || !vehicleModel || !technicalDetails || !purpouseUsage || !productionYear || !coverageTerritory)) {
    //   setError('Ju lutem plotësoni të gjitha fushat e kërkuara në seksionin e detajeve të makinës.');
    //   return;
    // }

    const bankAccountLocally = Cookies.get('bank_account_number_locally');

    // if (!bankAccountLocally) {
    //   setError('Bank account number is not available.');
    //   return;
    // }

    if (bankAccountLocally.startsWith('15')) {
      setShowConfirmation(true);
    } else {
      if (internalUserVerifyStatus === 1) {
        setShowUpcPaymentComponent(true);
      }
      else {
        setError('Llogaria juaj nuk eshte verifikuar ende!');
      }

    }
  };


  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(true);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d+(\.\d+)?$/.test(inputValue) || inputValue === '') {
      setValue(inputValue);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const queryParams = new URLSearchParams(location.search);
  const [pep, setPep] = useState('');
  const [files, setFiles] = useState([]);
  const [showLoanInfoData, setShowLoanInfoData] = useState(true);
  const [loanId, setLoanId] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [personal_number, setPersonalNumber] = useState('');
  const [business_number, setBusinessNumber] = useState('');
  const [birth_date, setBirthDate] = useState('');
  const [address, setAddress] = useState('');
  const [bank_account_number, setBankAccountNumber] = useState('');
  const [productPartnersList, setProductPartnersList] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [reqeuestStatusCodeState, setRequestStatusCodeState] = useState(false);
  const [productPartnersDetailsList, setProductPartnersDetailsList] = useState([]);
  const [currentDate] = useState(new Date().toISOString().slice(0, 10));

  const [loan_info_status_value, setLoanInfoStatusValue] = useState('');
  const [mortage_status_value, setMortageStatusValue] = useState('');
  const [health_status_value, setHealthStatusValue] = useState('');
  const [vehicle_details_status_value, setVehicleDetailsStatusValue] = useState('');

  //Vehicle Details:
  const [vehiclePolicyNumbere, setVehiclePolicyNumber] = useState('');
  const [vinNumber, setVinNumber] = useState('');
  const [plateNumber, setPlateNumber] = useState('');
  const [vehicleCategory, setVehicleCategory] = useState('');
  const [vehicleBrand, setVehicleBrand] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [technicalDetails, setTechnicalDetails] = useState(0);
  const [purpouseUsage, setPurpouseUsage] = useState('');
  const [productionYear, setProductionYear] = useState(0);
  const [coverageTerritory, setCoverageTerritory] = useState(0);
  const [vehicleNotes, setVehicleNotes] = useState('');

  const handleVehicleCategory = (event) => {
    setVehicleCategory(event.target.value);
  }

  const handlePurpouseOfUsage = (event) => {
    setPurpouseUsage(event.target.value);
  }

  const handleCoverageTerritory = (event) => {
    setCoverageTerritory(event.target.value);
  }

  //Health problems Details
  const [healthProblems, setHealthProblems] = useState();
  const [healthProblemsLastFiveYears, setHealthProblemsLastFiveYeears] = useState();
  const [criticalDiseases, setCriticalDiseases] = useState();
  const [disabilities, setDisabilities] = useState();
  const [healthDeclaratinNotes, setHealthDeclarationNotes] = useState('');

  const handleHealthProblemsChange = (event) => {
    setHealthProblems(event.target.value);
  };
  const handleHealthProblemsLastFiveYears = (event) => {
    setHealthProblemsLastFiveYeears(event.target.value);
  };
  const handleCriticalDiseases = (event) => {
    setCriticalDiseases(event.target.value);
  };
  const handleDisabilities = (event) => {
    setDisabilities(event.target.value);
  };

  //Mortgage details
  // const [mortgagePolicyNumber, setMortgagePolicyNumber] = useState('');
  const [apsInsuredNumber, setApsInsuredNumber] = useState('');
  const [namePropertyInsured, setNamePropertyInsureed] = useState('');
  const [insuredPropertyAddress, setInsuredPropertyAddress] = useState('');
  const [mortgageDataNotes, setMortgageDataNotes] = useState('');

  //LoanData
  const [loanDataFromRice, setloanDataFromRice] = useState('');
  const [branchCodeFromRice, setBranchCodeFromRice] = useState('');
  const [sfeFromRice, setSfeFromRice] = useState('');
  const [initialAmountFromRice, setInitialAmountFromRice] = useState('');
  const [outstandingAmountFromRice, setOutstandingAmountFromRice] = useState('');
  const [periodFromLoanFromRice, setPeriodFromLoanFromRice] = useState('');
  const [periodToLoanFromRice, setPeriodToLoanFromRice] = useState('');
  const [apsNumberFromRice, setApsNumberFromRice] = useState('');

  const [internalUserVerifyStatus, setInternalUserVerifyStatus] = useState(null);

  const handlNamePropertyInsured = (event) => {
    setNamePropertyInsureed(event.target.value);
  }

  const [isLoading, setIsLoading] = useState(false);

  const productName = Cookies.get('product_name_local_storage');

  const selectedPartner = location?.state?.selectedPartner || null;

  const id = queryParams.get('id');
  const name = queryParams.get('name');

  const getLogoByPartner = (partner) => {
    switch (partner) {
      case 1:
        return SigalLifeLogoIcon;
      case 2:
        return SigalLogoIcon;
      case 3:
        return PrisigLogoIcon;
      case 4:
        return DukagjiniLogoIcon;
      case 5:
        return ElsigLogoIcon;
      case 6:
        return EurosigLogoIcon;
      case 7:
        return IlyriaLogoIcon;
      case 8:
        return IlyriaLogoIcon;
      case 9:
        return EurosigColor;
      case 10:
        return ScardianLogoIcon;
      case 11:
        return SigkosLogoIcon;
      case 12:
        return SigmaLogoIcon;
      case 13:
        return SiguriaLogoIcon;
      default:
        return null;
    }
  };

  //insurance details:
  const sumInsuredForApiCall = Cookies.get('sum_insured_for_api_call');
  const paymentMethodForApiCall = Cookies.get('payment_method_for_api_call');
  const numberOfInstallmentsForApiCall = Cookies.get('number_of_installments_for_api_call');
  const numberOfInstallmentsForYearlyForApiCall = Cookies.get('number_of_installments_for_yearly_for_api_call');
  const feeTypeForApiCall = Cookies.get('fee_type_for_api_call');
  const feeForApiCall = Cookies.get('tarif_for_api_call');
  const userId = Cookies.get('user_id');
  const legalNumber = Cookies.get('legal_number');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const legal_number_from_api = Cookies.get('legal_number');
  const customerId = Cookies.get('id');
  const primMujor = Cookies.get('prim_mujor');
  const formattedPrimMujor = formatNumber(primMujor);
  const primVjetor = Cookies.get('prim_vjetor');
  const formattedPrimVjetor = formatNumber(primVjetor);
  const primTotal = Cookies.get('prim_total');
  const formattedPrimTotal = formatNumber(primTotal);

  const premium = Cookies.get('premium_for_api_call');
  const productId = Cookies.get('product_id_for_api_call');
  const partnerId = Cookies.get('partner_id_for_api_call');
  const periodFrom = Cookies.get('period_from_api_call');
  const periodTo = Cookies.get('period_to_api_call');
  const firstPaymentDate = Cookies.get('first_payment_date');

  const dateNow = new Date();
  let sumInsuredForApiCallToDouble = parseFloat(sumInsuredForApiCall, 10);
  let numberOfInstallmentsForApiCallToInt = parseInt(numberOfInstallmentsForApiCall, 10);
  let numberOfInstallmentsForYearlyForApiCallToInt = parseInt(numberOfInstallmentsForYearlyForApiCall, 10);
  let userIdForApiCall = parseInt(userId, 10);
  let premiumForApiCall = parseFloat(premium, 10);
  let feeForApiCallToDouble = parseFloat(feeForApiCall, 10);
  let paymentMethodForApiCallToInt = parseInt(paymentMethodForApiCall, 10);
  let customerIdForApiCall = parseInt(customerId, 10);

  function formatNumber(str) {
    let num = parseFloat(str);
    num = Math.round(num * 100) / 100;
    return num.toString();
  }

  const handleConfirm = async () => {
    await sendRequest();
    navigate('/documents');
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  const fetchApiProfileData = useCallback(async () => {
    try {
      const response = await api.get(`/customers/${legal_number_from_api}/customer-api-detail-from-database`);

      setFirstName(response.data.first_name);
      setLastName(response.data.last_name);
      setEmail(response.data.email);
      setGender(response.data.gender);
      setPhoneNumber(response.data.phone_number);
      setPersonalNumber(response.data.legal_number);
      setBusinessNumber(response.data.business_number);
      setBirthDate(response.data.birth_date);
      setAddress(response.data.address);
      setBankAccountNumber(response.data.bank_account_number);
      setPep(response.data.pep);



    } catch (error) {
      console.error(error);
    }
  }, [legal_number_from_api]);

  useEffect(() => {
    fetchApiProfileData();
  }, [fetchApiProfileData]);

  function formatDate(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    return `${year}-${month}-${day}`;
  }

  const getInternalUserStatus = async () => {
    try {
      const legalNumber = Cookies.get('legal_number');
      const getInternalUserDetailsResponse = await api.get(`/internal-users/api/get-by-personal-number/${legalNumber}/`,);
      if (getInternalUserDetailsResponse.status === 200) {
        setInternalUserVerifyStatus(getInternalUserDetailsResponse.data.verification_status);
      } else if (getInternalUserDetailsResponse.status === 404) {
        // setErrorMessage('Ky klient nuk ka asnje loan agreemet!');
      }
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    setLoanInfoStatusValue(Cookies.get('loan_info_status'));
    setMortageStatusValue(Cookies.get('mortage_status'));
    setHealthStatusValue(Cookies.get('health_statement_status'));
    setVehicleDetailsStatusValue(Cookies.get('vehicle_details_status'));
    getInternalUserStatus();
    const loan_status = Cookies.get('loan_info_status');
    if (bankAccountNumberLocally.startsWith('15') && loan_status === "1") {
      setloanDataFromRice(Cookies.get('loanDataFromRice'));
      setBranchCodeFromRice(Cookies.get('branchCodeFromRice'));
      setSfeFromRice(Cookies.get('sfeFromRice'));
      setInitialAmountFromRice(Cookies.get('initialAmountFromRice'));
      setOutstandingAmountFromRice(Cookies.get('outstandingAmountFromRice'));
      const formatedPeriodFrom = formatDate(Cookies.get('periodFromLoanFromRice'));
      const formatedPeriodTo = formatDate(Cookies.get('periodToLoanFromRice'));
      setPeriodFromLoanFromRice(formatedPeriodFrom);
      setPeriodToLoanFromRice(formatedPeriodTo);
      setApsNumberFromRice(Cookies.get('apsNumberFromRice'));
    }
  }, []);

  const bankAccountNumberLocally = Cookies.get('bank_account_number_locally');
  const loanInfoStatusLocally = Cookies.get('loan_info_status');

  useEffect(() => {
    if ((!bankAccountNumberLocally.startsWith('15')) || loanInfoStatusLocally === '2') {
      setShowLoanInfoData(false);
    }
  });

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const newFiles = Array.from(selectedFiles).map((file) => {
      const fileType = file.type;
      const fileSize = file.size;
      let sizeInBytes = fileSize;
      let sizeInKb = (fileSize / 1024).toFixed(2);
      let sizeInMb = (fileSize / (1024 * 1024)).toFixed(2);
      let size;
      if (fileSize < 1024) {
        size = `${sizeInBytes} bytes`;
      } else if (fileSize >= 1024 && fileSize < 1024 * 1024) {
        size = `${sizeInKb} KB`;
      } else if (fileSize >= 1024 * 1024) {
        size = `${sizeInMb} MB`;
      }
      return {
        file,
        name: file.name,
        size,
        type: fileType,
      };
    });
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDelete = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const sendRequest = async () => {
    setIsLoading(true);
    try {
      let sendrequestResponseBody = {
        "partner": partnerId,
        "product": productId,
        "legal_number": legalNumber,
        "broker_id": userIdForApiCall,
        "customer": customerIdForApiCall,
        "broker": userIdForApiCall,
        "policy_date": currentDate,
        "sum_insured": sumInsuredForApiCallToDouble,
        "insurance_period_from": periodFrom,
        "insurance_period_to": periodTo,
        "premium": premiumForApiCall,
        "tarif": feeForApiCallToDouble,
        "payment_method": paymentMethodForApiCallToInt,
        "number_instalments": paymentMethodForApiCallToInt === 1 ? numberOfInstallmentsForApiCallToInt : numberOfInstallmentsForYearlyForApiCallToInt,
        "first_payment_date": firstPaymentDate,
        "client_type": 1,
        "service_type": 1,
        "distribution_channel": 1,

        //health
        "health_problems": healthProblems,
        "health_problems_last_five_years": healthProblemsLastFiveYears,
        "critical_diseases": criticalDiseases,
        "disabilities": disabilities,
        "health_declaration_notes": healthDeclaratinNotes,

        //vehicle details
        "vin_number": vinNumber,
        "plate_number": plateNumber,
        "vehicle_category": vehicleCategory,
        "vehicle_brand": vehicleBrand,
        "model": vehicleModel,
        "technical_details": technicalDetails,
        "purpose_usage": purpouseUsage,
        "production_year": productionYear,
        "coverage_territory": coverageTerritory,
        "vehicle_notes": vehicleNotes,
        "flag": 2,

        //mortgage
        // "mortgage_policy_number": mortgagePolicyNumber,
        "aps_insured_number": apsInsuredNumber,
        "name_property_insured": namePropertyInsured,
        "insured_property_address": insuredPropertyAddress,
        "mortgage_data_notes": mortgageDataNotes,
        "created_at": currentDate,

        //loan info
        "provided_by": 2,
        "loan_agreement_id": loanDataFromRice,
        "initial_amount": initialAmountFromRice,
        "outstanding_amount": outstandingAmountFromRice,
        "period_from": periodFromLoanFromRice,
        "period_to": periodToLoanFromRice,
        "sfe": sfeFromRice,
        "branch_code": branchCodeFromRice,
        // "target_code": "300",
      };

      if (health_status_value === "1" && loan_info_status_value === "1" && mortage_status_value === "2" && vehicle_details_status_value === "2") {
        sendrequestResponseBody = {
          "partner": partnerId,
          "product": productId,
          "legal_number": legalNumber,
          "broker_id": userIdForApiCall,
          "customer": customerIdForApiCall,
          "broker": userIdForApiCall,
          "policy_date": currentDate,
          "sum_insured": sumInsuredForApiCallToDouble,
          "insurance_period_from": periodFrom,
          "insurance_period_to": periodTo,
          "premium": premiumForApiCall,
          "tarif": feeForApiCallToDouble,
          "payment_method": paymentMethodForApiCallToInt,
          "number_instalments": paymentMethodForApiCallToInt === 1 ? numberOfInstallmentsForApiCallToInt : numberOfInstallmentsForYearlyForApiCallToInt,
          "first_payment_date": firstPaymentDate,
          "client_type": 1,
          "service_type": 1,
          "distribution_channel": 1,

          "created_at": currentDate,

          //health
          "health_problems": healthProblems,
          "health_problems_last_five_years": healthProblemsLastFiveYears,
          "critical_diseases": criticalDiseases,
          "disabilities": disabilities,
          "health_declaration_notes": healthDeclaratinNotes,

          //loan info
          "provided_by": 2,
          "loan_agreement_id": loanDataFromRice,
          "initial_amount": initialAmountFromRice,
          "outstanding_amount": outstandingAmountFromRice,
          "period_from": periodFromLoanFromRice,
          "period_to": periodToLoanFromRice,
          "sfe": sfeFromRice,
          "branch_code": branchCodeFromRice,
          // "target_code": "300",

        };
      }

      if (health_status_value === "1" && mortage_status_value === "2" && vehicle_details_status_value === "2" && loan_info_status_value === "2") {
        sendrequestResponseBody = {
          "partner": partnerId,
          "product": productId,
          "legal_number": legalNumber,
          "broker_id": userIdForApiCall,
          "customer": customerIdForApiCall,
          "broker": userIdForApiCall,
          "policy_date": currentDate,
          "sum_insured": sumInsuredForApiCallToDouble,
          "insurance_period_from": periodFrom,
          "insurance_period_to": periodTo,
          "premium": premiumForApiCall,
          "tarif": feeForApiCallToDouble,
          "payment_method": paymentMethodForApiCallToInt,
          "number_instalments": paymentMethodForApiCallToInt === 1 ? numberOfInstallmentsForApiCallToInt : numberOfInstallmentsForYearlyForApiCallToInt,
          "first_payment_date": firstPaymentDate,
          "client_type": 1,
          "service_type": 1,
          "distribution_channel": 1,
          "created_at": currentDate,

          //health
          "health_problems": healthProblems,
          "health_problems_last_five_years": healthProblemsLastFiveYears,
          "critical_diseases": criticalDiseases,
          "disabilities": disabilities,
          "health_declaration_notes": healthDeclaratinNotes,

        };
      }

      if (health_status_value === "2" && mortage_status_value === "1" && vehicle_details_status_value === "2" && loan_info_status_value === "1") {
        sendrequestResponseBody = {
          "partner": partnerId,
          "product": productId,
          "legal_number": legalNumber,
          "broker_id": userIdForApiCall,
          "customer": customerIdForApiCall,
          "broker": userIdForApiCall,
          "policy_date": currentDate,
          "sum_insured": sumInsuredForApiCallToDouble,
          "insurance_period_from": periodFrom,
          "insurance_period_to": periodTo,
          "premium": premiumForApiCall,
          "tarif": feeForApiCallToDouble,
          "payment_method": paymentMethodForApiCallToInt,
          "number_instalments": paymentMethodForApiCallToInt === 1 ? numberOfInstallmentsForApiCallToInt : numberOfInstallmentsForYearlyForApiCallToInt,
          "first_payment_date": firstPaymentDate,
          "client_type": 1,
          "service_type": 1,
          "distribution_channel": 1,

          "created_at": currentDate,

          //mortgage
          // "mortgage_policy_number": mortgagePolicyNumber,
          "aps_insured_number": apsInsuredNumber,
          "name_property_insured": namePropertyInsured,
          "insured_property_address": insuredPropertyAddress,
          "mortgage_data_notes": mortgageDataNotes,

          //loan info
          "provided_by": 2,
          "loan_agreement_id": loanDataFromRice,
          "initial_amount": initialAmountFromRice,
          "outstanding_amount": outstandingAmountFromRice,
          "period_from": periodFromLoanFromRice,
          "period_to": periodToLoanFromRice,
          "sfe": sfeFromRice,
          "branch_code": branchCodeFromRice,
          // "target_code": "300",

        };
      }

      if (health_status_value === "2" && mortage_status_value === "1" && vehicle_details_status_value === "2" && loan_info_status_value === "2") {
        sendrequestResponseBody = {
          "partner": partnerId,
          "product": productId,
          "legal_number": legalNumber,
          "broker_id": userIdForApiCall,
          "customer": customerIdForApiCall,
          "broker": userIdForApiCall,
          "policy_date": currentDate,
          "sum_insured": sumInsuredForApiCallToDouble,
          "insurance_period_from": periodFrom,
          "insurance_period_to": periodTo,
          "premium": premiumForApiCall,
          "tarif": feeForApiCallToDouble,
          "payment_method": paymentMethodForApiCallToInt,
          "number_instalments": paymentMethodForApiCallToInt === 1 ? numberOfInstallmentsForApiCallToInt : numberOfInstallmentsForYearlyForApiCallToInt,
          "first_payment_date": firstPaymentDate,
          "client_type": 1,
          "service_type": 1,
          "distribution_channel": 1,

          // "mortgage_policy_number": mortgagePolicyNumber,
          "aps_insured_number": apsInsuredNumber,
          "name_property_insured": namePropertyInsured,
          "insured_property_address": insuredPropertyAddress,
          "mortgage_data_notes": mortgageDataNotes,

          "created_at": currentDate,

          //loan info
        };
      }

      if (health_status_value === "2" && mortage_status_value === "2" && vehicle_details_status_value === "1" && loan_info_status_value === "1") {
        sendrequestResponseBody = {
          "partner": partnerId,
          "product": productId,
          "legal_number": legalNumber,
          "broker_id": userIdForApiCall,
          "customer": customerIdForApiCall,
          "broker": userIdForApiCall,
          "policy_date": currentDate,
          "sum_insured": sumInsuredForApiCallToDouble,
          "insurance_period_from": periodFrom,
          "insurance_period_to": periodTo,
          "premium": premiumForApiCall,
          "tarif": feeForApiCallToDouble,
          "payment_method": paymentMethodForApiCallToInt,
          "number_instalments": paymentMethodForApiCallToInt === 1 ? numberOfInstallmentsForApiCallToInt : numberOfInstallmentsForYearlyForApiCallToInt,
          "first_payment_date": firstPaymentDate,
          "client_type": 1,
          "service_type": 1,
          "distribution_channel": 1,
          "created_at": currentDate,

          //vehicle details
          "vin_number": vinNumber,
          "plate_number": plateNumber,
          "vehicle_category": vehicleCategory,
          "vehicle_brand": vehicleBrand,
          "model": vehicleModel,
          "technical_details": technicalDetails,
          "purpose_usage": purpouseUsage,
          "production_year": productionYear,
          "coverage_territory": coverageTerritory,
          "vehicle_notes": vehicleNotes,
          "flag": 2,

          //loan info
          "provided_by": 2,
          "loan_agreement_id": loanDataFromRice,
          "initial_amount": initialAmountFromRice,
          "outstanding_amount": outstandingAmountFromRice,
          "period_from": periodFromLoanFromRice,
          "period_to": periodToLoanFromRice,
          "sfe": sfeFromRice,
          "branch_code": branchCodeFromRice,
          // "target_code": "300",
        };
      }

      if (health_status_value === "2" && mortage_status_value === "2" && vehicle_details_status_value === "1" && loan_info_status_value === "2") {
        sendrequestResponseBody = {
          "partner": partnerId,
          "product": productId,
          "legal_number": legalNumber,
          "broker_id": userIdForApiCall,
          "customer": customerIdForApiCall,
          "broker": userIdForApiCall,
          "policy_date": currentDate,
          "sum_insured": sumInsuredForApiCallToDouble,
          "insurance_period_from": periodFrom,
          "insurance_period_to": periodTo,
          "premium": premiumForApiCall,
          "tarif": feeForApiCallToDouble,
          "payment_method": paymentMethodForApiCallToInt,
          "number_instalments": paymentMethodForApiCallToInt === 1 ? numberOfInstallmentsForApiCallToInt : numberOfInstallmentsForYearlyForApiCallToInt,
          "first_payment_date": firstPaymentDate,
          "client_type": 1,
          "service_type": 1,
          "distribution_channel": 1,

          //vehicle details
          "vin_number": vinNumber,
          "plate_number": plateNumber,
          "vehicle_category": vehicleCategory,
          "vehicle_brand": vehicleBrand,
          "model": vehicleModel,
          "technical_details": technicalDetails,
          "purpose_usage": purpouseUsage,
          "production_year": productionYear,
          "coverage_territory": coverageTerritory,
          "vehicle_notes": vehicleNotes,
          "flag": 2,

          "created_at": currentDate,

        };
      }
      const sendRequestRresponse = await api.post(`/requests/api/create_request/`, sendrequestResponseBody);
      if (sendRequestRresponse.status === 201) {
        Cookies.set('request_id', sendRequestRresponse.data.request_id);
        Cookies.set('ribk_code', sendRequestRresponse.data.request_ribk);
        setRequestStatusCodeState(true);
        setShowSuccess(true);

      } else if (sendRequestRresponse.status !== 201) {
        setErrorMessage('Something went wrong!');
      }
      else { }
    } catch (error) {
      console.error(error);
      setErrorMessage('Something went wrong!');
    }
    setIsLoading(false);
  }

  const YourCircularIndicatorComponent = () => {
    return (
      <div className="flex justify-center items-center">
        <div style={{ borderColor: '#FFDD00' }} className="w-6 h-6 border-t-4 border-yellow-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  };


  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className='overflow-hidden bg-[#f5f6fa]'>
    <div className="bg-[#222627] z-50 w-full">
      <Navbar className="" type="authentication" authType="loggedIn" landing="landing" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
    </div>
    {/* <img src={Background} alt="Background" className="absolute w-full transform rotate-180 " /> */}
      <div className='container'>
        <div className='flex flex-column py-10'>
          <h1 className='text-md md:text-[32px] font-semibold text-[#171d25] pl-6 md:pl-10 lg:pl-0 lg:pt-5 relative'> {t('security_details')} {productName}</h1>
        </div>
        <div className='lg:flex mx-auto'>
          <div className='lg:flex-3 md:pb-10 lg:pb-[200px]'>
            
            <div className='max-w-full'>
            <div className='bg-[#fff] w-auto h-[64px] shadow-lg relative rounded-xl mx-[5%] lg:mx-0 mb-6 flex items-center justify-center'>
          <img src={InfoLogo} alt='InfoLogo' className='absolute inset-y-0 left-4 flex items-center mt-3'></img>
          <p className="text-[#212b36] absolute text-[9px] font-regular  md:text-[12px] lg:text-base inset-y-0 left-16 flex items-center">
            {t('data_automatically_collected_from_bank')}
          </p>
        </div>
              <div className='bg-white pl-4 py-4 md:py-10 shadow-lg mx-[5%] lg:center lg:mx-0 rounded-xl relative  w-auto'>
                <p className='text-[#0f1d31] font-semibold text-sm md:text-[22px] pb-4 md:pb-[31px]'> {t('insured')} </p>
                <div className='overflow-x-auto'>
                <table className="">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 text-[10px] font-regular md:text-sm text-[#b0bac9] whitespace-nowrap flex">{t('name_lastname')}</th>
                      <th className="px-4 py-2 text-[10px] font-regular md:text-sm text-[#b0bac9] whitespace-nowrap text-left">{t('personal_number')}</th>
                      <th className="px-4 py-2 text-[10px] font-regular md:text-sm text-[#b0bac9] whitespace-nowrap text-left">{t('business_number')}</th>
                      <th className="px-4 py-2 text-[10px] font-regular md:text-sm text-[#b0bac9] whitespace-nowrap text-left">{t('phone_number')}</th>
                      <th className="px-4 py-2 text-[10px] font-regular md:text-sm text-[#b0bac9] whitespace-nowrap text-left">{t('city')}</th>
                      <th className="px-4 py-2 text-[10px] font-regular md:text-sm text-[#b0bac9] whitespace-nowrap text-left">{t('adres')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowra flex">{first_name} {last_name}</td>
                      <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left">{personal_number}</td>
                      <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left">{business_number}</td>
                      <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left">{phone_number}</td>
                      <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left">Prishtinë</td>
                      <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left">{address}</td>
                    </tr>
                  </tbody>
                </table>
              </div>



              </div>
            </div>
            {showLoanInfoData === true && (<div className='bg-white mx-[5%] lg:mx-0 pl-10 py-4 px-4 lg:px-0 md:py-10 mt-10 rounded-xl relative'>
              <p className='text-[#171d25] font-semibold text-[10px] md:text-[22px] pb-4 md:pb-[31px]'> {t('loan_agreement')} </p>
              <div>3

                <div className='flex'>
                  <div className='w-1/6 text-[6px] md:text-sm  font-regular text-[#b0bac9]'> {t('loan_id')} </div>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#b0bac9]'> {t('initial_amount')} </div>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#b0bac9]'> {t('unpaid_amount')}</div>
                  <div className='w-1/6 text-[6px] pl-1 md:pl-0 md:text-sm font-regulars text-[#b0bac9]'>{t('period')}</div>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#b0bac9]'>SFE</div>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#b0bac9]'> {t('branch_code')} </div>
                  {/* <div className='w-1/6 text-sm text-[#b0bac9]'> {t('target_code')} </div> */}
                </div>


                <div className='flex'>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#0f1d31] pt-1'>{loanDataFromRice}</div>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#0f1d31] pt-1'>{initialAmountFromRice} €</div>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#0f1d31] pt-1'>{outstandingAmountFromRice} €</div>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#0f1d31] pl-1 md:pl-0 pt-1'>{periodFromLoanFromRice} - {periodToLoanFromRice}</div>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#0f1d31] pt-1'>{sfeFromRice}</div>
                  <div className='w-1/6 text-[6px] md:text-sm font-regular text-[#0f1d31] pt-1'>{branchCodeFromRice}</div>
                  {/* <div className='w-1/6 text-sm text-[#0f1d31 pt-1'>-</div> */}
                </div>

              </div>
            </div>)}

            {vehicle_details_status_value === '1' && <div className='bg-white overflow-auto pl-4 py-4 md:py-10 shadow-lg mx-[5%] lg:center lg:mx-0 mt-10 rounded-xl relative  w-autoe'>
              <div>
                <p className='text-[#171d25] font-semibold text-sm md:text-[22px] pb-[31px]'> {t('vehicle_details')}</p>
                <div className="flex">
                    <div className="w-1/4 px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap flex">{t('chassis_number')}</div>
                    <div className="w-1/4 px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left lg:pl-6">{t('plate_number')}</div>
                    <div className="w-1/4 px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left flex lg:pl-6">{t('vehicle_category')}</div>
                </div>
                <div className="flex">
                  <div className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm text-[#0f1d31] ">
                    <input className="font-regular text-[#0f1d31] bg-[#f5f6fa] w-[212px] h-[32px] text-[10px] md:text-[14px] lg:text-sm mt-1 pl-3 rounded-md" placeholder={t('chassis_number')} id="" name="" type="text" value={vinNumber} onChange={(e) => setVinNumber(e.target.value)} />
                  </div>
                  <div className="px-4 py-2  text-[10px] md:text-[14px] lg:text-smtext-[#0f1d31 ">
                    <input className="font-regular text-[#0f1d31] bg-[#f5f6fa] w-[212px] h-[32px] ml-3 lg:ml-0 text-[10px] md:text-[14px] lg:text-sm  pl-3 rounded-md" placeholder={t('plate_number')} id="" name="" type="text" value={plateNumber} onChange={(e) => setPlateNumber(e.target.value)} />
                  </div>
                  <div className="px-4 py-2  text-[10px] md:text-[14px] lg:text-sm font-regular text-[#0f1d31]">
                  <select
                    className="text-[#b0bac9] bg-[#f5f6fa] w-[212px] h-[32px] text-[10px] md:text-[14px] lg:text-sm lg:ml-0 pl-3 rounded-md"
                    type="number"
                    value={vehicleCategory}
                    onChange={handleVehicleCategory}
                    style={{ color: vehicleCategory === 'Motorcycle' || vehicleCategory === 'Truck' || vehicleCategory === 'Bus' ||vehicleCategory === 'Sedan' || vehicleCategory ==='Coupe' || vehicleCategory === 'Wagon' || vehicleCategory === 'Hatchback' || vehicleCategory === 'Convertible' || vehicleCategory === 'SUV' || vehicleCategory === 'Minivan' || vehicleCategory === 'Pickup' || vehicleCategory === 'Motorcycle' || vehicleCategory === 'Van' || vehicleCategory === 'Bus' || vehicleCategory === 'Truck' ? '#171d25' : '#b0bac9' }}
                  >
                    <option value="" style={{ color: '#b0bac9' }}>{t('select')}</option>
                    <option value="Sedan" style={{ color: vehicleCategory === 'Sedan' ? '#171d25' : '#b0bac9' }}>Sedan</option>
                    <option value="Coupe" style={{ color: vehicleCategory === 'Coupe' ? '#171d25' : '#b0bac9' }}>Coupe</option>
                    <option value="Wagon" style={{ color: vehicleCategory === 'Wagon' ? '#171d25' : '#b0bac9' }}>Wagon</option>
                    <option value="Hatchback" style={{ color: vehicleCategory === 'Hatchback' ? '#171d25' : '#b0bac9' }}>Hatchback</option>
                    <option value="Convertible" style={{ color: vehicleCategory === 'Convertible' ? '#171d25' : '#b0bac9' }}>Convertible</option>
                    <option value="SUV" style={{ color: vehicleCategory === 'SUV' ? '#171d25' : '#b0bac9' }}>SUV</option>
                    <option value="Minivan" style={{ color: vehicleCategory === 'Minivan' ? '#171d25' : '#b0bac9' }}>Minivan</option>
                    <option value="Pickup" style={{ color: vehicleCategory === 'Pickup' ? '#171d25' : '#b0bac9' }}>Pickup</option>
                    <option value="Motorcycle" style={{ color: vehicleCategory === 'Motorcycle' ? '#171d25' : '#b0bac9' }}>Motorcycle</option>
                    <option value="Van" style={{ color: vehicleCategory === 'Van' ? '#171d25' : '#b0bac9' }}>Van</option>
                    <option value="Bus" style={{ color: vehicleCategory === 'Bus' ? '#171d25' : '#b0bac9' }}>Bus</option>
                    <option value="Truck" style={{ color: vehicleCategory === 'Truck' ? '#171d25' : '#b0bac9' }}>Truck</option>
                  </select>

                  </div>

                </div>
              
                <div className="flex pt-4">
                    <div className="w-1/4 px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap flex">{t("vehicle_mark")}</div>
                    <div className="w-1/4 px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-lef pl-52 md:pl-0 lg:pl-6">{t("model")}</div>
                    <div className="w-1/4 px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left pl-52 lg:pl-6">{t("technical_details")}</div>
                </div>      
                <div className="flex">
                  <div className="px-4 py-2  text-[10px] md:text-[14px] lg:text-sm text-[#0f1d31] ">
                    <input className="text-[#0f1d31] bg-[#f5f6fa] font-regular w-[212px] h-[32px] text-[10px] md:text-[14px] lg:text-sm mt-1 pl-3 rounded-md" placeholder={t("vehicle_mark")} id="" name="" type="text" value={vehicleBrand} onChange={(e) => setVehicleBrand(e.target.value)} />
                  </div>
                  <div className="px-4 py-2 text-[#0f1d31] ">
                    <input className="text-[#0f1d31] bg-[#f5f6fa] font-regular w-[212px] h-[32px] text-[10px] md:text-[14px] lg:text-sm  ml-6 lg:ml-0  mt-1 pl-3 rounded-md" placeholder={t("model")} id="" name="" type="text" value={vehicleModel} onChange={(e) => setVehicleModel(e.target.value)} />
                  </div>
                  <div className="px-4 py-2  text-[10px] md:text-[14px] lg:text-smtext-[#0f1d31 relative inline-block">
                    <input className="text-[#0f1d31]  bg-[#f5f6fa] w-[212px] h-[32px] font-regular text-[10px] md:text-[14px] lg:text-sm  lg:ml-0 mt-1 pl-3 rounded-md" placeholder={t("technical_details")} id="" name="" type="number" value={technicalDetails} onChange={(e) => setTechnicalDetails(e.target.value)} />
                    <span className="absolute top-1/2 right-10 text-[#0f1d31] transform font-regular -translate-y-1/2">cm³</span>
                  </div>
                  
                </div>
               <div className="flex pt-4">
                  <div className="w-1/4 whitespace-nowrap px-4 py-2 font-regular  text-[10px] md:text-[14px] lg:text-sm text-[#0f1d31]"> {t('year_manufacture')} </div>
                  <div className="w-1/4 whitespace-nowrap px-4 py-2 font-regular lg:pl-5 text-[10px] md:text-[14px] pl-10 md:pl-0  lg:text-smtext-[#0f1d31]]"> {t('covered_area')} </div>
                  <div className="w-1/4 px-4 py-2 text-[10px] md:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left pl-56 lg:pl-6">{t("purpose_of_use")}</div>

                </div>
                <div className="flex">
                <div className="px-4 py-2  text-[10px] md:text-[14px] lg:text-sm text-[#0f1d31] ">
                    <input className="text-[#0f1d31]  bg-[#f5f6fa] font-regular w-[212px] h-[32px] text-[10px] md:text-[14px] lg:text-sm mt-1 pl-3 rounded-md" placeholder={t('year_manufacture')} id="" name="" type="number" value={productionYear} onChange={(e) => setProductionYear(e.target.value)} />
                  </div>
                  <div className="px-4 py-2  text-[10px] md:text-[14px] lg:text-sm text-[#0f1d31]">
                  <select
                      className="text-[#b0bac9] font-regular bg-[#f5f6fa] lg:ml-0 w-[212px] h-[32px] text-[10px] md:text-[14px] lg:text-sm mt-1 pl-3 rounded-md"
                      value={coverageTerritory}
                      onChange={handleCoverageTerritory}
                      type="number"
                      style={{color:coverageTerritory === 'kosovo' || coverageTerritory === 'region' || coverageTerritory === 'europe' ? '#171d25' : '#b0bac9' }}
                    >
                    <option value="" style={{ color: '#b0bac9' }}>{t('select')}</option>
                      <option value="kosovo" style={{ color: coverageTerritory === 'kosovo' ? '#171d25' : '#b0bac9' }}>{t('kosovo')}</option>
                      <option value="region" style={{ color: coverageTerritory === 'region' ? '#171d25' : '#b0bac9' }}>{t('region')}</option>
                      <option value="europe" style={{ color: coverageTerritory === 'europe' ? '#171d25' : '#b0bac9' }}>{t('europe')}</option>
                    </select>
                  </div>
                  <div className="w-1/4 text-[10px] md:text-[14px] lg:text-sm text-[#0f1d31]">
                    <select
                      className="text-[#b0bac9] bg-[#f5f6fa] font-regular mt-3 lg:ml-4 w-[212px] h-[32px] text-[10px] md:text-[14px] lg:text-sm  mr-10 md:mr-0  pl-3 rounded-md"
                      value={purpouseUsage}
                      onChange={handlePurpouseOfUsage}
                      type="number"
                      style={{ color: purpouseUsage === 'transport' ? '#171d25' : (purpouseUsage === 'work' ? '#171d25' : (purpouseUsage === 'personal' ? '#171d25' : '#b0bac9')) }}
                    >
                      <option value="" style={{ color: '#b0bac9' }}>{t('select')}</option>
                      <option value="personal" style={{ color: purpouseUsage === 'personal' ? '#171d25' : '#b0bac9' }}>{t('personal')}</option>
                      <option value="work" style={{ color: purpouseUsage === 'work' ? '#171d25' : '#b0bac9' }}>{t('work')}</option>
                      <option value="transport" style={{ color: purpouseUsage === 'transport' ? '#171d25' : '#b0bac9' }}>{t('transport')}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label for="message" className="text-base text-[#313a3c] lg:pl-5 leading-6 pl-4 font-medium"> {t('note')} </label>
                <div>
                  <textarea type="text" id="message" rows="2" className=" w-11/12 ml-4 px-4 py-[14px] mt-2 bg-[#f7f8fb] font-regular text-[#0f1e2f] text-[15px] leading-6 rounded-md" placeholder={t('note')} value={vehicleNotes} onChange={(e) => setVehicleNotes(e.target.value)}></textarea>
                </div>
              </div>
            </div>
            }

            <div className='bg-white lg:pl-4 mx-[5%] lg:mx-0  py-4 md:py-10 shadow-lg mt-10 rounded-xl relative px-4 lg:px-0'>
                <p className='text-[#171d25] font-semibold text-sm md:text-[22px]  pb-4 md:pb-[31px]'> {t('security_details')} </p>
                <div className='overflow-auto'>
                <table className="min-w-full">
              <thead>
                <tr>
                  <th className="pl-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#b0bac9] whitespace-nowrap flex">{t('policy_date')}</th>
                  <th className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('insured_amount')}</th>
                  <th className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('insurance_period')}</th>
                  <th className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('payment_method')}</th>
                  <th className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('number_of_installments')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#0f1d31] whitespace-nowrap flex">{currentDate}</td>
                  <td className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left">€{sumInsuredForApiCall}</td>
                  <td className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left">
                  {periodFrom.replace(/-/g, '/').split('-').map(datePart => {
                    const parts = datePart.split('/');
                    return `${parts[2]}/${parts[1]}/${parts[0]}`;
                  }).join('/')}- 
                  {periodTo.replace(/-/g, '/').split('-').map(datePart => {
                    const parts = datePart.split('/');
                    return `${parts[2]}/${parts[1]}/${parts[0]}`;
                  }).join('/')}
                </td>
                <td className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left">{paymentMethodForApiCallToInt === 1 ? 'Monthly' : paymentMethodForApiCallToInt === 2 ? 'Yearly' : 'Pagese Online'}</td>
                  <td className="px-4 py-2 text-[10px] md:text-[14px] lg:text-sm font-regular text-[#0f1d31] whitespace-nowrap text-left">{paymentMethodForApiCallToInt === 1 ? numberOfInstallmentsForApiCallToInt.toString() : paymentMethodForApiCallToInt === 2 ? numberOfInstallmentsForYearlyForApiCallToInt.toString() : '-'}333</td>
                </tr>
              </tbody>
            </table>

                </div>

            </div>
            {health_status_value === '1' && <div className='bg-white pl-4 shadow-lg py-4 md:py-10 mx-[5%] lg:mx-0 mt-10 rounded-xl relative'>
              <p className='text-[#171d25] font-semibold text-sm md:text-[22px] md:pb-[31px]'> {t('health_declaration')} </p>
              <div className="flex overflow-auto pt-2 space-x-10 pl-4">
                <div className="mt-4 w-1/2">
                  <p className="text-[10px] md:text-[14px] lg:text-sm font-regular  text-[#b0bac9] whitespace-nowrap"> {t('health_problems')} </p>
                  <div className="mt-2 space-y-2 whitespace-nowrap">
                    <label className="inline-flex items-center">
                      <input type="radio" className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                        name="healthProblems" value={1} checked={healthProblems === '1'}
                        onChange={handleHealthProblemsChange} />
                      <span className="ml-2 mt-1.5 mr-2 text-[11px] font-regular text-[#0f1d31] "> {t('yes')} </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input type="radio" className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                        name="healthProblems" value={2} checked={healthProblems === '2'}
                        onChange={handleHealthProblemsChange} />
                      <span className="ml-2 mt-1.5 text-[11px] font-regular text-[#0f1d31]"> {t('no')} </span>
                    </label>
                  </div>
                </div>
                <div className="mt-4 w-1/2  md:w-1/2 lg:w-1/3" >
                  <p className="text-[10px] md:text-[14px] lg:text-sm text-[#b0bac9] whitespace-nowrap font-regular pl-8 md:pl-0"> {t('health_problems_in_last_five_years')} </p>
                  <div className="mt-2 space-y-2">
                    <label className="inline-flex items-center pl-8 md:pl-0">
                      <input type="radio" className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                        name="healthProblemsLastFiveYears" value={1} checked={healthProblemsLastFiveYears === '1'}
                        onChange={handleHealthProblemsLastFiveYears} />
                      <span className="ml-2 mt-1.5 mr-2 text-[11px] font-regular text-[#0f1d31]"> {t('yes')} </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input type="radio" className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                        name="healthProblemsLastFiveYears" value={2} checked={healthProblemsLastFiveYears === '2'}
                        onChange={handleHealthProblemsLastFiveYears} />
                      <span className="ml-2 mt-1.5 mr-2 text-[11px] font-regular text-[#0f1d31]"> {t('no')} </span>
                    </label>
                  </div>
                </div>
                <div className="mt-4 w-full md:w-1/2 lg:w-1/5 text-left pl-6">
                  <p className="md:pr-1 lg:pr-0 text-[10px] md:text-[14px] lg:text-sm text-[#b0bac9] whitespace-nowrap font-regular pl-14 md:pl-6 lg:pl-0"> {t('critical_illnesses')} </p>
                  <div className="mt-2 space-y-2 pr-8  pl-14 md:pl-6 lg:pl-0 whitespace-nowrap">
                    <label className="inline-flex items-center">
                      <input type="radio" className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                        name="criticalDiseases" value={1} checked={criticalDiseases === '1'}
                        onChange={handleCriticalDiseases} />
                      <span className="ml-2 mt-1.5 mr-2 text-[11px] font-regular text-[#0f1d31]"> {t('yes')} </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input type="radio" className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                        name="criticalDiseases" value={2} checked={criticalDiseases === '2'}
                        onChange={handleCriticalDiseases} />
                      <span className="ml-2 mt-1.5 mr-2 text-[11px] font-regular text-[#0f1d31]"> {t('no')} </span>
                    </label>
                  </div>
                </div>
                <div className="mt-4 w-1/2 lg:pr-4 ">
                  <p className="text-[10px] md:text-[14px] lg:text-sm text-[#b0bac9] whitespace-nowrap font-regular"> {t('disabilities')} </p>
                  <div className="mt-2 space-y-2 pr-10 whitespace-nowrap">
                    <label className="inline-flex items-center">
                      <input type="radio" className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                        name="disabilities" value={1} checked={disabilities === '1'}
                        onChange={handleDisabilities} />
                      <span className="ml-2 mt-1.5 mr-2 text-[11px] font-regular text-[#0f1d31]"> {t('yes')} </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input type="radio" className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                        name="disabilities" value={2} checked={disabilities === '2'}
                        onChange={handleDisabilities} />
                      <span className="ml-2 mt-1.5 mr-2 text-[11px] font-regular text-[#0f1d31]"> {t('no')} </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                  <label htmlFor="message" className="text-base text-[#313a3c] leading-6 font-semibold pl-4"> {t('note')} </label>
                  <div className='ml-4'>
                    <textarea id="message" rows="2" className=" w-11/12 px-4 py-[14px] mt-2 bg-[#f7f8fb] font-regular text-[#0f1e2f] text-[15px] leading-6 rounded-md" placeholder={t('note')} value={healthDeclaratinNotes} onChange={(e) => setHealthDeclarationNotes(e.target.value)}></textarea>
                  </div>
                </div>
            </div>
            }

            {mortage_status_value === '1' &&<div className='bg-white pl-4 py-4 md:py-10 shadow-lg mx-[5%] lg:center lg:mx-0 mt-10 overflow-auto rounded-xl relative  w-auto'>

              <div>
                <p className='text-[#171d25] font-semibold text-sm md:text-[22px] pb-[31px] '> {t('mortage_details')} </p>
                <div className='pl-4'>
                <div className="flex">
                  <div className="w-1/3 px-1 py-2 text-[10px] md:text-[14px] lg:text-sm  font-regular whitespace-nowrap text-[#313a3c]"> {t('aps_number')} </div>
                  <div className="w-1/3 px-4 py-2 text-[10px] md:text-[14px] lg:text-sm  font-regular whitespace-nowrap pl-32 lg:pl-0 text-[#313a3c]">{t('name_insured_property')} </div>
                  <div className="w-1/3 px-4 py-2 text-[10px] md:text-[14px] lg:text-sm  font-regular whitespace-nowrap text-[#313a3c] pl-60 md:pl-0"> {t('address_insured_property')} </div>
                </div>
                <div className="flex">
                  <div className="w-1/3 text-sm text-[#0f1d31 ">
                    <input className="font-regular bg-[#f5f6fa] w-[212px] h-[32px] text-[10px] md:text-[14px] lg:text-sm mt-1 pl-3 rounded-md" placeholder={t('aps_number')} id="" name="" type="text" value={apsInsuredNumber} onChange={(e) => setApsInsuredNumber(e.target.value)} />
                  </div>
                  <div className="w-1/3 text-[10px] md:text-[14px] lg:text-sm [#a5aebd]">
                  <select className="#a5aebd font-regular bg-[#f5f6fa] ml-32 md:ml-0 w-[212px] h-[32px] text-[10px] md:text-[14px] lg:text-sm mt-1 pl-3 rounded-md" 
                  type="number" 
                  value={namePropertyInsured} 
                  onChange={handlNamePropertyInsured}
                  style={{color:namePropertyInsured === '1' || namePropertyInsured === '2' || namePropertyInsured === '3' || namePropertyInsured === '4' || namePropertyInsured === '5' || namePropertyInsured === '6' || namePropertyInsured === '7' || namePropertyInsured === '8' || namePropertyInsured === '9' || namePropertyInsured === '10' || namePropertyInsured === '11' || namePropertyInsured === '12' || namePropertyInsured === '13' || namePropertyInsured === '14' || namePropertyInsured === '15' || namePropertyInsured === '16' || namePropertyInsured === '17' || namePropertyInsured === '18' || namePropertyInsured === '19' || namePropertyInsured === '20' || namePropertyInsured === '21' || namePropertyInsured === '22' || namePropertyInsured === '23' || namePropertyInsured === '24' || namePropertyInsured === '26' ||  namePropertyInsured === '27' ||  namePropertyInsured === '28' ||  namePropertyInsured === '29' ||  namePropertyInsured === '30' ||  namePropertyInsured === '31' ||  namePropertyInsured === '32' ||  namePropertyInsured === '33' ||   namePropertyInsured === '34' ||  namePropertyInsured === '35' ||  namePropertyInsured === '36' ||  namePropertyInsured === '37' ||  namePropertyInsured === '38' || namePropertyInsured === '39' ||  namePropertyInsured === '40' ||  namePropertyInsured === '41' ||  namePropertyInsured === '42' ||  namePropertyInsured === '43' ||  namePropertyInsured === '44' ||  namePropertyInsured === '45' ||  namePropertyInsured === '46' ||  namePropertyInsured === '47' ||  namePropertyInsured === '48' ||  namePropertyInsured === '49' ||  namePropertyInsured === '50' ||  namePropertyInsured === '51' ||  namePropertyInsured === '52' ||  namePropertyInsured === '53' ||  namePropertyInsured === '54'||  namePropertyInsured === '55' || namePropertyInsured === '56' ||  namePropertyInsured === '57' ||  namePropertyInsured === '58' ||  namePropertyInsured === '59' ||  namePropertyInsured === '60' ? '#171d25' : '#b0bac9' }}>
                      <option value="" className='text-[#b0bac9]'>{t('select')}</option>
                      <option value={1} style={{ color: namePropertyInsured === '1' ? '#171d25' : '#b0bac9' }}>Agricultural Land</option>
                      <option value={2} style={{ color: namePropertyInsured === '2' ? '#171d25' : '#b0bac9' }}>Agricultural vehicles incl. tractors</option>
                      <option value={3}  style={{ color: namePropertyInsured === '3' ? '#171d25' : '#b0bac9' }}>WagApartment Buildingon</option>
                      <option value={4}  style={{ color: namePropertyInsured === '4' ? '#171d25' : '#b0bac9' }}>Bus</option>
                      <option value={5}  style={{ color: namePropertyInsured === '5' ? '#171d25' : '#b0bac9' }}>Car</option>
                      <option value={6}  style={{ color: namePropertyInsured === '6' ? '#171d25' : '#b0bac9' }}>CASH - Margin Account</option>
                      <option value={7}  style={{ color: namePropertyInsured === '7' ? '#171d25' : '#b0bac9' }}>CASH - Current Account</option>
                      <option value={8}  style={{ color: namePropertyInsured === '8' ? '#171d25' : '#b0bac9' }}>Land for business purposes</option>
                      <option value={9}  style={{ color: namePropertyInsured === '9' ? '#171d25' : '#b0bac9' }}>Condominiums </option>
                      <option value={10}  style={{ color: namePropertyInsured === '10' ? '#171d25' : '#b0bac9' }}>Constrction Equipment incl. cranes, excavators </option>
                      <option value={11}  style={{ color: namePropertyInsured === '11' ? '#171d25' : '#b0bac9' }}>Consumer Goods </option>
                      <option value={12}  style={{ color: namePropertyInsured === '12' ? '#171d25' : '#b0bac9' }}>CRE under construction</option>
                      <option value={13}  style={{ color: namePropertyInsured === '13' ? '#171d25' : '#b0bac9' }}>Earth Moving Machinery</option>
                      <option value={14}  style={{ color: namePropertyInsured === '14' ? '#171d25' : '#b0bac9' }}>Farm House Building</option>
                      <option value={15}  style={{ color: namePropertyInsured === '15' ? '#171d25' : '#b0bac9' }}>Fitments, Furniture, Fixture</option>
                      <option value={16}  style={{ color: namePropertyInsured === '16' ? '#171d25' : '#b0bac9' }}>Forest</option>
                      <option value={17}  style={{ color: namePropertyInsured === '17' ? '#171d25' : '#b0bac9' }}>Forklift</option>
                      <option value={18}  style={{ color: namePropertyInsured === '17' ? '#171d25' : '#b0bac9' }}>Garage/parking lot being part of RRE</option>
                      <option value={19}  style={{ color: namePropertyInsured === '19' ? '#171d25' : '#b0bac9' }}>Gas Station</option>
                      <option value={20}  style={{ color: namePropertyInsured === '20' ? '#171d25' : '#b0bac9' }}>Guarantee</option>
                      <option value={21}  style={{ color: namePropertyInsured === '21' ? '#171d25' : '#b0bac9' }}>GUARANTEES-Banks</option>
                      <option value={22}  style={{ color: namePropertyInsured === '22' ? '#171d25' : '#b0bac9' }}>GUARANTEES-MDB</option>
                      <option value={23}  style={{ color: namePropertyInsured === '23' ? '#171d25' : '#b0bac9' }}>GUARANTEES-Other</option>
                      <option value={24}  style={{ color: namePropertyInsured === '24' ? '#171d25' : '#b0bac9' }}>Heavy Industrial Equipment</option>
                      <option value={25}  style={{ color: namePropertyInsured === '25' ? '#171d25' : '#b0bac9' }}>Holiday Weekend Houses</option>
                      <option value={26}  style={{ color: namePropertyInsured === '26' ? '#171d25' : '#b0bac9' }}>Hotel at prime location</option>
                      <option value={27}  style={{ color: namePropertyInsured === '27' ? '#171d25' : '#b0bac9' }}>Industrial Property, Factory</option>
                      <option value={28}  style={{ color: namePropertyInsured === '28' ? '#171d25' : '#b0bac9' }}>IT - Office Equipment</option>
                      <option value={29}  style={{ color: namePropertyInsured === '29' ? '#171d25' : '#b0bac9' }}>Land plot for constructing of CRE</option>
                      <option value={30}  style={{ color: namePropertyInsured === '30' ? '#171d25' : '#b0bac9' }}>Land plot for constructing of RRE</option>
                      <option value={31}  style={{ color: namePropertyInsured === '31' ? '#171d25' : '#b0bac9' }}>Land plot without invention for constructing</option>
                      <option value={32}  style={{ color: namePropertyInsured === '32' ? '#171d25' : '#b0bac9' }}>Light Machinery (movable)</option>
                      <option value={33}  style={{ color: namePropertyInsured === '33' ? '#171d25' : '#b0bac9' }}>Logistic Centers</option>
                      <option value={34}  style={{ color: namePropertyInsured === '34' ? '#171d25' : '#b0bac9' }}>Mixed Building (shops/offices/apartments) CRE prevailing</option>
                      <option value={35}  style={{ color: namePropertyInsured === '35' ? '#171d25' : '#b0bac9' }}>Mixed Building (shops/offices/apartments) RRE prevailing</option>
                      <option value={36}  style={{ color: namePropertyInsured === '36' ? '#171d25' : '#b0bac9' }}>Multi Family house (max. 4 apartm.)</option>
                      <option value={37}  style={{ color: namePropertyInsured === '37' ? '#171d25' : '#b0bac9' }}>Nursing Home (healthcare)</option>
                      <option value={38}  style={{ color: namePropertyInsured === '38' ? '#171d25' : '#b0bac9' }}>Offices</option>
                      <option value={39}  style={{ color: namePropertyInsured === '39' ? '#171d25' : '#b0bac9' }}>Other agricultural products</option>
                      <option value={40}  style={{ color: namePropertyInsured === '40' ? '#171d25' : '#b0bac9' }}>Other movable assets</option>
                      <option value={41}  style={{ color: namePropertyInsured === '41' ? '#171d25' : '#b0bac9' }}>Other turism and enterainment</option>
                      <option value={42}  style={{ color: namePropertyInsured === '42' ? '#171d25' : '#b0bac9' }}>Other vehicle</option>
                      <option value={43}  style={{ color: namePropertyInsured === '43' ? '#171d25' : '#b0bac9' }}>Precious metals, jewelry</option>
                      <option value={44}  style={{ color: namePropertyInsured === '44' ? '#171d25' : '#b0bac9' }}>Premises/shops</option>
                      <option value={45}  style={{ color: namePropertyInsured === '45' ? '#171d25' : '#b0bac9' }}>Production line (factory)</option>
                      <option value={46}  style={{ color: namePropertyInsured === '46' ? '#171d25' : '#b0bac9' }}>Railway wagon, railcar</option>
                      <option value={47}  style={{ color: namePropertyInsured === '47' ? '#171d25' : '#b0bac9' }}>Raw/Developed Land</option>
                      <option value={48}  style={{ color: namePropertyInsured === '48' ? '#171d25' : '#b0bac9' }}>RECEIVABLES-Single receivables</option>
                      <option value={49}  style={{ color: namePropertyInsured === '49' ? '#171d25' : '#b0bac9' }}>Restaurants, catering facilities</option>
                      <option value={50}  style={{ color: namePropertyInsured === '50' ? '#171d25' : '#b0bac9' }}>Row house</option>
                      <option value={51}  style={{ color: namePropertyInsured === '51' ? '#171d25' : '#b0bac9' }}>PRE under construction</option>
                      <option value={52}  style={{ color: namePropertyInsured === '52' ? '#171d25' : '#b0bac9' }}>Shopping centre/business park</option>
                      <option value={53}  style={{ color: namePropertyInsured === '53' ? '#171d25' : '#b0bac9' }}>Single apartments</option>
                      <option value={54}  style={{ color: namePropertyInsured === '54' ? '#171d25' : '#b0bac9' }}>Single Family House</option>
                      <option value={55}  style={{ color: namePropertyInsured === '55' ? '#171d25' : '#b0bac9' }}>Storages/Warehouse</option>
                      <option value={56}  style={{ color: namePropertyInsured === '56' ? '#171d25' : '#b0bac9' }}>Trailer</option>
                      <option value={57}  style={{ color: namePropertyInsured === '57' ? '#171d25' : '#b0bac9' }}>Truck</option>
                      <option value={58}  style={{ color: namePropertyInsured === '58' ? '#171d25' : '#b0bac9' }}>(Various) goods on stock</option>
                      <option value={59}  style={{ color: namePropertyInsured === '59' ? '#171d25' : '#b0bac9' }}>workshop</option>
                      <option value={60}  style={{ color: namePropertyInsured === '60' ? '#171d25' : '#b0bac9' }}>Other</option>

                    </select>
                  </div>
                  <div className="w-1/3 ml-1 md:ml-0 text-[10px] md:text-[14px] lg:text-sm text-[#0f1d31 ">
                    <input className="text-[#0f1d31] font-regular bg-[#f5f6fa]  w-[230px] ml-64 lg:ml-0 mr-10 md:mr-0 h-[32px] text-[10px] md:text-[14px] lg:text-sm mt-1 pl-3 rounded-md" placeholder={t('address_insured_property')} id="" name="" type="text" value={insuredPropertyAddress} onChange={(e) => setInsuredPropertyAddress(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                  <label htmlFor="message" className="text-base text-[#0f1e2f] pl-4 leading-6 font-medium">{t('note')}</label>
                  <div>
                    <textarea id="message" rows="2" className="w-11/12 ml-4 px-4 py-[14px] mt-2 bg-[#f7f8fb] font-regular text-[#0f1e2f] text-[15px] leading-6 rounded-md" placeholder={t('note')} value={mortgageDataNotes} onChange={(e) => setMortgageDataNotes(e.target.value)}></textarea>
                  </div>
              </div>
              </div>
            </div>}

            {/* <div className='bg-white pl-10 py-6 mt-10 rounded-xl relative'>
            <div>
              <p className='text-[#171d25] font-semibold text-[22px] pb-2'>{t('documents')}</p>
              <label
                htmlFor="myFile"
                className="custom-file-upload block text-xs font-bold text-[#0f1d31]"
              >
                <div className="my-3 max-w-[154px] py-3 px-3 border-[1px] border-[#86b1ad] border-dashed rounded-[3px] cursor-pointer bg-[#f7f8f9] text-[#86b1ad]">
                  {t('upload_documents')}
                </div>
                <input
                  type="file"
                  id="myFile"
                  name="myFile"
                  onChange={handleFileChange}
                  hidden
                  multiple
                />
              </label>
            </div>
            <div className="">
              {files.map((file, index) => (
                <div
                  key={file.name} className="flex flex-columns bg-white max-w-[300px] py-3 px-3  border-[1px] border-[#e5e9f2] rounded-[3px]">
                  <p className="text-sm whitespace-wrap">{file.name}</p>
                  <p className="text-[#b0bac9] text-xs">
                    {file.size}
                  </p>
                  <div className="ml-auto pl-5">
                    <button
                      className="" onClick={() => handleFileDelete(index)}>
                      <img src={XButton} alt="x-button" className="mx-auto w-[21px] px-1 py-1 h-[21px] bg-[#f8fafc] my-3 right-0" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          </div>
          <div className=" justify-center bottom-0  mt-20 md:mt-10 lg:mt-[90px]  mb-4 mx-[10%] md:mx-[20%] lg:mx-[8%] ">
            <div className="rounded-2xl bg-white w-full mb-10 shadow-lg relative pt-6 ">
              {selectedPartner && selectedPartner.partner && (
                <img src={getLogoByPartner(selectedPartner.partner)} alt='Partner Logo' className="bg-white rounded-[100%] w-[120px] h-[120px] mt-[-25%] md:mt-[-16%] lg:mt-[-25%] shadow-2xl items-center mx-auto"
                />
              )}
              <div className="horizontal container z-100 p-6 ">
                <div className="flex flex-col ">
                  <ul>
                    <li className="text-right">
                      <p className="text-[8px] md:text-[15px] font-regular  text-[#171d25]"> {t('monthly_premium')}</p>
                      <p className="pt-1 font-semibold text-[9px] md:text-[16px] text-[#171d25]">{formattedPrimMujor}</p>
                    </li>
                    <li className="pt-6  text-right">
                      <h1 className="text-[8px] md:text-[15px]  font-regular  text-[#171d25]"> {t('annual_premium')} </h1>
                      <p className="pt-1 font-semibold text-[9px]md:text-[16px] text-[#171d25]">{formattedPrimVjetor}</p>
                    </li>
                    <li className="pt-6 text-right">
                      <h1 className="text-[8px] md:text-[15px] font-regular  text-[#171d25]"> {t('total_premium')} </h1>
                      <p className="pt-1 font-semibold text-[9px]md:text-[16px] text-[#171d25]">€ {formattedPrimTotal}</p>
                    </li>
                    <div className="mt-8">
                      <div className="">
                        <label className="ml-2 mt-4 text-sm text-[#313a3c] leading-tight">
                          <input
                            type="checkbox"
                            checked={termsChecked}
                            onChange={handleTermsChange}
                            className="mr-2 text-[#313a3c] border-gray-300 rounded accent-yellow-300"
                          />
                          <span className="mr-1 tracking-tighter font-regular text-[11px] md:text-base"> {t('i_agree')} </span>
                          <span className="underline font-semibold text-[10px] md:text-sm">
                            <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
                              {t('terms_of_use')}
                              <br />
                            </Link>
                            <Link className="ml-8 font-semibold" to="/terms-of-use" target="_blank" rel="noopener noreferrer">
                              {t('and_privacy_policy')}
                            </Link>
                          </span>
                        </label>
                      </div>
                      <div className="mt-2">
                        <label className="ml-2 tracking-tighter text-sm text-[10px] md:text-base text-[#313a3c] font-regular">
                          <input
                            type="checkbox" className="mr-2 accent-yellow-300"
                            checked={signatureChecked} onChange={handleSignatureChange}
                          />
                          {t('my_electronic_signature')}
                        </label>
                      </div>
                      {error && <p className="text-red-500 text-sm ml-6 mt-2 tracking-tighter">{error}</p>}
                    </div>
                    <div className="flex justify-center pt-2">
                      {hasError && error && (
                        <p className="text-red-500 text-sm mt-2">{error}</p>
                      )}

                      <button
                        className="bg-[#f8f000] hover:bg-[#E0D815] font-sans text-[#313a3c] py-2 my-3 rounded-[6px] font-semibold w-1/2 md:w-[290px] shadow-xl"
                        onClick={handleSubmit}
                      >
                        {t('buy_policy')}
                      </button>
                      {showUpcPaymentComponent && <UpcPaymentComponent />}
                    </div>
                    {showConfirmation && (
                      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white rounded-md mx-[10%] md:mx-0 md:w-[492px] md:h-[349px] p-10 md:p-[60px]">
                          <h1 className="mb-4 font-semibold text-lg md:text-2xl text-center text-[#313a3c]"> {t('confirmation')} </h1>
                          <p className="mb-4 text-center text-xs md:text-sm font-medium">{t('do_you_to_complete_the_purchase')}</p>
                          <button type="submit" className="md:w-[380px] h-[52px] w-[250px] rounded-md text-[#fff] mt-6 uppercase bg-[#313a3c] hover:bg-[#374151] text-sm md:text-base" onClick={handleConfirm}>
                            {isLoading == true ? <YourCircularIndicatorComponent /> : t('complete_purchase')}
                          </button>
                          <div className="pb-6">
                            <button type="button" className=" w-[250px] md:w-[380px] h-[52px] rounded-md text-[#313a3c] mt-2 font-semibold uppercase bg-[#fff] hover:bg-[#f5f6fa]" onClick={handleCancel}>
                              {t('cancel')}
                            </button>
                            {errorMessage && <p className="text-sm font-medium text-red-700">{errorMessage}</p>}

                          </div>
                        </div>
                      </div>
                    )}
                    {showSuccess && (
                      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white rounded-md w-[492px] h-[349px] p-12">
                          <img src={Success} alt="" className='mx-auto my-auto block mb-4' />
                          <h1 className="mb-4 font-semibold text-[24px] text-center text-[#313a3c]"> {t('wiht_success')} </h1>
                          <p className=" text-center text-sm font-medium mb-8"> {t('successfully_accepted')} </p>
                          <Link to="/home">
                            <button type="button" className="w-[380px] h-[52px] rounded-md text-[#fff] uppercase bg-[#313a3c] hover:bg-[#374151] " onClick={handleSuccessClose}>
                              {t('continue')}
                            </button>
                          </Link>

                        </div>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div className="relative bottom-0">
      <Footer landing="landing" />
    </div>
  </div>

)
}

export default ProductDetails;