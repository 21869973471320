import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import Navbar from "../navbar/Navbar";
import InformationIcon from "../../assets/icons/white-information.svg";
import SigalColor from "../../assets/images/sigal.png";
import SigalLifeColor from "../../assets/images/sigal-life.png";
import SigkosColor from "../../assets/images/sigkos.png";
import ScardianColor from "../../assets/images/scardian.png";
import SiguriaColor from "../../assets/images/siguria.png";
import ElsigColor from "../../assets/images/elsig.png";
import PrisigColor from "../../assets/images/prisig.png";
import DukagjiniColor from "../../assets/images/dukagjini.png";
import EurosigColor from "../../assets/images/eurosig.png";
import IlyriaColor from "../../assets/images/illyria.png";
import IlyriaLifeColor from "../../assets/images/illyria-life.png";
import SigmaColor from "../../assets/images/sigma.png";
import SigalLogoIcon from "../../assets/icons/sigal-symbol.png";
import SigalLifeLogoIcon from "../../assets/icons/sigal-life-symbol.png";
import SigkosLogoIcon from "../../assets/icons/sigos-symbol.png";
import ScardianLogoIcon from "../../assets/icons/scardian-symbol.png";
import SiguriaLogoIcon from "../../assets/icons/siguria-symbol.png";
import ElsigLogoIcon from "../../assets/icons/elsig-symbol.png";
import PrisigLogoIcon from "../../assets/icons/prisig-symbol.png";
import DukagjiniLogoIcon from "../../assets/icons/dukagjini-symbol.png";
import EurosigLogoIcon from "../../assets/icons/eurosig-symbol.png";
import IlyriaLogoIcon from "../../assets/icons/illyria-symbol.png";
import SigmaLogoIcon from "../../assets/icons/sigma-symbol.png";
import KaskoBg from "../../assets/images/sigurimi-kasko-automjetit-bg.png";
import Background from "../../assets/images/Background.svg";
import Footer from "../footer/Footer";
import api from "../../services/api";

const SigurimiKaskoIAutomjetit = (landing) => {
  useEffect(() => {
    getLoanDataFromRice();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setMonth(today.getMonth() + 1);
  const id = queryParams.get("id");
  const data = "data";

  const [error, setError] = useState("");
  const [loanId, setLoanId] = useState("");
  const [period_to, setPeriodTo] = useState("");
  const [primMujor, setPrimMujor] = useState("");
  const [primTotal, setPrimTotal] = useState("");
  const [sumInsured, setSumInsured] = useState("");
  const [primVjetor, setPrimvjetor] = useState("");
  const [period_from, setPeriodFrom] = useState(
    today.toISOString().split("T")[0]
  );
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedValue, setSelectedValue] = useState("al");
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [productPartnersList, setProductPartnersList] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("select");
  const [productPartnersDetailsList, setProductPartnersDetailsList] = useState(
    []
  );
  const [firstPaymentDate, setFirstPaymentDate] = useState(
    today.toISOString().split("T")[0]
  );

  const [internalUserVerifyStatus, setInternalUserVerifyStatus] =
    useState(null);

  const name = queryParams.get("name");
  const ribk = queryParams.get("ribk");
  const mortgage = queryParams.get("mortgage");
  const order_no = queryParams.get("order_no");
  const loan_info = queryParams.get("loan_info");
  const created_at = queryParams.get("created_at");
  const updated_at = queryParams.get("updated_at");
  const numerator_fk = queryParams.get("numerator_fk");
  const product_type = queryParams.get("product_type");
  const product_notes = queryParams.get("product_notes");
  const online_product = queryParams.get("online_product");
  const vehicle_details = queryParams.get("vehicle_details");
  const health_statement = queryParams.get("health_statement");
  const product_category = queryParams.get("product_category");
  const third_product_notes = queryParams.get("third_product_notes");
  const fifth_product_notes = queryParams.get("fifth_product_notes");
  const second_product_notes = queryParams.get("second_product_notes");
  const fourth_product_notes = queryParams.get("fourth_product_notes");
  const the_first_product_title = queryParams.get("the_first_product_title");
  const the_second_product_title = queryParams.get("the_second_product_title");
  const the_third_product_title = queryParams.get("the_third_product_title");
  const the_fourth_product_title = queryParams.get("the_fourth_product_title");
  const the_fifth_product_title = queryParams.get("the_fifth_product_title");

  const bankAccountNumberLocally = Cookies.get("bank_account_number_locally");

  const formattedPrimMujor = formatNumber(primMujor);
  const formattedPrimVjetor = formatNumber(primVjetor);
  const formattedPrimTotal = formatNumber(primTotal);

  function formatNumber(str) {
    let num = parseFloat(str);
    num = Math.round(num * 100) / 100;
    return num.toString();
  }

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handlePeriodFrom = (event) => {
    setPrimvjetor("-");
    setPrimMujor("-");
    setPrimTotal("-");
    setPeriodFrom(event.target.value);
  };

  const handlePeriodTo = (event) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();

    if (selectedDate > today) {
      setPrimvjetor("-");
      setPrimMujor("-");
      setPrimTotal("-");
      setPeriodTo(event.target.value);
    }
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    const minDate = nextDay.toISOString().split("T")[0];
  };

  const PaymentComponent = () => {
    useEffect(() => {
      if (
        bankAccountNumberLocally &&
        !bankAccountNumberLocally.startsWith("15")
      ) {
        setSelectedPaymentMethod(3);
      }
    }, [bankAccountNumberLocally]);

    const handlePaymentMethodChange = (event) => {
      setPrimvjetor("-");
      setPrimMujor("-");
      setPrimTotal("-");
      const selectedValue = event.target.value;
      let numericValue;

      if (selectedValue === "monthly") {
        numericValue = 1;
      } else if (selectedValue === "yearly") {
        numericValue = 2;
      } else {
        numericValue = null;
      }
      setSelectedPaymentMethod(numericValue);
    };

    return (
      <div>
        <label
          className="block text-base pl-1 text-[#171d25] font-medium"
          htmlFor="dropdown"
        >
          {t("payment_method")}
        </label>

        <select
          id="paymentMethod"
          className="block w-[290px] lg:w-[220px] lg:mx-1 h-[52px]  font-regular text-[15px] text-[#a5aebd] bg-[#f7f8fb] rounded-md px-2"
          value={
            selectedPaymentMethod === 1
              ? "monthly"
              : selectedPaymentMethod === 2
              ? "yearly"
              : "select"
              
          }
          onChange={handlePaymentMethodChange}
          disabled={selectedPaymentMethod === 3}
          style={{ color: selectedPaymentMethod === 2 ? '#171d25' : (selectedPaymentMethod === 1 ? '#171d25' : '#a5aebd') }}
          >
            <option value="select" style={{ color: '#a5aebd' }}>{t("select")}</option>
            <option value="monthly" style={{ color: selectedPaymentMethod === 1 ? '#171d25' : '#a5aebd' }}>{t("monthly")}</option>
            <option value="yearly" style={{ color: selectedPaymentMethod === 2 ? '#171d25' : '#a5aebd' }}>{t("yearly")}</option>
          </select>
      </div>
    );
  };

  const FirstPaymentDateComponent = () => {
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setMonth(today.getMonth() + 1);

    const handleFirstPaymentDate = (event) => {
      setPrimvjetor("-");
      setPrimMujor("-");
      setPrimTotal("-");
      setFirstPaymentDate(event.target.value);
    };

    return (
      <div className="">
      <label
        className="block text-base text-[#171d25] font-medium pl-1 pt-4 lg:pt-0"
        htmlFor="PeriodFrom"
      >
        {t("first_payment")}
      </label>
      <div className="relative inline-block">
        <input
          id="PeriodFrom"
          type="date"
          value={firstPaymentDate}
          onChange={handleFirstPaymentDate}
          placeholder={firstPaymentDate === "" ? "Select Date" : ""}
          className="block w-[290px] lg:w-[220px] lg:mx-1 h-[52px] bg-[#f7f8fb] font-regular text-[15px] text-[${firstPaymentDate ? '#171d25' : '#a5aebd'}] rounded-md px-2"
        />
      </div>
    </div>
  );
};

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    setFirstPaymentDate(selectedDate.toISOString().split("T")[0]);
  };

  const handleSumInsuredChange = (event) => {
    setPrimvjetor("-");
    setPrimMujor("-");
    setPrimTotal("-");
    const inputValue = event.target.value;
    if (inputValue === "") {
      setSumInsured("");
    } else {
      let convertSumInsuredToDouble = parseFloat(inputValue, 10);
      setSumInsured(convertSumInsuredToDouble);
      Cookies.set("sum_insured_for_api_call", convertSumInsuredToDouble);
    }
    const parsedValue = parseFloat(inputValue);
    if (!isNaN(parsedValue)) {
      setSumInsured(parsedValue.toString());
    } else {
      setSumInsured("");
    }
  };

  const getLogoByPartner = (partner) => {
    switch (partner) {
      case 1:
        return SigalLifeLogoIcon;
      case 2:
        return SigalLogoIcon;
      case 3:
        return PrisigLogoIcon;
      case 4:
        return DukagjiniLogoIcon;
      case 5:
        return ElsigLogoIcon;
      case 6:
        return EurosigLogoIcon;
      case 7:
        return IlyriaLogoIcon;
      case 8:
        return IlyriaLogoIcon;
      case 9:
        return EurosigColor;
      case 10:
        return ScardianLogoIcon;
      case 11:
        return SigkosLogoIcon;
      case 12:
        return SigmaLogoIcon;
      case 13:
        return SiguriaLogoIcon;
      default:
        return null;
    }
  };

  const handleCliRequestNavigate = (e) => {
    e.preventDefault();
    if (
      !loanId ||
      !period_to ||
      !sumInsured ||
      !period_from ||
      !selectedPartner ||
      selectedPaymentMethod === "select" ||
      !firstPaymentDate
    ) {
      setError("Please fill in all fields.");
    } else {
      setError("");
      Cookies.set("product_name_local_storage", name);
      navigate("/cli-request", { state: { selectedPartner } });
    }
  };

  const insuranceDetails = async (partner) => {
    setSelectedPartner({ partner });
    try {
      Cookies.set("partner_id_for_api_call", partner);

      Cookies.set("loan_info_status", loan_info);
      Cookies.set("mortage_status", mortgage);
      Cookies.set("vehicle_details_status", vehicle_details);
      Cookies.set("health_statement_status", health_statement);
      const partnerProductDetailsResponse = await api.get(
        `/partners/api/partner-product-config/${id}/${[partner]}/`
      );
      if (partnerProductDetailsResponse.status === 200) {
        Cookies.set(
          "product_fee_type",
          partnerProductDetailsResponse.data[0].fee_type
        );
        Cookies.set("product_fee", partnerProductDetailsResponse.data[0].fee);
      } else {
      }
    } catch (error) {
      console.error(error);
    }

    try {
      const product_fee_type = Cookies.get("product_fee_type");
      const product_tarif = Cookies.get("product_fee");
      const int_product_fee_type = parseInt(product_fee_type, 10);
      const int_product_fee = parseInt(product_tarif, 10);
      const double_sum_insured = parseFloat(sumInsured, 10);

      const response = await api.post(`/requests/api/insurance-details/`, {
        insurance_period_to: period_to,
        first_payment_date: firstPaymentDate,
        payment_method: selectedPaymentMethod,
        sum_insured: double_sum_insured,
        fee_type: int_product_fee_type,
        tarif: int_product_fee,
      });
      let premium = response.data.premium;
      let number_of_installments = response.data.month_count;
      let number_of_installments_for_yearly = response.data.year_count;

      Cookies.set("payment_method_for_api_call", selectedPaymentMethod);
      Cookies.set(
        "number_of_installments_for_api_call",
        number_of_installments
      );
      Cookies.set(
        "number_of_installments_for_yearly_for_api_call",
        number_of_installments_for_yearly
      );
      Cookies.set("fee_type_for_api_call", int_product_fee_type);
      Cookies.set("tarif_for_api_call", int_product_fee);
      Cookies.set("premium_for_api_call", premium);
      Cookies.set("period_from_api_call", period_from);
      Cookies.set("period_to_api_call", period_to);
      Cookies.set("first_payment_date", firstPaymentDate);
      if (int_product_fee_type === 1) {
        if (selectedPaymentMethod === 1) {
          let prim_vjetor = premium * 12;
          setPrimvjetor(prim_vjetor);
          let prim_mujor = int_product_fee;
          setPrimMujor(prim_mujor);
          let prim_total = premium * number_of_installments;
          setPrimTotal(prim_total);
          Cookies.set("prim_mujor", prim_mujor);
          Cookies.set("prim_vjetor", prim_vjetor);
          Cookies.set("prim_total", prim_total);
        }
        if (selectedPaymentMethod === 2) {
          let prim_vjetor_yearly = premium * 12;
          setPrimvjetor(prim_vjetor_yearly);
          setPrimMujor("-");
          let prim_total_yearly = premium * number_of_installments_for_yearly;
          setPrimTotal(prim_total_yearly);
          Cookies.set("prim_mujor", "-");
          Cookies.set("prim_vjetor", prim_vjetor_yearly);
          Cookies.set("prim_total", prim_total_yearly);
        }
        if (selectedPaymentMethod === 3) {
          setPrimvjetor("-");
          setPrimMujor("-");
          setPrimTotal(premium);
          Cookies.set("prim_mujor", "-");
          Cookies.set("prim_vjetor", "-");
          Cookies.set("prim_total", premium);
        }
      }
      if (int_product_fee_type === 2) {
        if (selectedPaymentMethod === 1) {
          setPrimvjetor(premium);
          let prim_mujor_total_percentage = premium / 12;
          setPrimMujor(prim_mujor_total_percentage);
          let prim_total_percentage = (premium / 12) * number_of_installments;
          setPrimTotal(prim_total_percentage);
          Cookies.set("prim_mujor", prim_mujor_total_percentage);
          Cookies.set("prim_vjetor", premium);
          Cookies.set("prim_total", prim_total_percentage);
        }
        if (selectedPaymentMethod === 2) {
          setPrimvjetor(premium);
          setPrimMujor("-");
          let prim_total_percentage =
            (premium / 12) * number_of_installments_for_yearly;
          setPrimTotal(prim_total_percentage);

          Cookies.set("prim_mujor", "-");
          Cookies.set("prim_vjetor", premium);
          Cookies.set("prim_total", prim_total_percentage);
        }
        if (selectedPaymentMethod === 3) {
          setPrimvjetor("-");
          setPrimMujor("-");
          setPrimTotal(premium);
          Cookies.set("prim_mujor", "-");
          Cookies.set("prim_vjetor", "-");
          Cookies.set("prim_total", premium);
        }
      }
    } catch (error) {}
  };

  const productPartners = async (event) => {
    event.preventDefault();
    Cookies.set("product_id_for_api_call", id);
    try {
      const response = await api.get(
        `/requests/api/${id}/filter-partner-products-api/`
      );
      setProductPartnersList(response.data);
    } catch (error) {}

    try {
      const response = await api.get(`/requests/api/get-loan-info/123/`);
      setLoanId(response.data.loan);
    } catch (error) {
      console.error(error);
    }

    try {
      const partnerProductDetailsResponse = await api.get(
        `/partners/api/partner-product-config/${id}/`
      );
      if (partnerProductDetailsResponse.status === 200) {
        setProductPartnersDetailsList(partnerProductDetailsResponse.data);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const SignupForm = () => {
    const formik = useFormik({
      initialValues: {
        selectedPartner: selectedPartner || "",
      },
      onSubmit: (values) => {
        alert(JSON.stringify(values, null, 2));
      },
    });
    return (
      <form onSubmit={formik.handleSubmit}>
        <label
          htmlFor="firstName"
          name="firstName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.firstName}
        />
        <label htmlFor="lastName">{t("last_name")}</label>
        <input
          id="lastName"
          name="lastName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.lastName}
        />
        <label htmlFor="email"> {t("email_address")} </label>
        <input
          id="email"
          name="email"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        <button type="submit"> {t("submit")} </button>
      </form>
    );
  };

  const validateForm = () => {
    if (
      loanId === "" ||
      period_to === "" ||
      sumInsured === "" ||
      period_from === "" ||
      selectedPartner === null ||
      selectedPaymentMethod === "select" ||
      firstPaymentDate === ""
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };

  useEffect(() => {
    validateForm();
  }, [
    loanId,
    period_to,
    sumInsured,
    period_from,
    selectedPartner,
    selectedPaymentMethod,
    firstPaymentDate,
  ]);

  // const [mortgageDataNotes, setMortgageDataNotes] = useState('');
  const [
    isLoadingForGettingLoanDataFromRice,
    setIsLoadingForGettingLoanDataFromRice,
  ] = useState(false);
  // const [mortgageDataNotes, setMortgageDataNotes] = useState('');
  const [loanDataFromRiceList, setlLoanDataFromRiceList] = useState([]);
  const [
    selectetIndexFromLoanDataFromRiceList,
    setSelectetIndexFromLoanDataFromRiceList,
  ] = useState([]);

  const getLoanDataFromRice = async () => {
    setIsLoadingForGettingLoanDataFromRice(true);
    try {
      const legalNumber = Cookies.get("legal_number");
      const getLoaDataFromRiceResponse = await api.get(
        `/requests/api/get-loan-data-from-rice/${legalNumber}/ACTIVE/`
      );
   
      if (getLoaDataFromRiceResponse.status === 200) {

        // if (getLoaDataFromRiceResponse.data && getLoaDataFromRiceResponse.data.loans && getLoaDataFromRiceResponse.data.loans.length > 0) {
        setlLoanDataFromRiceList(getLoaDataFromRiceResponse.data.data.loans);
        // }
      } else if (getLoaDataFromRiceResponse.status === 404) {
        // setErrorMessage('Ky klient nuk ka asnje loan agreemet!');
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoadingForGettingLoanDataFromRice(false);
  };

  const YourCircularIndicatorComponent = () => {
    return (
      <div className="flex justify-center items-center">
        <div
          style={{ borderColor: "#FFDD00" }}
          className="w-6 h-6 border-t-4 border-yellow-500 border-solid rounded-full animate-spin"
        ></div>
      </div>
    );
  };

  const [selectedIndex, setSelectedIndex] = useState(null);

  // Handler for when the dropdown selection changes
  const handleSelectLoanDataFromRiceElement = (event) => {
    const selectedLoanIndex = loanDataFromRiceList.findIndex(
      (loan) => loan.loanId === event.target.value
    );
    setSelectedIndex(selectedLoanIndex);

    Cookies.set(
      "loanDataFromRice",
      loanDataFromRiceList[selectedLoanIndex].loanId
    );
    Cookies.set(
      "branchCodeFromRice",
      loanDataFromRiceList[selectedLoanIndex].origination.organizationBranchCode
    );
    Cookies.set(
      "sfeFromRice",
      loanDataFromRiceList[selectedLoanIndex].origination.agentCode
    );
    Cookies.set(
      "initialAmountFromRice",
      loanDataFromRiceList[selectedLoanIndex].approvedAmount.amount
    );
    Cookies.set(
      "outstandingAmountFromRice",
      loanDataFromRiceList[selectedLoanIndex].openAmount.amount
    );
    Cookies.set(
      "periodFromLoanFromRice",
      loanDataFromRiceList[selectedLoanIndex].schedule.disbursmentDate
    );
    Cookies.set(
      "periodToLoanFromRice",
      loanDataFromRiceList[selectedLoanIndex].schedule.maturityReferenceDateType
    );
    Cookies.set(
      "apsNumberFromRice",
      loanDataFromRiceList[selectedLoanIndex].applicationId
    );
  };

  return (
    <div onChange={productPartners} className="relative overflow-hidden ">
      <div className=" bg-[#171d25] lg:bg-transparent">
        <div className="bg-white shadow-md w-full z-50 fixed">
          <Navbar type="authentication" authType="loggedIn" landing={landing} />
        </div>
        <img
          src={KaskoBg}
          alt=""
          className="w-full hidden lg:block object-cover"
        />
        <div className="flex justify-center items-center bg-[#171d25] lg:bg-transparent md:ml-20 lg:ml-36">
          <div className="lg:absolute top-1 transform z-10 container">
            <div>
              <h1 className="text-[#ffffff] text-xl lg:text-5xl font-sans pt-12 mb-4 mt-20 pl-5 md:pl-0">
                {name}
              </h1>
              <p className="flex text-xs md:text-base text-[#ffffff] font-regular px-4 md:px-0 md:w-2/3 mb-4 pl-5 md:pl-0">
                {product_notes}
              </p>
            </div>
            <div className="md:flex ">
              <div className="md:flex">
                <div>
                  {loan_info === "1" &&
                  bankAccountNumberLocally.startsWith("15") ? (
                    <div className="grid grid-cols-2">
                      <div className="md:flex items-center mb-4">
                        <div className="flex items-center mb-4">
                          <div className="dropdown mt-5">
                            <select
                              id="loanId"
                              className="block w-[254px] h-[52px] backdrop-blur-sm bg-white/30 text-[#fff] rounded-md pl-2"
                              onChange={handleSelectLoanDataFromRiceElement}
                            >
                              <option value="" disabled selected hidden>
                                Select Loan
                              </option>
                              {loanDataFromRiceList &&
                                loanDataFromRiceList.map((loan, index) => (
                                  <option key={index} value={loan.loanId}>
                                    {loan.loanId}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {isLoadingForGettingLoanDataFromRice === true ? (
                            <div>
                              <YourCircularIndicatorComponent />
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {/* <div>
                          <button className="w-[181px] h-[52px] rounded-md text-[#0f1d31] text-base font-bold bg-[#f7ee00] hover:bg-[#E0D815] ml-4 mt-6" onClick={productPartners}>Merrni të dhëna</button>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="bg-white shadow-md rounded-lg py-7  px-8 w-[350px] lg:w-auto mx-auto md:mx-0">
                    <div className="lg:grid grid-cols-2">
                      <div className="pb-4 lg:pb-6">
                        <label
                          className="block text-base text-[#171d25] font-medium md:pb-1 pl-1"
                          htmlfor="dropdown"
                        >
                          {" "}
                          {t("isurance_amount")}{" "}
                        </label>
                        <div className="relative inline-block">
                          <input
                            id="sumInsured"
                            type="number"
                            value={sumInsured}
                            onChange={handleSumInsuredChange}
                            placeholder={sumInsured === "" ? "0.0" : ""}
                            className="w-[290px] lg:w-[220px] lg:mx-1 h-[52px] bg-[#f7f8fb] font-regular text-[15px] text-[#171d25]  rounded-md pr-12 pl-2"
                          />
                          <span className="absolute top-1/2 font-medium text-[#171d25] right-2 transform -translate-y-1/2">
                            €
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="lg:grid lg:grid-cols-4">
                      <PaymentComponent />
                      <FirstPaymentDateComponent />
                      <div>
                        <label
                          className="block text-base text-[#171d25] font-medium pt-4 lg:pt-0 pl-1"
                          htmlfor="date-picker"
                        >
                          {" "}
                          {t("period_from")}{" "}
                        </label>
                        <input
                          readOnly
                          id="PeriodFrom"
                          type="date"
                          className="block w-[290px]  lg:w-[220px]  font-regular text-[15px] text-[#171d25]  mx-1 h-[52px] bg-[#f7f8fb] rounded-md px-2"
                          placeholder={period_from}
                          value={period_from}
                          onChange={handlePeriodFrom}
                        />
                      </div>
                      <div>
                        <label
                          className="block text-base text-[#171d25] font-medium pl-1 pt-4 lg:pt-0"
                          htmlFor="date-picker"
                        >
                          {" "}
                          {t("to")}{" "}
                        </label>
                        <input
                          type="date"
                          id="PeriodTo"
                          className="block w-[290px] lg:w-[220px] lg:mx-1 h-[52px] bg-[#f7f8fb] font-regular text-[15px] rounded-md px-2"
                          placeholder="To"
                          value={period_to}
                          onChange={handlePeriodTo}
                          min={new Date().toISOString().split("T")[0]}
                          style={{ color: (period_to ? '#171d25' : '#a5aebd') }}
                        />
                      </div>


                    </div>
                  </div>
                  <div className="text-xs">
                    <div className="z-40 relative text-[#ffffff] font-medium  md:pb-8 mt-[46px] pl-5 md:pl-0 text-[16px]">
                      {" "}
                      {t("select_offers")}
                      <img
                        src={InformationIcon}
                        alt=""
                        className="ml-2 mb-0.5 inline-block z-50 relative"
                      />
                    </div>
                  </div>
                  {productPartnersDetailsList.length > 0 && (
                    <div className="bg-white rounded-lg shadow-md mt-4 md:mb-6 lg:mb-0 px-8 md:px-4 py-2 mx-auto w-[350px] md:w-[350px] lg:w-auto">
                      <div className="grid grid-cols-3  lg:grid-cols-6  gap-[25px] md:gap-[10px] ">
                        {productPartnersDetailsList.map((item, index) => (
                          <div
                            key={index}
                            onClick={() => insuranceDetails(item.partner)}
                          >
                            <div className="text-center my-3">
                              {item.partner === 1 && (
                                <img
                                  className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3 w-auto h-auto  px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                  src={SigalLifeColor}
                                  alt=""
                                />
                              )}
                              {item.partner === 2 && (
                                <img
                                  className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                  src={SigalColor}
                                  alt=""
                                />
                              )}
                              {item.partner === 3 && (
                                <img
                                  className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                  src={PrisigColor}
                                  alt=""
                                />
                              )}
                              {item.partner === 4 && (
                                <img
                                  className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                  src={DukagjiniColor}
                                  alt=""
                                />
                              )}
                              {item.partner === 5 && (
                                <img
                                  className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                  src={ElsigColor}
                                  alt=""
                                />
                              )}
                              {item.partner === 6 && (
                                <img
                                  className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3   w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                  src={EurosigColor}
                                  alt=""
                                />
                              )}
                            </div>
                            {item.partner === 7 && (
                              <img
                                className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                src={IlyriaColor}
                                alt=""
                              />
                            )}
                            {item.partner === 8 && (
                              <img
                                className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                src={IlyriaLifeColor}
                                alt=""
                              />
                            )}
                            {item.partner === 9 && (
                              <img
                                className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                src={EurosigColor}
                                alt=""
                              />
                            )}
                            {item.partner === 10 && (
                              <img
                                className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px]] logo hover:scale-110 transform transition-transform duration-300"
                                src={ScardianColor}
                                alt=""
                              />
                            )}
                            {item.partner === 11 && (
                              <img
                                className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3 w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                src={SigkosColor}
                                alt=""
                              />
                            )}
                            {item.partner === 12 && (
                              <img
                                className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px] logo hover:scale-110 transform transition-transform duration-300"
                                src={SigmaColor}
                                alt=""
                              />
                            )}
                            {item.partner === 13 && (
                              <img
                                className="hover:bg-[#f3f4f5] rounded-md focus:outline-none focus:ring focus:ring-[#f3f4f5] py-3  w-auto h-auto   px-1 md:h-[60px]] logo hover:scale-110 transform transition-transform duration-300"
                                src={SiguriaColor}
                                alt=""
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="relative rounded-lg bg-white w-[350px] md:w-[240px] lg:w-[270px] h-[380px] mb-10 md:mb-0 mt-20 md:mt-0 mx-auto md:mx-[5%] md:ml-2 lg:ml-10">
                <form className="relative">
                  {selectedPartner && selectedPartner.partner && (
                    <img
                      src={getLogoByPartner(selectedPartner.partner)}
                      alt="Selected Partner"
                      name="selectedPartner"
                      className="bg-white rounded-[100%] w-[120px] h-[120px] shadow-2xl absolute top-[-60px] left-1/2 transform -translate-x-1/2"
                    />
                  )}
                  <div className="horizontal container z-100 pt-20 md:pt-18 lg:pt-20 p-6 relative">
                    <div className="flex flex-col text-right">
                      <ul>
                        <li className="text-right">
                          <p className=" font-regular text-[15px] text-[#171d25]">
                            {t("monthly_premium")}
                          </p>
                          <p className="pt-1 font-medium text-[#171d25]  text-base">
                            €{" "}
                            {isNaN(formattedPrimMujor)
                              ? "-"
                              : formattedPrimMujor}
                          </p>
                        </li>
                        <li className="pt-6 text-[14px] font-thin">
                          <h1 className=" font-regular text-[15px] text-[#171d25]">{t("annual_premium")}</h1>
                          <p className="pt-1 font-medium text-[#171d25] text-base">
                            €{" "}
                            {isNaN(formattedPrimVjetor)
                              ? "-"
                              : formattedPrimVjetor}
                          </p>
                        </li>
                        <li className="pt-6 text-[14px] font-thin">
                          <h1 className=" font-regular text-[15px] text-[#171d25]">{t("total_premium")}</h1>
                          <p className="pt-1 font-medium text-[#171d25] text-base">
                            €{" "}
                            {isNaN(formattedPrimTotal)
                              ? "-"
                              : formattedPrimTotal}
                          </p>
                        </li>
                      </ul>
                      <div className="error-message text-[15px] font-medium text-[#FF0000]">{error}</div>
                    </div>
                    <div className="flex justify-center pt-6 bottom-0 left-0 w-full">
                      <button
                        onClick={handleCliRequestNavigate}
                        className="bg-[#f8f000] hover:bg-[#E0D815] text-[#0f1d31] py-2 rounded-[6px] font-sans text-base w-[240px] shadow-xl"
                      >
                        {t("buy_policy")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <img
          src={Background}
          alt="Background"
          className="w-screen hidden md:block md:h-[900px] lg:h-full object-cover"
        />
        <div className="flex justify-center items-center bg-[#f5f6fa] mb-6 md:mb-0 ">
          <div className="md:absolute top-1 transform z-10 w-full mx-auto container">
            <h1 className="text-[#171d25] text-2xl md:text-4xl pl-8 md:pl-20  lg:pl-24 lg:text-5xl font-snas pt-10 lg:pt-[100px] mb-12">
              {" "}
              {t("product_information")}{" "}
            </h1>
            <div className="bg-white border border-[#f3f4f5] w-[340px] md:w-[600px] lg:w-5/6 lg:mx-[7%] rounded-2xl  relative shadow-md px-8 py-6 mb-8 mx-auto">
              <p className="text-lg md:text-2xl font-sans text-[#212b36] pb-3">
                {" "}
                {the_second_product_title}
              </p>
              <p className="text-xs md:text-base font-regular text-[#171d25]">
                {second_product_notes}
              </p>
            </div>
            <div className="bg-white  border border-[#f3f4f5]  w-[340px] md:w-[600px] lg:w-5/6 lg:mx-[7%] rounded-2xl mx-auto relative shadow-md p-8 mb-8">
              <p className="text-lg md:text-2xl  font-sans text-[#212b36] pb-3">
                {" "}
                {the_third_product_title}
              </p>
              <p className="text-xs md:text-base font-regular text-[#171d25]">
                {third_product_notes}
              </p>
            </div>
            <div className="bg-white  border border-[#f3f4f5]  w-[340px] md:w-[600px] lg:w-5/6 lg:mx-[7%] rounded-2xl mx-auto relative shadow-md p-8 mb-8">
              <p className="text-lg md:text-2xl  font-sans text-[#212b36] pb-3">
                {" "}
                {the_fourth_product_title}
              </p>
              <p className="text-xs md:text-base font-regular text-[#171d25]">
                {fourth_product_notes}
              </p>
            </div>
            <div className="bg-white border border-[#f3f4f5]  w-[340px] md:w-[600px] lg:w-5/6 lg:mx-[7%] rounded-2xl mx-auto relative shadow-md px-8 py-6 mb-8">
              <p className="text-lg md:text-2xl  font-sans text-[#212b36] pb-3">
                {" "}
                {the_fifth_product_title}
              </p>
              <p className="text-xs md:text-base font-regular text-[#171d25]">
                {fifth_product_notes}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        <Footer landing="landing" />
      </div>
    </div>
  );
};

export default SigurimiKaskoIAutomjetit;
