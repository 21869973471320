import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Raiffeisenbg from "../../assets/images/raiffeisenbggg.svg";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  //const raiffeisenbgImageStyle = { 
  //backgroundImage: `url(${raiffeisenbg})`,
  //backgroundSize: "cover", // };

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      <div className="bg-[#fff] overflow-hidden">
        {isLoggedIn ? (
          <div className="bg-[#222627] fixed z-50 w-full">
            <Navbar
              type="authentication"
              authType="loggedIn"
              landing="landing"
              selectedValue={selectedValue}
              handleSelectChange={handleSelectChange}
            />
          </div>
        ) : (
          <div className="bg-white shadow-md fixed z-50 w-full">
            <Navbar
              selectedValue={selectedValue}
              handleSelectChange={handleSelectChange}
            />
          </div>
        )}
        <img src={Raiffeisenbg} className=" absolute w-full h-full object-cover" />
        <div className="mt-[5%] container  overflow-hidden mx-auto py-28 px-10 md:px-24 lg:px-0 select-none text-justify" >
          <h1 className="text-[33px] pt-6 md:pt-10 lg:pt-0 font-sans relative">
            {t('like')}
          </h1>
          <p className="py-2 relative font-regular text-base">
            {t('click_the_like_button')}
          </p>
          <p className="py-2 font-sans text-lg relative"> {t('contact')} </p>
          <p className="py-2 relative font-regular text-base">
            {t('resolve_a_complaint')}
          </p>
          <p className="py-2 relative font-regular text-base">Raiffeisen Insurance Broker</p>
          <p className="py-2 relative font-regular text-base">
            {t('adress')}
          </p>
          <p className="py-2 relative font-regular text-base">{t('mobile_number')}</p>
          <p className="py-2 relative font-regular text-base">Email: info.broker@raiffeisen-kosovo.com</p>
          <hr className="border-t border-black my-4 relative" />
          <p className="relative">
            <span className="inline-block text-xs px-2 py-1 pl-4 rounded-full font-bold text-gray-600 absolute top-[-0.2rem] left-[-1rem]">1</span>
            <p className="pl-2 font-regular text-base"> {t('individual_offers')} </p>
          </p>
        </div>
        <div className="relative lg:fixed md:w-full md:bottom-0  lg:mt-28">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;