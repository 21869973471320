import React from 'react';
import { useState } from 'react';
import DamageReport from '../../assets/images/damage_report.png';
import Navbar from '../../components/navbar/Navbar';
import { useTranslation } from 'react-i18next';
import Username from '../../assets/icons/PersonalNumber.png';
import Calendar from '../../assets/icons/calendar-icon.svg';
import Paper from '../../assets/icons/paper.svg';
import SigalRoundedIcon from '../../assets/icons/sigal-rounded-icon.svg';
import XButton from '../../assets/icons/x-icon.svg';
import Footer from '../../components/footer/Footer';

const ClaimReports = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [stories, setStories] = useState([{}]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isLoadingForCancelingPolicy, setIsLoadingForCancelingPolicy] =
    useState(false);

//   const fetchCancelPolicy = async () => {
//     setIsLoadingForCancelingPolicy(true);
//   };

  const addStory = () => {
    if (stories.length < 5) {
      setStories([...stories, {}]);
    }
  };

  const removeStory = (index) => {
    const updatedStories = [...stories];
    updatedStories.splice(index, 1);
    setStories(updatedStories);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const newFiles = Array.from(selectedFiles).map((file) => {
      const fileType = file.type;
      const fileSize = file.size;
      let sizeInBytes = fileSize;
      let sizeInKb = (fileSize / 1024).toFixed(2);
      let sizeInMb = (fileSize / (1024 * 1024)).toFixed(2);
      let size;
      if (fileSize < 1024) {
        size = `${sizeInBytes} bytes`;
      } else if (fileSize >= 1024 && fileSize < 1024 * 1024) {
        size = `${sizeInKb} KB`;
      } else if (fileSize >= 1024 * 1024) {
        size = `${sizeInMb} MB`;
      }
      return {
        file,
        name: file.name,
        size,
        type: fileType,
      };
    });
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleContinue = () => {
    setConfirmationModal(true);
  };

  const handleCloseModal = () => {
    setConfirmationModal(false);
  };

  const handleFileDelete = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  const [selectedValue, setSelectedValue] = useState('');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const YourCircularIndicatorComponent = () => {
    return (
      <div className='flex justify-center items-center'>
        <div
          style={{ borderColor: '#FFDD00' }}
          className='w-6 h-6 border-t-4 border-yellow-500 border-solid rounded-full animate-spin'></div>
      </div>
    );
  };

  return (
    <div className='bg-[#f5f6fa]'>
      <div className='bg-[#222627] z-50 w-full'>
        <Navbar
          type='authentication'
          authType='loggedIn'
          landing='landing'
          selectedValue={selectedValue}
          handleSelectChange={handleSelectChange}
        />
      </div>
      <img src={DamageReport} alt='Sigal Logo' className='absolute ' />
      <div className='container relative pt-14'>
        <div className='flex'>
          <div className='flex-grow'>
            <h1 className='text-[#171d25] text-3xl font-semibold'>
              {t('report_the_claim')}
            </h1>
            <p className='text-[#171d25] italic text-[22px] font-medium'>
              {t('vehicle_insurance')}
            </p>
          </div>
          <div className='flex space-x-4 pt-2'>
            <p className='text-[#b0bac9] font-regular'>{t('claim_nr')}</p>
            <p className='font-regular'>3856490 | 20/02/2023</p>
          </div>
        </div>
        <div className='border-t border-[#b0bac9] mt-8'>
          <div className='mt-8 text-2xl font-semibold pb-6'>
            <h2>{t('information_about_insurance')}</h2>
          </div>
          <div className='flex space-x-10 justify-left'>
            <div className='flex-none w-1/4 bg-white rounded-2xl shadow-md px-10 py-8 text-center'>
              <h2 className='text-[#171d25] text-[22px] font-semibold mb-4'>
                Mbrojtje nga dëmet materiale
              </h2>
              <p className='text-[#171d25] text-base font-medium'>
                Sigurimi kasko do të mbulojë dëmet materiale të automjetit tuaj
                në raste të caktuara, siç janë aksidentet, vandalismi,
                grabitjet, etj. Kjo do të ndihmojë të shmangni shpenzimet e
                mëdha që mund të lidhen me riparimin ose zëvendësimin e
                automjetit tuaj.
              </p>
            </div>
            <div className='flex-none w-1/4 bg-white rounded-2xl shadow-md px-10 py-8 text-center'>
              <h2 className='text-[#171d25] text-[22px]  mb-4 font-semibold'>
                Mbrojtje nga humbjet e mjeteve të tjera
              </h2>
              <p className='text-[#171d25] text-base font-medium'>
                Nëse jeni të përfshirë në një aksident dhe dëmtuat një automjet
                tjetër ose dëmtoi pronën e dikujt tjetër, sigurimi kasko do të
                mbulojë koston e riparimit ose zëvendësimit të këtyre mjeteve.
              </p>
            </div>
            <div className='flex-none w-1/4 bg-white rounded-2xl shadow-md px-10 py-8 text-center'>
              <h2 className='text-[#171d25] text-[22px] font-semibold mb-4'>
                Ndihmë financiare në raste emergjente
              </h2>
              <p className='text-[#171d25] text-base font-medium'>
                Nëse jeni të përfshirë në një aksident dhe keni nevojë për
                ndihmë emergjente, si të thirrni një remorker ose të
                transportoheni në spital, disa polica kasko mund të mbulojnë
                këto shpenzime.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className='bg-white rounded-2xl shadow-lg px-10 mt-20 py-10'>
            <h2 className='text-2xl font-semibold pb-10'>{t('policy_details')}</h2>
            <div className='grid grid-cols-5 gap-20'>
              <div className='flex items-center'>
                <img src={Paper} alt='' className='mr-3' />
                <p className='text-base font-semibold'>
                  123456789
                  <br />
                  <span className='text-[#838c9b] font-medium text-sm'>
                    {t('policy_number')}
                  </span>
                </p>
              </div>
              <div className='flex items-center'>
                <img src={SigalRoundedIcon} alt='' className='mr-3' />
                <p className='text-base font-semibold'>
                  Sigal
                  <br />
                  <span className='text-[#838c9b] font-medium text-sm'>
                    {t('insurance_company')}
                  </span>
                </p>
              </div>
              <div className='flex items-center'>
                <img src={Username} alt='' className='mr-3' />
                <p className='text-base font-semibold'>
                  Ambra Kelmendi
                  <br />
                  <span className='text-[#838c9b] font-medium text-sm'>
                    {t('insured')}
                  </span>
                </p>
              </div>
              <div className='flex items-center'>
                <img src={Calendar} alt='' className='mr-3' />
                <p className='text-base font-semibold'>
                  123456789
                  <br />
                  <span className='text-[#838c9b] font-medium text-sm'>
                    {t('insurance_period')}
                  </span>
                </p>
              </div>
            </div>
            <p className='text-[#838c9b] text-sm mt-8 pb-2.5'>
              {t('documents')}
            </p>
            <div className='flex'>
              <button className=''>
                <div className='flex border border-[#e5e9f2] py-2.5 px-4 rounded-md'>
                  <div className='text-xs pr-2'>Document.pdf</div>
                  <svg
                    className='download-icon'
                    width='12'
                    height='18'
                    viewBox='0 0 18 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      className='download-arrow'
                      d='M13 9L9 13M9 13L5 9M9 13V1'
                      stroke='#333333'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M1 17V18C1 18.7956 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H14C14.7956 21 15.5587 20.6839 16.1213 20.1213C16.6839 19.5587 17 18.7956 17 18V17'
                      stroke='#333333'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div className='bg-white rounded-2xl shadow-lg px-10 mt-8 py-10'>
            {stories.map((story, index) => (
              <div key={index} className='mb-4'>
                <div className='flex'>
                  <p className='flex-grow text-2xl font-semibold pb-10'>
                    {t('claim_data')}
                  </p>
                  {index > 0 && (
                    <div className='flex items-center'>
                      <button
                        className='text-[#4e938c] pt-2'
                        onClick={() => removeStory(index)}>
                        <img
                          src={XButton}
                          alt='x-button'
                          className='w-[21px] px-1 h-[21px] ml-4'
                        />
                      </button>
                    </div>
                  )}
                </div>

                <div className='flex'>
                  <div className='w-1/4 text-sm text-[#0f1e2f] font-semibold'>
                    {t('the_date_of_story')}
                  </div>
                  <div className='w-1/4 text-sm text-[#0f1e2f] font-semibold'>
                    {t('invoice_number')}
                  </div>
                  <div className='w-1/4 text-sm text-[#0f1e2f] font-semibold'>
                    {t('head_of_service')}
                  </div>
                  <div className='w-1/4 text-sm text-[#0f1e2f] font-semibold'>
                    {t('value')}
                  </div>
                </div>
                <div className='flex'>
                  <div className='w-1/4 text-sm text-[#0f1d31]'>
                    <input
                      className='text-[#0f1d31] bg-[#f5f6fa] w-[212px] h-[32px] text-sm mt-1 pl-3 rounded-md font-medium'
                      placeholder={t('the_date_of_story')}
                      id=''
                      name=''
                      type='text'
                    />
                  </div>
                  <div className='w-1/4 text-sm text-[#0f1d31]'>
                    <input
                      className='text-[#0f1d31] bg-[#f5f6fa] w-[212px] h-[32px] text-sm mt-1 pl-3 rounded-md font-medium'
                      placeholder={t('invoice_number')}
                      id=''
                      name=''
                      type='text'
                    />
                  </div>
                  <div className='w-1/4 text-sm text-[#0f1d31]'>
                    <input
                      className='text-[#0f1d31] bg-[#f5f6fa] w-[212px] h-[32px] text-sm mt-1 pl-3 rounded-md font-medium'
                      placeholder={t('the_date_of_story')}
                      id=''
                      name=''
                      type='text'
                    />
                  </div>
                  <div className='w-1/4 text-sm text-[#0f1d31] '>
                    <input
                      className='text-[#0f1d31] bg-[#f5f6fa] w-[212px] h-[32px] text-sm mt-1 pl-3 rounded-md font-medium'
                      placeholder={t('value')}
                      id=''
                      name=''
                      type='text'
                    />
                  </div>
                </div>
                <div className='mt-4'>
                  <label
                    htmlFor={`message-${index}`}
                    className='text-sm font-bold text-[#0f1d31]'>
                    {t('description_of_the_story')}
                  </label>
                  <div>
                    <textarea
                      id={`message-${index}`}
                      rows='2'
                      className='w-full px-4 py-[14px] mt-2 bg-[#f7f8fb] text-[#0f1e2f] text-[15px] leading-6 rounded-md font-medium'
                      placeholder={t('description')}></textarea>
                  </div>
                </div>
              </div>
            ))}
            <div>
              {stories.length < 5 && (
                <p
                  className='text-[#4e938c] pt-2 cursor-pointer font-semibold'
                  onClick={addStory}>
                  {t('+_add_story')}
                </p>
              )}
            </div>
          </div>
          <div className='bg-white rounded-2xl shadow-lg px-10 mt-8 py-10'>
            <div>
              <p className='text-[#171d25] font-semibold text-[22px] pb-2'>
                {t('documents')}
              </p>
              <label
                htmlFor='myFile'
                className='custom-file-upload block text-xs font-bold text-[#0f1d31]'>
                <div className='my-3 max-w-[154px] py-3 px-3 border-[1px] border-[#86b1ad] border-dashed rounded-[3px] cursor-pointer bg-[#f7f8f9] text-[#86b1ad]'>
                  {t('upload_documents')}
                </div>
                <input
                  type='file'
                  id='myFile'
                  name='myFile'
                  onChange={handleFileChange}
                  hidden
                  multiple
                />
              </label>
            </div>
            <div className='flex flex-wrap -mx-4 gap-4 pl-3'>
              {files.map((file, index) => (
                <div
                  key={file.name}
                  className='flex border border-[#e5e9f2] py-2.5 px-4 rounded-md'>
                  <div>
                    <p className='text-sm whitespace-wrap'>{file.name}</p>
                    <p className='text-[#b0bac9] text-xs'>{file.size}</p>
                  </div>
                  <div className='ml-auto pl-5'>
                    <button
                      className=''
                      onClick={() => handleFileDelete(index)}>
                      <img
                        src={XButton}
                        alt='x-button'
                        className=' w-[21px] px-1 h-[21px] ml-4'
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='bg-white rounded-2xl shadow-lg px-10 mt-8 py-10'>
            <p className='text-2xl font-semibold'>{t('additional_comment')}</p>
            <div className='mt-4'>
              <div>
                <textarea
                  id='message'
                  rows='2'
                  className='w-full px-4 py-[14px] mt-2 bg-[#f7f8fb] text-[#0f1e2f] text-[15px] leading-6 rounded-md font-medium'
                  placeholder={t('description')}></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className='flex'>
          <div className='flex-grow pt-11'>
          </div>
          <div className='flex pt-11'>
            <button className='text-[#0f1d31] hover:bg-[#535353] px-[22px] py-[11px] rounded-lg font-medium'>
              {t('cancel')}
            </button>
            <button
              className='bg-[#f7ee00] hover:bg-[#E0D815] text-[#0f1d31] px-[22px] py-[11px] rounded-lg font-medium'
              onClick={handleContinue}>
              {t('continue')}
            </button>
            {confirmationModal && (
              <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                <div className='bg-white rounded-md p-[60px]'>
                  <h1 className='mb-4 font-semibold text-2xl text-center text-[#313a3c]'>
                    {t('confirm')}
                  </h1>
                  <p className='mb-4 text-center text-sm font-medium'>
                    {t('want_report_claim')}?
                  </p>
                  <button
                    className='w-[380px] h-[52px] rounded-md text-[#fff]s mt-2 font-semibold uppercase bg-[#313a3c] hover:bg-[#374151]'
                    type='submit' onClick={handleCloseModal}>
                    {t('cancel')}
                  </button>
                  <div className=''>
                    <button
                      type='button'
                      className='w-[380px] h-[52px] rounded-md text-[#313a3c] mt-2 font-semibold uppercase bg-[#fff] hover:bg-[#f5f6fa]'
                      >
                      {t('continue')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='bottom-0 relative left-0 w-full pt-56'>
        <Footer landing='landing' />
      </div>
    </div>
  );
};

export default ClaimReports;
