import React, { useState, useEffect } from "react";
import Background from "../../assets/images/raiffeisen-bg.png";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import InsurexLogoX from "../../assets/images/insurex-logo-x-green.png";
import ForgotPasswordIcon from "../../assets/icons/forgot-password-icon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Cookies from 'js-cookie';

const OtpPasswordForm = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState('al');

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <div className="">
            <img src={Background} alt="background" className="bg-[#726f6f] fixed w-full h-full object-cover" />
            <div className="relative">
        <Navbar type="authentication" landing="landing" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
      </div>
                <div className="flex flex-col items-center  justify-center relative w-full h-full">
                      <img className=" pb-4 mx-auto" src={InsurexLogoX} alt="" />
                        <p className="text-[#9b9b9b] pb-8 text-sm up font-regular text-center">{t('insurance_platform')}</p>
                    <div className="bg-white mt-8 md:mt-8 lg:mt-2 rounded-md lg:mx-auto mb-10 px-[25px] md:px-[50px] ">
                        <div className="flex justify-center">
                        <img
                            className="mx-auto mt-6 md:mt-12 mb-2"
                            src={ForgotPasswordIcon}
                            alt=""
                        />
                        </div>                        
                        <div className="flex justify-center items-center pb-6">
                            <h1 className="font-sans lg:text-2xl text-center "> {t('verify_account')} </h1>
                        </div>
                        <div className="leading-5 pb-10">
                            <p className="text-center text-xs md:text-base font-medium"> {t('verification_password')} </p>
                            <p className="underline font-medium text-center text-xs md:text-base">ambra@gmail.com</p>
                        </div>
                        <div className="mx-auto md:mx-0">                        
                        <div>
                            <input className="text-[#0f1d31] bg-[#f7f8fb] w-72 md:w-[380px] h-[52px] font-regular  text-sm  pl-3 mb-6 "
                            placeholder= {t('type_password')} id="text" name="text" type="text"/>
                        </div>
                        <div className="flex flex-col items-center">
                            <button type="submit" className="w-72 md:w-[380px] h-[52px]  rounded-md text-[#fff] font-semibold uppercase bg-[#313a3c] hover:bg-[#374151]">
                                {t('submit')}
                            </button>
                            <div className="flex items-center font-semibold mt-[22px] mb-6">
                                <label htmlFor="link-checkbox" className="text-xs md:text-base text-[#171d25]">
                                {t('didnt_get_code')}
                                <span className="underline mx-1 text-xs md:text-base"> {t('resend')} </span>
                            </label>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 w-full">
                <Footer />
            </div>
        </div>
    )
}

export default OtpPasswordForm;