import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Raiffeisenbg from "../../assets/images/raiffeisenbg.svg";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const Cookie = () => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);


  return (
    <div className="bg-[#fff] overflow-hidden">   
     {isLoggedIn ? (
    <div className="bg-white fixed w-full shadow-md z-50  ">
        <Navbar type="authentication" authType="loggedIn" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
      </div>
      ):(
      <div>
      <Navbar selectedValue={selectedValue} handleSelectChange={handleSelectChange}/>
      </div>
     )}
      <img src={Raiffeisenbg} className=" absolute w-full h-[1000px] object-cover bg-[#f8fafc]" />
      <div className="mx-auto py-8 md:py-20  md:px-24 lg:px-0 select-none relative md:container">
        <h1 className="font-sans relative text-[#171d25] text-lg md:text-[33px] pl-10 lg:pl-0 md:pl-0">
          {t('what_is_cookie')}
        </h1>
        <p className="relative font-regular text-[#171d25] text-sm md:text-base py-2 text-justify pt-2 md:pt-6 px-10 lg:pl-0 lg:pb-10 md:pl-0 md:pr-4 ">
         {t('cookie_description')}
        </p>
        <p className="py-2 relative text-[#171d25] font-sans text-2xl font-bold pl-10 lg:pl-0 md:pl-0">
          {t('the_use_of_cookies')}
          <span className="pl-1">
            <a
              href="https://www.raiffeisenbroker-kosovo.com"
              target="_blank"
              className="text-[#3458fb]  hover:underline"
            >
              {" "}
              www.raiffeisenbroker-kosovo.com
            </a>
          </span>
        </p>
        <p className="py-2 relative text-justify font-regular text-[#171d25]  px-10 lg:pl-0 md:pl-0  md:pr-4">
          {t('cookies_used_by_ribk')}
        </p>
        <p className="relative py-2 lg:text-justify font-regular text-[#171d25] px-10 lg:pl-0 md:pl-0  md:pr-4">
         {t('information_about_third_parties')}
          <span className="pl-1">
            <a
              href="https://support.google.com/analytics/answer/6004245?hl=en_US "
              target="_blank"
              className="ttext-[#3458fb]  hover:underline break-words"
            >
           <br></br>https://support.google.com/analytics/answer/6004245?hl=en_US{" "}
            </a>
          </span>
        </p>
        <p className="py-2 relative font-regular text-[#171d25] lg:text-justify px-10 lg:pl-0 md:pl-0  md:pr-4">
          {t('google_analytics')}
          <span className="pl-1">
            <a
              href="https://tools.google.com/dlpage/gaoptout/"
              target="_blank"
              className="text-blue-500 hover:underline"
            >
          <br></br>https://tools.google.com/dlpage/gaoptout/
            </a>
          </span>
        </p>
        <p className="py-2 relative font-regular text-[#171d25] lg:text-justify px-10 lg:pl-0 md:pl-0  md:pr-4">
         {t('detailed_information_about_third_parties_facebook')}
          <span className="pl-1">
            <a
              href="https://www.facebook.com/policies/cookies/"
              target="_blank"
              className="text-[#3458fb]  hover:underline"
            >
            <br></br> https://www.facebook.com/policies/cookies/
            </a>
          </span>
        </p>
        <p className="py-2 relative lg:text-justify font-regular text-[#171d25] lg:pb-8 px-10 lg:pl-0 md:pl-0  md:pr-4">
          {t('google_analytics_cookies')}
          <span>
            <a
              href="https://www.facebook.com/policies/cookies/"
              target="_blank"
              className="text-[#3458fb]  hover:underline"
            >
            <br></br>https://www.facebook.com/policies/cookies/
            </a>
          </span>
        </p>
        <p className="py-2 font-bold relative text-[#171d25] text-[22px] pl-10 lg:pl-0 md:pl-0">
            {t('cookie_disable')}
        </p>
        <p className="py-2 relative text-justify font-regular text-[#171d25]  px-10 lg:pl-0 md:pl-0  md:pr-4">
         {t('cookie_disable_description')}
        </p>
        <ul className="py-2 relative font-regular text-[#171d25] list-disc pl-14 lg:pl-4 md:pl-4 ">
          <li className="text-[#3458fb] hover:underline">
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chrome
            </a>
          </li>
          <li className="text-[#3458fb]  hover:underline">
            <a
              href="https://www.mozilla.org/firefox/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firefox
            </a>
          </li>
          <li className="text-[#3458fb] hover:underline">
            <a
              href="https://www.microsoft.com/en-us/edge"
              target="_blank"
              rel="noopener noreferrer"
            >
              Internet Explorer
            </a>
          </li>
          <li className="text-[#3458fb] hover:underline">
            <a
              href="https://www.opera.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Opera
            </a>
          </li>
          <li className="text-[#3458fb] hover:underline">
            <a
              href="https://www.apple.com/safari/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Safari
            </a>
          </li>
        </ul>
      <div className="py-2 font-regular text-[#171d25] relative text-justify px-10 lg:pl-0 md:pl-0  md:pr-4 whitespace-normal">
        <p className="inline">
         {t('by_using_the_website')}
          <a
            href="https://www.raiffeisenbroker-kosovo.com"
            target="_blank"
            className="text-[#3458fb] hover:underline"
          >
              https://www.raiffeisenbroker-kosovo.com
          </a>
          <p className="inline">
          {t('it_is_considered')}
          </p>
            </p>
          </div>
    
      </div>
      <div className="relative mt-[25%] md:mt-[10%] lg:mt-[0%] bottom-0">
        <Footer landing="landing" />
      </div>
    </div>
  );
};
export default Cookie;
