import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import alTranslations from './locales/al/translation.json';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'al'];

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    al: {
      translation: alTranslations,
    },
  },
  lng: 'al',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);