import "./navbar.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import whiteLogo from "../../assets/icons/raiffeisen-white-logo.png";
import blackLogo from "../../assets/icons/raiffeisen-black-logo.png";
import ArrowDown from "../../assets/icons/arrow-ios-downward.svg";
import BlackArrowDown from "../../assets/icons/black-down-arrow.png";
import YellowProfile from "../../assets/images/yellow-user-profile.png";
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import enTranslations from '../../locales/en/translation.json';
import alTranslations from '../../locales/al/translation.json';
import Cookies from 'js-cookie';
import Globe from "../../assets/images/Globe.png";
import WhiteGlobe from "../../assets/icons/whiteglobe.svg"
import SelectedPolicyModal from "../selectedPolicyModal/SelectedPolicyModal";

const Navbar = ({ type, authType, landing }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(authType === "loggedIn" ? "al" : "en");
  const [languageClicked, setLanguageClicked] = useState(false);
  const [showSelectedPolicyModal, setShowSelectedPolicyModal] = useState(false);

  const handleReportClaimClick = () => {
    // Set the state to true to show the modal
    setShowSelectedPolicyModal(true);
  };

  const handleCancel = () => {
    // Set the state to false to hide the modal
    setShowSelectedPolicyModal(false);
  };

  
  const {t} = useTranslation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  const response_username = Cookies.get("response_username");
  const selectedTranslations = selectedValue === 'en' ? enTranslations : alTranslations;

  const params = new URLSearchParams(location.search);
  const username = params.get('username');

  const handleLanguageClick = () => {
    setLanguageClicked(!languageClicked);
    setClicked(false);
  };

  const handleClick = () => {
    setClicked(!clicked);
    setLanguageClicked(false);

    if (landing && landing.target && landing.target.value) {
      i18next.changeLanguage(landing.target.value);
    }
  };

  const handleLogOut = async () => {
    const allCookies = Cookies.get();

    for (let cookieName in allCookies) {
      Cookies.remove(cookieName);
    }
    navigate('/login');
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    i18n.changeLanguage(value);
    Cookies.set("selectedValue", value);
  };

  useEffect(() => {
    const savedValue = Cookies.get("selectedValue");
    if (savedValue) {
      setSelectedValue(savedValue);
      // i18n.changeLanguage(savedValue);
    }
  }, [i18n])

  return (
<div className="flex container items-center justify-between py-8 lg:py-5 ">
      {!landing || landing !== "landing" ? (
        <a href="/">
          <img src={blackLogo} className="w-[150px] md:w-[200px]" alt="logo" />
        </a>
      ) : (
        <a href="/">
          <img src={whiteLogo} className="w-[200px]" alt="logo" />
        </a>
      )}
  
      <nav className="flex">
      <div className={type === "authentication" && authType === "loggedIn" && landing === "landing" ? "hidden" : undefined}>


          {type !== "authentication" && landing !== "landing" && (
            <>
              <ul className="DESKTOP-MENU  space-x-1 lg:space lg:flex pt-2">
                <div className="hidden lg:flex  lg:pt-2 justify-center items-center mr-10 lg:mr-0">
                  <Link to="/register" className="py-2 text-sm font-semibold px-3 mx-1 md:py-2 md:px-5 md:mx-1 border-brokerx_yellow rounded-md bg-[#f7ee00]">
                      <li> {t('register')} </li>
                  </Link>
                  <Link to="/login" className="py-2 px-3 font-semibold text-sm  mx-1 md:py-2 md:px-10 md:mx-1 rounded-md text-black">
                    <li>{t('sign_in')}</li>
                  </Link>
                </div>
              
                <div className="mr-2 md:mr-5 relative">
                  <div className="dropdown-language lg:pt-4" onClick={handleLanguageClick}>
                    <div className=" mr-4 cursor-pointer">
                      <img
                        src={selectedValue === "al" ?  Globe : Globe} 
                        className="w-5 h-5"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    className="dropdown-content-language right-0 shadow-2xl"
                    onClick={handleLanguageClick}
                    style={{ display: languageClicked ? "block" : "none" }}
                  >
                    <ul
                      className="dropdown-button-language font-medium text-sm rounded-2xl pl-2 hover:bg-gray-100 py-2"
                      onClick={() => handleSelectChange("al")}
                    >
                      <img  alt="" className=" pl-4 inline-block " />
                      {t('albanian')}
                    </ul>
                    <ul
                      className="dropdown-button-language font-medium text-sm rounded-2xl pl-2 hover:bg-gray-100 py-2"
                      onClick={() => handleSelectChange("en")}
                    >
                      <img  alt="" className="pl-4 inline-block" />
                      {t('english')}
                    </ul>
                  </div>
                </div>
              </ul>
            </>
          )}
        </div>
        {type !== "authentication" && (
          <>
            <section className="MOBILE-MENU flex lg:hidden pt-2">
              <div className="HAMBURGER-ICON space-y-2 cursor-pointer"
                onClick={() => setIsNavOpen((prev) => !prev)}
              >
                <span className="block h-0.5 w-8 animate-pulse bg-black"></span>
                <span className="block h-0.5 w-8 animate-pulse bg-black"></span>
                <span className="block h-0.5 w-8 animate-pulse bg-black"></span>
              </div>
              <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
                <div
                  className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
                  onClick={() => setIsNavOpen(false)}
                >
                  <svg
                    className="h-8 w-8 text-white cursor-pointer"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </div>
                <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[0px]">
                  <li className="text-white my-8 font-semibold text-sm uppercase py-2 px-3 mx-1 md:py-2 md:px-10 md:mx-1 bg-transparent hover:bg-gray-800">
                    <Link to="/home">Home</Link>
                  </li>
                  <li className="border-b border-yellow-300 font-semibold text-sm text-white my-8 uppercase py-2 px-3 mx-1 md:py-2 md:px-10 md:mx-1 bg-transparent hover:bg-[#f7ee00] hover:text-black">
                    <Link to="/login">{t('sign_in')}</Link>
                  </li>
                  <li className="border-b text-white border-yellow-300 font-semibold text-sm my-8 uppercase py-2 px-3 mx-1 md:py-2 md:px-10 md:mx-1 bg-transparent hover:bg-[#f7ee00] hover:text-black">
                    <Link to="/register"> {t('register')} </Link>
                  </li>
                  <li className="border-b border-yellow-300 text-white font-semibold text-sm my-8 uppercase py-2 px-3 mx-1 md:py-2 md:px-10 md:mx-1 bg-transparent hover:bg-gray-800">
                    <Link to="/change-password"> {t('change_password')} </Link>
                  </li>
                  <li className="text-white my-8 uppercase py-2 px-3 mx-1 font-semibold text-sm md:py-2 md:px-10 md:mx-1 bg-transparent hover:bg-gray-800">
                    <Link to="/login/forgot-password"> {t('forgot_password')} </Link>
                  </li>
                </ul>
              </div>
            </section>
          </>
        )}
       <div className={type === "authentication" && authType === "loggedIn" && landing !== "landing" ? undefined : ""}>

          {type !== "authentication" && landing === "landing" &&  (
            <>
              <ul className="DESKTOP-MENU hidden space-x-8 lg:flex pt-2">
                <li>
                  <Link to="/register" className="py-2 px-3 font-semibold text-sm mx-1 md:py-2 md:px-5 md:mx-1 border-brokerx_yellow rounded-md bg-[#f7ee00]">
                  {t('register')} 
                  </Link>
                </li>
                <li>
                  <Link to="/login" className="py-2 px-3 mx-1 font-semibold text-sm md:py-2 md:px-10 md:mx-1 rounded-md bg-[#222627] text-white">
                  {t('sign_in')}
                  </Link>
                </li>
              </ul>
            </>
          )}
          {authType === "loggedIn" && landing !== "landing" && (
            <>
              <div className="flex flex-row ">
       {/* <div>
      <button
        className="bg-[#f7ee00] hover:bg-[#E0D815] text-[8px] md:text-base py-2 px-2 mt-2 md:px-4 justify-center items-center rounded-lg mr-2 md:mr-6 font-bold"
        onClick={handleReportClaimClick} 
      >
        {t('report_the_claim')}
      </button>
      {showSelectedPolicyModal && (
        <SelectedPolicyModal
          ShowSelectedPolicyModal={showSelectedPolicyModal}
          handleCancel={handleCancel}
        />
      )}
    </div> */}
                <div className="mr-2 md:mr-5 items-center justify-center flex md:mt-2">
                  <div className="dropdown-language" onClick={handleLanguageClick}>
                    <div className="cursor-pointer justify-center items-center">
                      <img
                        src={selectedValue === "al" ?  Globe : Globe}
                        className="w-5 h-5"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <div
                      className="dropdown-content-language  border-2 shadow-md z-50 top-3 lg:top-4 right-1 md:right-0 "
                      onClick={handleLanguageClick}
                      style={{ display: languageClicked ? "block" : "none" }}
                    >
                      <ul
                        className="dropdown-button-language  pl-2 rounded-2xl hover:bg-gray-100 py-2"
                        onClick={() => handleSelectChange("al")}
                      >
                        <p className="inline-block font-medium text-sm pl-4">
                        {t('albanian')}
                        </p>
                      </ul>
                      <ul
                        className="dropdown-button-language font-medium text-sm pl-2 rounded-2xl hover:bg-gray-100 py-2"
                        onClick={() => handleSelectChange("en")}
                      >
                        <p className="inline-block pl-4">
                        {t('english')}
                        </p>
                      </ul>

                    </div>
                  </div>
                </div>
                <div className="relative">
                <div className="dropdown" onClick={handleClick}>
                  <div className="flex flex-row">
                    <img
                      src={YellowProfile}
                      alt="userimg"
                      className="ml-auto md:mt-2 lg:mt-2 md:ml-0 mx-2 w-8 h-8"
                    />
                    <span className="text-black text-[14px] font-semibold text-sm lg:pt-3 cursor-pointer hidden lg:block">
                      {response_username}
                    </span>
                    <img
                      src={BlackArrowDown}
                      alt="BlackArrowDown"
                      className="mx-2 mt-3 md:mt-4 cursor-pointer w-4 h-4"
                    />
                    <div
                      className="dropdown-content shadow-md py-2 z-50 right-3 lg:right-0 top-3 md:top-6"
                      onClick={handleClick}
                      style={{ display: clicked ? "block" : "none" }}
                    >
                      <Link to="/profile">
                        <ul className="dropdown-button py-2 hover:bg-[#f2f2f2] mx-2 font-medium text-sm  hover:rounded-2xl"> {t('profile')} </ul>
                      </Link>
                      <button onClick={handleLogOut}>
                        <ul className="dropdown-button py-2 hover:bg-[#f2f2f2] px-10 hover:rounded-2xl font-medium text-sm"> {t('log_out')} </ul>
                      </button>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </>
          )}
            {authType === "loggedIn" && landing === "landing" && (
              <>
                <div className="flex flex-row">
                  <div className="mr-2">
                    <div className="dropdown-language mt-1" onClick={handleLanguageClick}>
                      <div className="">
                        <img
                          src={selectedValue === "al" ? WhiteGlobe:WhiteGlobe}
                          className="w-5 h-5"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="relative">
                      <div
                        className="dropdown-content-language shadow-md z-50 right-1 md:right-2 lg:right-0 md:top-0  lg:top-1 "
                        onClick={handleLanguageClick}
                        style={{ display: languageClicked ? "block" : "none" }}
                      >
                        <ul
                          className="dropdown-button-language font-medium text-sm rounded-2xl pl-2 hover:bg-gray-100 py-2"
                          onClick={() => handleSelectChange("al")}
                        >
                          <img  alt="" className=" inline-block pl-4" />
                          {t('albanian')}
                        </ul>
                        <ul
                          className="dropdown-button-language font-medium text-sm rounded-2xl pl-2 hover:bg-gray-100 py-2"
                          onClick={() => handleSelectChange("en")}
                        >
                          <img  alt="" className=" inline-block pl-4" />
                          {t('english')}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="dropdown" onClick={handleClick}>
                      <div className="flex flex-row">
                        <img
                          src={YellowProfile}
                          alt="userimg"
                          className="ml-auto md:ml-0 mx-2 mt-[-5px] w-8 h-8"
                        />
                        <span className="text-white text-[14px] hidden lg:block cursor-pointer">
                          {response_username}
                        </span>
                        <img
                          src={ArrowDown}
                          alt="ArrowDown"
                          className="mx-2  cursor-pointer"
                        />
                         <div
                      className="dropdown-content shadow-md py-2 z-50 right-3 lg:right-0 top-3 md:top-6 lg:top-3"
                      onClick={handleClick}
                      style={{ display: clicked ? "block" : "none" }}
                    >
                      <Link to="/profile">
                        <ul className="dropdown-button py-2 hover:bg-[#f2f2f2] mx-2 font-medium text-sm  hover:rounded-2xl"> {t('profile')} </ul>
                      </Link>
               
                      <button onClick={handleLogOut}>
                        <ul className="dropdown-button py-2 hover:bg-[#f2f2f2] font-medium text-sm  px-10 hover:rounded-2xl"> {t('log_out')} </ul>
                      </button>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
