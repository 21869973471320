import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import api from '../../services/api';
import Navbar from '../../components/navbar/Navbar';
import UserProfile from '../../assets/images/yellow-user-profile.png';
import InfoIcon from '../../assets/icons/information-gray-icon.png';
import Footer from '../../components/footer/Footer';
import PersonalNumber from '../../assets/icons/PersonalNumber.png';
import Email from '../../assets/icons/Email.png';
import Phone from '../../assets/icons/Phone.png';
import Address from '../../assets/icons/address-icon.svg';
import Calendar from '../../assets/icons/calendar-icon.svg';
import BusinessNumber from '../../assets/icons/business-icon.svg';

const Profile = () => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [username] = useState(Cookies.get('response_username'));
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState(1);
  const [phone_number, setPhoneNumber] = useState('');
  const [personal_number, setPersonalNumber] = useState('');
  const [birth_date, setBirthDate] = useState('');
  const [address, setAddress] = useState('');
  const [bank_account_number, setBankAccountNumber] = useState('');

  const legal_number_from_api = Cookies.get('legal_number');

  const fetchApiProfileData = useCallback(async () => {
    try {
      const response = await api.get(
        `/customers/${legal_number_from_api}/customer-api-detail-from-database`
      );
      setFirstName(response.data.first_name);
      setLastName(response.data.last_name);
      setEmail(response.data.email);
      const newGender = parseInt(response.data.gender, 10);
      setGender(newGender);
      setPhoneNumber(response.data.phone);
      setPersonalNumber(response.data.legal_number);
      setBirthDate(response.data.birth_date);
      setAddress(response.data.address);
      setBankAccountNumber(response.data.bank_account_number);
    } catch (error) {
      console.error(error);
    }
  }, [legal_number_from_api]);

  useEffect(() => {
    fetchApiProfileData();
  }, [fetchApiProfileData]);

  const [selectedValue, setSelectedValue] = useState('al');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className='bg-[#f5f6fa] overflow-hidden'>
      <div className='bg-white fixed w-full shadow-md z-50'>
        <Navbar
          type='authentication'
          authType='loggedIn'
          selectedValue={selectedValue}
          handleSelectChange={handleSelectChange}
        />
      </div>
      <div className='relative z-20 pb-[16%] md:pb-[10%] px-5 lg:px-2 mt-[5%] container  mx-auto '>
        <p className='font-semibold pb-4 text-[32px] pt-28 md:pt-32 lg:pt-28'>
          {' '}
          {t('profile')}{' '}
        </p>
        <div className='bg-[#171d25] p-6 mt-[1%] rounded-2xl'>
          <div className='md:flex md:flex-cols justify-between'>
            <div className='flex flex-cols pt-1 items-center '>
              <img
                src={UserProfile}
                className='w-20 h-20 mb-4 md:mb-0 '
                alt=''
              />
              <div className=''>
                <p className='font-semibold ml-4 text-white md:text-[24px] items-center'>
                  {first_name} {last_name}
                  <br />
                </p>
                <Link to="/change-password">
                <p className='ml-4 font-regular text-sm text-[#f7ee00] hover:underline'>{t('change_password')}</p>                      
                </Link>
              </div>
            </div>
            <div className='bg-[#33383f] font-regular text-xs md:text-[16px] rounded-[16px] p-4 md:p-6 flex flex-row'>
              <img src={InfoIcon} className='w-4 h-4 mx-2 mt-1' alt='' />
              <p className='text-[#c9ccd1] '>
                {t('please_contact')} <br></br> {t('contact_us')}
                <br></br>
                <span className='italic'>info@rbko.com</span>{' '}
                {t('wish_to_modify_them')}
              </p>
            </div>
          </div>
        </div>
        <div className='bg-white shadow md p-6 mt-[10%] md:mt-[5%] lg:mt-[2%] rounded-[16px] pb-[4%]'>
          <p className='font-semibold text-[24px] pl-5'>
            {t('personal_information')}
          </p>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-[15px] gap-9 mt-10'>
            <div className='flex flex-rows'>
              <img src={PersonalNumber} className='w-11 h-11' alt='' />
              <div className='mt-[-1%] ml-2'>
                <p className='text-[#313a3c] font-semibold'>
                  {username}
                  <br />
                  <span className='text-[#979797] font-regular text-[14px]'>
                    {t('username')}
                  </span>
                </p>
              </div>
            </div>
            <div className='flex flex-rows'>
              <img src={PersonalNumber} className='w-11 h-11' alt='' />
              <div className='mt-[-1%] ml-2'>
                <p className='text-[#313a3c] font-semibold'>
                  {personal_number}
                  <br />
                  <span className='text-[#979797] whitespace-nowrap font-regular text-[14px]'>
                    {t('personal_number')}
                  </span>
                </p>
              </div>
            </div>
            <div className='flex flex-rows'>
              <img src={Calendar} className='w-11 h-11' alt='' />
              <div className='mt-[-1%] ml-2'>
                <p className='text-[#313a3c] font-semibold'>
                  {birth_date}
                  <br />
                  <span className='text-[#979797] font-regular text-[14px]'>
                    {t('birth_date')}
                  </span>
                </p>
              </div>
            </div>
            <div className='flex flex-rows'>
              <img src={PersonalNumber} className='w-11 h-11' alt='' />
              <div className='ml-2'>
                <p className='text-[#313a3c] font-semibold'>
                  {gender === 1 ? (
                    <p className="text-[#313a3c] font-semibold"> {t('female')} </p>
                  ) : (
                    <p className="text-[#313a3c] font-semibold"> {t('male')}</p>
                  )}
                  <span className='text-[#979797] font-regular text-[14px]'>
                    {t('gender')}
                  </span>
                </p>
              </div>
            </div>
            {/* <div className="flex flex-rows">
              <img src={PEP} className="w-11 h-11" alt="" />
              <div className="ml-2">
                <p className="text-[#313a3c] font-semibold">
                  <p className="text-[#313a3c] font-bold"></p>
                  {pep === "1" ? (
                    <p className="text-[#313a3c] font-bold">Yes</p>
                  ) : (
                    <p className="text-[#313a3c] font-bold">No</p>
                  )}
                  <span className="text-[#979797] font-normal text-[14px]">
                    PEP
                  </span>
                </p>

              </div>
            </div> */}
            <div className='flex flex-rows'>
              <img src={Email} className='w-11 h-11' alt='' />
              <div className='mt-[-1%] ml-2'>
                <p className='text-[#313a3c] font-semibold'>
                  {email}
                  <br />
                  <span className='text-[#979797] font-regular text-[14px]'>
                    Email
                  </span>
                </p>
              </div>
            </div>
            <div className='flex flex-rows'>
              <img src={Phone} className='w-11 h-11' alt='' />
              <div className='mt-[-1%] ml-2'>
                <p className='text-[#313a3c] font-semibold'>
                  {phone_number}
                  <br />
                  <span className='text-[#979797] font-regular text-[14px]'>
                    {t('phone_number')}
                  </span>
                </p>
              </div>
            </div>
            <div className='flex flex-rows'>
              <img src={Address} className='w-11 h-11' alt='' />
              <div className='mt-[-1%] ml-2'>
                <p className='text-[#313a3c] font-semibold'>
                  {address}
                  <br />
                  <span className='text-[#979797] font-regular text-[14px]'>
                    {t('adres')}
                  </span>
                </p>
              </div>
            </div>
            <div className='flex flex-rows'>
              <img src={BusinessNumber} className='w-11 h-11' alt='' />
              <div className='mt-[-1%] ml-2'>
                <p className='text-[#313a3c] font-semibold'>
                  {bank_account_number}
                  <br />
                  <span className='text-[#979797] font-regular text-[14px]'>
                    {t('bank_acc_number')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='relative mt-[25%] md:mt-[10%] lg:mt-[0%] bottom-0 '>
        <Footer landing='landing' />
      </div>
    </div>
  );
};

export default Profile;
