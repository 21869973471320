import './landingPage.css';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import PrisigLogo from '../../assets/icons/Prisig.svg';
import SiguriaLogo from '../../assets/icons/Siguria.svg';
import ElsigLogo from '../../assets/icons/Elsig.svg';
import IllyriaLogo from '../../assets/icons/Illyria.svg';
import ScardianLogo from '../../assets/icons/Scardian.svg';
import SigalLogo from '../../assets/icons/Sigal.svg';
import SigallifeLogo from '../../assets/icons/Sigallife.png';
import SigkosLogo from '../../assets/icons/Sigkos.svg';
import DukagjiniLogo from '../../assets/icons/Dukagjini.svg';
import EurosigLogo from '../../assets/icons/Eurosig.svg';
import SigmaLogo from '../../assets/icons/Sigma.svg';
import BrokerX from '../../assets/images/Brokerximg.png';
import Woman from '../../assets/images/Womanimg.png';
import House from '../../assets/images/Houseimg.png';
import Car from '../../assets/images/Carimg.png';
import Sethoscope from '../../assets/images/Sethoscopeimg.png';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const LandingPage = (landing) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/home');
    }
  }, [navigate]);

  return (
    <>
      <div className='overflow-hidden'>
        <div className='bg-white shadow-md fixed z-50 w-full '>
          <Navbar
            selectedValue={selectedValue}
            handleSelectChange={handleSelectChange}
          />
        </div>
        <div className='flex'>
          <div className='bg-[#171D25] w-full flex items-center justify-center'>
            <div className='items-center justify-center center xl:w-5/6 pt-40  lg:pt-14'>
              <h1 className='font-sans font-bold text-xl md:text-4xl lg:pb-8 lg:text-[64px] text-white leading-6'>
                {t('ensure')} <br></br>{' '}
                <div className='lg:pt-8'>{t('your_future')}</div>
              </h1>
              <p className='text-white py-6 md:pb-10  leading-6 w-4/6 font-regular text-sm  md:text-base'>
                {t('description_future')}
              </p>
              <Link to='/login'>
                <button className='text-[#0f1d31] rounded-md bg-[#f7ee00] text-xs md:text-base  py-2 px-6 font-sans mb-8  '>
                  {t('button_under_description')}!
                </button>
              </Link>
            </div>
          </div>
          <div className='w-full h-full  hidden lg:block'>
            <img src={BrokerX} alt='BrokerX' className='w-full h-full' />
          </div>
        </div>
        <div className='lg:pt-10 md:mb-20 relative bg-[#f5f6fa]'>
          <div className='container mx-auto relative z-0'>
            <div className='flex items-center mx-[10%] mb-[5%] w-[80%] text-[#0f1d31]'>
              <div className='flex-1 text-center'>
                <p className='text-xl lg:text-5xl mb-4 font-extrabold pt-8 md:pt-8 md:mr-0'>
                  {t('insurance_type')}
                </p>
                <p className='text-sm lg:text-lg lg:w-4/6 font-regular mx-auto md:text-center'>
                  {t('insurance_description')}
                </p>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 pt-10 md:pt-0 lg:grid-cols-4 mx-[10%] gap-[3%]'>
              <div className='bg-white rounded-2xl shadow-xl'>
                <div className='white-background-color rounded-t-[16px]'>
                  <Link to='/login'>
                    <img
                      src={Woman}
                      alt='woman'
                      className='items-center rounded-t-2xl'
                    />
                  </Link>
                </div>
                <div className='pt-16 pb-4 md:pb-0 md:pt-10 px-10'>
                  <p className='text-center mt-[-5%] text-[17px] pb-8 font-sans text-[#0f1d31]'>
                    {t('life_insurance')}
                  </p>
                </div>
              </div>
              <div className='bg-white rounded-2xl pb-4 shadow-xl'>
                <div className='white-background-color rounded-t-[16px]'>
                  <Link to='/login'>
                    <img
                      src={House}
                      alt='Fireicon'
                      className='items-center rounded-t-2xl'
                    />
                  </Link>
                </div>
                <div className='pt-8 pb-2 md:pb-0 px-10'>
                  <p className='text-center text-[17px]  font-sans text-[#0f1d31]'>
                    {t('property_insurance')}
                  </p>
                </div>
              </div>
              <div className='bg-white rounded-2xl shadow-xl r-0 h-[100%]'>
                <div className='white-background-color rounded-t-[16px]'>
                  <Link to='/login'>
                    <img
                      src={Car}
                      alt='Carinsurance'
                      className='items-center rounded-t-2xl'
                    />
                  </Link>
                </div>
                <div className='pt-10 pb-4 md:pb-4 lg:pb-0 px-10'>
                  <p className='text-center mt-[-4%]  text-[17px] font-sans text-[#0f1d31]'>
                    {t('vehicle_insurance')}
                  </p>
                </div>
              </div>
              <div className='bg-white rounded-2xl shadow-xl r-0 h-[100%]'>
                <div className='white-background-color  rounded-t-[16px]'>
                  <Link to='/login'>
                    <img
                      src={Sethoscope}
                      alt='Healthinsurance'
                      className='items-center rounded-t-2xl'
                    />
                  </Link>
                </div>
                <div className='pt-7 pb-4 md:pb-0 px-10'>
                  <p className='text-center mt-[-6%] pt-4 text-[17px] pb-2 font-sans text-[#0f1d31]'>
                    {t('insurance_health')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='relative'>
            <div className=' mx-auto mt-32 md:mt-20 lg:mt-32 w-[230px] h-[230px] rounded-[100%]  portfolio-bg  text-center text-[#313a3c] px-1 relative'>
              <p className='font-sans text-[43px] pt-[26%] z-50'>
                {t('partners')}
              </p>
            </div>
            <div className='bg-[#2b2e33]  pt-5 mt-[-25%] md:mt-[-5%] lg:mt-[-6%]'>
              <div className='grid md:grid-cols-6 mt-[20%] md:mt-[10%] gap-[20%] md:gap-[5%]  md:mx-[4%] lg:mx-[7%]'>
                {/* <img
                  alt='SiguriaLogo'
                  src={SiguriaLogo}
                  className='cursor-pointer logo hover:scale-110 transform transition-transform duration-300'
                /> */}
                <img
                  alt='SigallifeLogo'
                  src={SigallifeLogo}
                  className='cursor-pointer md:mt-[-8%] logo hover:scale-110 transform transition-transform duration-300'
                />
                <img
                  alt='SigalLogo'
                  src={SigalLogo}
                  className='cursor-pointer pt-16 md:pt-0 logo hover:scale-110 transform transition-transform duration-300'
                />
                <img
                  alt='EurosigLogo'
                  src={EurosigLogo}
                  className='cursor-pointer logo hover:scale-110 transform transition-transform duration-300'
                />
                <img
                  alt='SigmaLogo'
                  src={SigmaLogo}
                  className='md:mt-[-12%] cursor-pointer logo hover:scale-110 transform transition-transform duration-300'
                />

                <img
                  alt='IllyriaLogo'
                  src={IllyriaLogo}
                  className='cursor-pointer logo hover:scale-110 transform transition-transform duration-300'
                />
                <img
                  alt='ScardianLogo'
                  src={ScardianLogo}
                  className='cursor-pointer logo hover:scale-110 transform transition-transform duration-300'
                />
              </div>
              <div className='grid md:grid-cols-5 mt-[40%] md:mt-[5%] gap-[20%] md:gap-[1%] ml-[32%] md:mx-[7%] lg:mx-[20%]'>
                {/* <img alt="SigkosLogo" src={SigkosLogo} className="cursor-pointer" /> */}
                {/* <img alt="DukagjiniLogo" src={DukagjiniLogo} className="cursor-pointer" /> */}
                {/* <img alt="ElsigLogo" src={ElsigLogo} className="cursor-pointer" />   */}
                {/* <img alt="PrisigLogo" src={PrisigLogo} className="cursor-pointer pt-6 md:pt-0" /> */}
              </div>
            </div>
          </div>
        </div>
        <div className='relative mt-[25%] md:mt-[10%] lg:mt-[0%] bottom-0 '>
          <Footer landing='landing' />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
