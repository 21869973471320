
import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Background from "../../assets/images/Background.svg";
import Bg from "../../assets/images/home-page-background.svg";
import Woman from "../../assets/images/Womanimg.png";
import House from "../../assets/images/Houseimg.png";
import Car from "../../assets/images/Carimg.png";
import Sethoscope from "../../assets/images/Sethoscopeimg.png";
import CookieIcon from "../../assets/icons/cookie-icon.svg";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';

const backgroundImageStyle = {
  backgroundImage: `url(${Background})`,
  backgroundSize: "cover",
};

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchDataAndSetup = async () => {
      try {
        await fetchApiProfileData();
        await handleProduktet();
        await handleProduktetEBlera();
        await handlePagesatERealizuara();
        await handleKestetERadhes();
        // Add any additional API calls here
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndSetup();
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const username = params.get("username");
  const [showContent, setShowContent] = useState(false);
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [showThirdContent, setShowThirdContent] = useState(false);
  const [produktetEBleraDataList, setProduktetEBleraDataList] = useState([]);
  const [pagesatErealizuaraDataList, setPagesatErealizuaraDataList] = useState([]);
  const [customerInstallmenntsDataList, setCustomerInstallmenntsDataList] = useState([]);
  const [productsDataList, setProductsDataList] = useState([]);
  const response_username = Cookies.get("response_username");
  const [displayedRows1, setDisplayedRows1] = useState(5); // For the first table
  const [displayedRows2, setDisplayedRows2] = useState(5); // For the second table
  const [displayedRows3, setDisplayedRows3] = useState(5); // For the third table
  const [cookieConsentVisible, setCookieConsentVisible] = useState(true);
  const [showBanner, setShowBanner] = useState(true);
  const [isBannerOpen, setIsBannerOpen] = useState(true);

  const slicedProducts1 = produktetEBleraDataList.slice(0, displayedRows1);
  const slicedProducts2 = pagesatErealizuaraDataList.slice(0, displayedRows2);
  const slicedProducts3 = customerInstallmenntsDataList.slice(0, displayedRows3);


  const handleCloseBanner = () => {
    setShowBanner(false);
  };

  const handleAcceptCookies = () => {
    Cookies.set('username', username, { expires: 7 });
    // Cookies.set('password', password, { expires: 7 });
    setIsBannerOpen(false);
    Cookies.set('cookiesAccepted', 'true');

  };

  const loadMoreRows1 = () => {
    setDisplayedRows1(displayedRows1 + 5);
  };

  const loadMoreRows2 = () => {
    setDisplayedRows2(displayedRows2 + 5);
  };

  const loadMoreRows3 = () => {
    setDisplayedRows3(displayedRows3 + 5);
  };

  const legal_number_from_api = Cookies.get("legal_number");
  const fetchApiProfileData = async () => {
    try {
      const response = await api.get(`customers/${legal_number_from_api}/customer-api-detail-from-database`);
      setFirstName(response.data.first_name);
      setLastName(response.data.last_name);
      Cookies.set("id", response.data.id, { expires: 1 });
      Cookies.set("bank_account_number_locally", response.data.bank_account_number, { expires: 1 });

    } catch (error) {
      console.error(error);
    }
  };


  const handleProduktetEBlera = async () => {
    const localStorageCustomerId = Cookies.get("id");

    try {
      const response = await api.get(
        `/customers/${localStorageCustomerId}/api-policies/`
      );
      setProduktetEBleraDataList(response.data);

    } catch (error) {
      console.error(error);
    }
  };

  const handlePagesatERealizuara = async () => {
    try {
      const customerId = Cookies.get('id');
      const customerTransactionResponse = await api.get(
        `/payments/${customerId}/customer-transaction/`
      );

      setPagesatErealizuaraDataList(customerTransactionResponse.data);

    } catch (error) {
      console.error(error);
    }
  }

  const handleKestetERadhes = async () => {
    try {

      const customerId = Cookies.get('id');
      const customerInstallmentsResopnse = await api.get(
        `/requests/api/${customerId}/customer-installments/`
      );
      setCustomerInstallmenntsDataList(customerInstallmentsResopnse.data);

    } catch (error) {
      console.error(error);
    }
  }

  const handleProduktet = async () => {
    try {
      const bankAccount = Cookies.get('bank_account_number_locally');
      if (bankAccount.startsWith('15')) {

        const productsForInternalCustomerResponse = await api.get(
          `/products/internal-customer-api-list/`
        );
        setProductsDataList(productsForInternalCustomerResponse.data);
      }
      else {
        const productsResponse = await api.get(
          `/products/api-list/`
        );
        setProductsDataList(productsResponse.data);
      }


    } catch (error) {
      console.error(error);
    }
  }

  const filteredAutomjetProducts = productsDataList.filter(
    (product) => product.product_category === 2
  );

  const filteredJeteProducts = productsDataList.filter(
    (product) => product.product_category === 3
  );

  const filteredProneProducts = productsDataList.filter(
    (product) => product.product_category === 4
  );

  const filteredShendetDheAksidenteProducts = productsDataList.filter(
    (product) => product.product_category === 5
  );

  const handleButtonClick = (product) => {

    const { id, name, ribk, created_at, updated_at, health_statement, loan_info, mortgage, vehicle_details, product_type, online_product, product_notes, second_product_notes, third_product_notes, fourth_product_notes, the_first_product_title, the_second_product_title, the_third_product_title, the_fourth_product_title, the_fifth_product_title, product_category, fifth_product_notes, order_no, numerator_fk } = product;

    navigate(`/sigurimi-kasko-i-automjetit?id=${id}&name=${encodeURIComponent(name)}&ribk=${encodeURIComponent(ribk)}&created_at=${encodeURIComponent(created_at)}&updated_at=${encodeURIComponent(updated_at)}&health_statement=${encodeURIComponent(health_statement)}&loan_info=${encodeURIComponent(loan_info)}&mortgage=${encodeURIComponent(mortgage)}&vehicle_details=${encodeURIComponent(vehicle_details)}&product_type=${encodeURIComponent(product_type)}&online_product=${encodeURIComponent(online_product)}&product_notes=${encodeURIComponent(product_notes)}&second_product_notes=${encodeURIComponent(second_product_notes)}&third_product_notes=${encodeURIComponent(third_product_notes)}&fourth_product_notes=${encodeURIComponent(fourth_product_notes)}&product_category=${encodeURIComponent(product_category)}&fifth_product_notes=${encodeURIComponent(fifth_product_notes)}&the_first_product_title=${encodeURIComponent(the_first_product_title)}&the_second_product_title=${encodeURIComponent(the_second_product_title)}&the_third_product_title=${encodeURIComponent(the_third_product_title)}&the_fourth_product_title=${encodeURIComponent(the_fourth_product_title)}&the_fifth_product_title=${encodeURIComponent(the_fifth_product_title)}&order_no=${encodeURIComponent(order_no)}&numerator_fk=${encodeURIComponent(numerator_fk)}`);
  };

  useEffect(() => {
    const cookiesAccepted = Cookies.get('cookiesAccepted');
    if (cookiesAccepted === 'true') {
      setIsBannerOpen(false);
    }
  }, []);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };



  return (
    <div className="overflow-hidden">
      <div className="bg-white fixed w-full shadow-md z-50  ">
        <Navbar type="authentication" authType="loggedIn" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
      </div>
      <div className="relative overflow-hidden">
        <img src={Bg} alt="" className="absolute w-full hidden md:block md:h-[400px]" />
        <div className="z-40 mx-auto relative bg-[#171d25] md:bg-[#171d25] lg:bg-hidden">
          <div className="text-xl md:text-2xl lg:text-[40px] text-white pt-8 md:pt-[80px] pb-12 text-center bg-[#171d25] md:bg-[#171d25] lg:bg-transparent">
            <p className="font-sans pt-24 md:pt-12  leading-loose md:items-center">
              {t('welcome')} {first_name} {last_name}
            </p>
            <p className="font-sans">{t('your_products')}</p>
          </div>
        </div>
      </div>

      <div className="bg-[#F7F9FC]" style={backgroundImageStyle}>
        <div className="flex flex-col pb-10 pt-10 md:pt-6 lg:pt-12 container">
          {showContent ? (
            <button
              onClick={() => setShowContent(!showContent)}
              className="flex items-center  w-5/6  mx-auto rounded-t-xl  shadow-t-lg border-lg mt-5 bg-white px-4 ">
              <h1 className="py-5 text-left text-md lg:text-2xl font-semibold text-[#171d25]">
                {t('purchased_products')}
              </h1>

              <span className="py-5 pt-6 text-white font-medium flex-grow"></span>
              <span className="py-5 pt-6 text-white font-medium">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 text-black"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </button>
          ) : (
            <button
              onClick={() => setShowContent(!showContent)}
              className="flex items-center w-5/6 rounded-xl mx-auto shadow-lg border-lg mt-5 bg-white px-4 ">
              <h1 className="py-5 text-left text-md lg:text-2xl font-semibold text-[#171d25]">
                {t('purchased_products')}
              </h1>

              <span className="py-5 pt-6 text-white font-medium flex items-center">
              </span>
              <span className="py-5 pt-6 text-white font-medium flex items-center ml-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 text-black"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </span>
            </button>

          )}
          {showContent && (
         <div className="container mx-auto flex items-center justify-center">
         <div className="w-5/6 overflow-auto shadow-xl">
           <table className="bg-white rounded-b-xl shadow-xl mx-auto border-separate border-spacing-y-3 overflow-x-auto w-full">
             <thead className="text-[#838c9b] font-regular">
               <tr>
                 <th className="w-1/3 text-[10px] md:text-[14px] lg:text-base md:py-4 text-left pl-6 md:pl-11 lg:pl-14">{t('product')}</th>
                 <th className="w-1/3 text-[10px] md:text-[14px] lg:text-base md:py-4 text-left pl-2 md:pl-2 lg:pl-14">{t('insurance_period')}</th>
                 <th className="w-1/3 text-[10px] md:text-[14px] lg:text-base md:py-4 text-left pl-2 md:pl-2 lg:pl-0">{t('status')}</th>
               </tr>
             </thead>
             <tbody className="mt-4 grid-cols-5 pb-2 scale-x-95 border-collapse overflow-auto md:overflow-visible">
               {slicedProducts1.map((product, productIndex) => (
                 <tr className="my-1 text-gray-900 bg-[#f5f6fa] first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg h-[57px] px-3 pl-5 rounded-[12px] font-work-sans mb-2 pt-4" key={productIndex}>
                   <td className="text-[10px] md:text-[16px] font-regular text-[#171d25] bg-[#f5f6fa] first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg font-normal py-2 pl-4 md:pl-7 pr-4 md:pr-7">
                     {product.product.toString()}
                   </td>
                   <td className="text-[10px] font-regular md:text-[14px] lg:text-[16px] whitespace-nowrap text-gray-900 bg-[#f5f6fa] first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg font-normal lg:pl-11 pr-4 md:pr-7">
                     {product.insurance_period_from && product.insurance_period_to && (
                       (() => {
                         const formatDate = (dateString) => {
                           const [year, month, day] = dateString.split('-');
                           return `${day}/${month}/${year}`;
                         };
                         const formattedFrom = formatDate(product.insurance_period_from);
                         const formattedTo = formatDate(product.insurance_period_to);
                         return `${formattedFrom} - ${formattedTo}`;
                       })()
                     )}
                   </td>
                   <td className="text-[10px] md:text-[14px] font-regular py-2 whitespace-nowrap pl-2 relative">
                     <div className="flex items-center">
                       <p className="font-semibold text-left">
                         {product.status === 1 ? (
                           <span className="text-green-500 font-medium"> {t('active')} </span>
                         ) : (
                           <span className="text-red-500 font-medium"> {t('not_active')} </span>
                         )}
                       </p>
                       {/* <button
                            className="bg-[#f7ee00] hover:bg-[#E0D815] text-[8px] md:text-[12px] lg:text-sm font-medium whitespace-nowrap px-2 py-2 lg:px-4 justify-center items-center rounded-lg absolute ml-10 md:ml-14 lg:right-16">
                            {t('report_the_claim')}
                          </button> */}
                     </div>
                   </td>
                   <td className="text-[10px] md:text-[16px] font-work-sans rounded-r-lg text-[#838c9b] font-normal pr-4 lg:pr-7">
                     <div className="flex items-center justify-center BO lg:pr-12 px-2">
                       <Link to={`/home/product-details/${product.id}`}>
                         <button className="text-[#838c9b] font-regular hover:underline pl-20 md:pl-0"> {t('details')} </button>
                       </Link>
                       <svg
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                         strokeWidth="1.5"
                         stroke="currentColor"
                         className="w-[10px] h-[10px] md:w-[13px] md:h-[13px] text-[#838c9b]"
                       >
                         <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"></path>
                       </svg>
                     </div>
                   </td>
                 </tr>
               ))}
               {displayedRows1 < produktetEBleraDataList.length && (
                 <tr>
                   <td colSpan="4" className="text-right">
                     <button
                       onClick={loadMoreRows1}
                       className="bg-[#f5f6fa] hover:bg-[#d8d8d8] font-semibold text-[#494e57] border text-[10px] md:text-base px-2 md:px-4 py-2  rounded-md"
                     >
                       {t('see_more')}
                     </button>
                   </td>
                 </tr>
               )}
             </tbody>
           </table>
         </div>
       </div>
          )}
          {showSecondContent ? (
            <div className="container mx-auto flex items-center justify-center">
              <button
                onClick={() => setShowSecondContent(!showSecondContent)}
                className="bg-white flex rounded-t-xl w-5/6  py-5 pl-4 lg:pl-4 mt-6"
              >
                <h1 className="text-md lg:text-2xl  font-semibold text-[#171d25]">
                  {t('completed_payments')}
                </h1>

                <span className="text-white font-medium flex-grow"></span>
                <span className="text-white font-medium pr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-black"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </span>
              </button>
            </div>
          ) : (
            <div className="container mx-auto flex items-center justify-center">
              <button
                onClick={() => setShowSecondContent(!showSecondContent)}
                className="bg-white pl-4 flex rounded-xl w-5/6  shadow-xl mt-6 "
              >
                <h1 className="py-5 text-left text-md lg:text-2xl font-semibold text-[#171d25]">
                  {t('completed_payments')}
                </h1>

                <span className="py-5 pt-6 text-white font-medium flex-grow"></span>
                <span className="py-5 pt-6 text-white font-medium pr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-black"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </span>
              </button>
            </div>
          )}
          {showSecondContent && (
            <div className="container mx-auto flex items-center justify-center">
              <table className="bg-white  rounded-b-xl w-5/6 table-auto shadow-xl lg:mx-auto border-separate border-spacing-y-4">
                <thead className="text-[#838c9b] font-regular">
                  <tr>
                    <th className="w-1/3 text-[10px] md:text-[14px] lg:text-base whitespace-nowrap py-4 text-left pl-6 md:pl-8 lg:pl-14">RIBK</th>
                    <th className="w-1/3 text-[10px] md:text-[14px] lg:text-base whitespace-nowrap py-4 text-left pl-4 md:pl-10 lg:pl-3">{t('payment_date')}</th>
                    <th className="w-1/3 text-[10px] md:text-[14px] lg:text-base whitespace-nowrap py-4 text-left">{t('total_payment')}</th>
                  </tr>
                </thead>
                <tbody className="scale-x-95">
                  {slicedProducts2.map((transaction, transactionIndex) => {
                    const transactionDate = new Date(transaction.transaction_date);
                    const formattedDate = transactionDate
                      .toISOString()
                      .split("T")[0];
                    return (
                      <tr
                        key={transactionIndex}
                        className="bg-[#f5f6fa] rounded-lg"
                      >
                          <td className="col text-[10px] font-regular md:text-[16px] py-4 text-gray-900 font-normal pl-4 lg:pl-7 rounded-l-lg">
                          {transaction.agreement_number}
                        </td>
                        <td className="text-[10px] font-regular col md:text-[16px] pl-4 md:pl-9 lg:pl-1 py-3 text-gray-900 font-normal ">
                          {formattedDate}
                        </td>
                        <td className="text-[10px] font-regular col md:text-[14px] py-3 text-gray-900 font-normal rounded-r-lg pl-2 md:pl-2 lg:pl-3">
                          {transaction.transaction_amount} €
                        </td>
                      </tr>
                    );
                  })}
                  {displayedRows2 < pagesatErealizuaraDataList.length && (
                   <tr>
                   <td colSpan="3" className="text-right pb-4">
                     <button
                       onClick={loadMoreRows2}
                       className="bg-[#f5f6fa] hover:bg-[#d8d8d8] font-semibold text-[#494e57] border text-[10px] md:text-base px-2 md:px-4 py-2  rounded-md"
                     >
                       {t('see_more')}
                     </button>
                   </td>
                 </tr>
                  )}
                </tbody>
              </table>
            </div>

          )}
          {showThirdContent ? (
            <div className="container mx-auto flex items-center justify-center">
              <button
                onClick={() => setShowThirdContent(!showThirdContent)}
                className="bg-white flex rounded-t-xl w-5/6 mt-6 "
              >
                <h1 className="py-5 text-left text-md lg:text-2xl pl-4  font-semibold text-[#171d25]">
                  {t('next_installments')}
                </h1>
                <span className="py-5 pt-6 text-white font-medium flex-grow"></span>
                <span className="py-5 pt-6 text-white font-medium pr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-black ml-auto"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                </span>
              </button>
            </div>

          ) : (
            <div className="container mx-auto flex items-center justify-center">
              <button
                onClick={() => setShowThirdContent(!showThirdContent)}
                className="bg-white flex rounded-xl w-5/6  shadow-xl mt-6 "
              >
                <h1 className="py-5 text-left text-md lg:text-2xl pl-4 font-semibold text-[#171d25]">
                  {t('next_installments')}
                </h1>
                <span className="py-5 pt-6 text-white font-medium flex-grow"></span>
                <span className="py-5 pt-6 text-white font-medium pr-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-black"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                </span>
              </button>
            </div>
          )}
          {showThirdContent && (
            <div className="container mx-auto flex items-center justify-center">
              <table className="bg-white rounded-b-xl w-5/6 table-auto shadow-xl lg:mx-auto border-separate border-spacing-y-4">
                <thead className="text-[#838c9b] font-regular">
                  <tr>
                    <th className="w-1/3 text-[10px] md:text-[14px] lg:text-base whitespace-nowrap  py-4 text-left pl-6 md:pl-8 lg:pl-14">RIBK</th>
                    <th className="w-1/3 text-[10px] md:text-[14px] lg:text-base whitespace-nowrap  py-4 text-left pl-4 md:pl-10 lg:pl-3">{t('payment_date')}</th>
                    <th className="w-1/3 text-[10px] md:text-[14px] lg:text-base whitespace-nowrap  py-4 text-left pl-2 md:pl-0">{t('total_payment')}</th>
                  </tr>
                </thead>
                <tbody className="scale-x-95">
                  {customerInstallmenntsDataList
                    .slice(0, displayedRows3)
                    .map((item, index) => {
                      const transactionDate = new Date(item.maturity_date);
                      const formattedDate = transactionDate
                        .toISOString()
                        .split("T")[0];
                      return (
                        <tr
                          key={index}
                          className="bg-[#f5f6fa]"
                        >
                          <td className="col text-[10px] font-regular md:text-[16px] py-4 text-gray-900 font-normal pl-4 lg:pl-7 rounded-l-lg">
                            {item.agreement_number}
                          </td>
                          <td className="text-[10px] col font-regular md:text-[16px] pl-4 md:pl-9 lg:pl-1 py-3 text-gray-900 font-normal ">
                            {formattedDate}
                          </td>
                          <td className="text-[10px] font-regular col md:text-[14px] py-3 text-gray-900 font-normal rounded-r-lg pl-4 md:pl-2 lg:pl-3">
                            {item.request_amount} €
                          </td>
                        </tr>
                      );
                    })}
                  {displayedRows3 < customerInstallmenntsDataList.length && (
                    <tr>
                      <td colSpan="3" className="text-right">
                        <button
                          onClick={loadMoreRows3}
                          className="bg-[#f5f6fa] font-semibold hover:bg-[#d8d8d8] text-[#494e57] border text-[10px] md:text-base px-2 md:px-4 py-2  rounded-md"
                        >
                          {t('see_more')}
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}

          <div className=" pt-4 md:pt-1 container">
            <div className="mx-auto relative z-0">
              <div className="flex items-center mx-[10%] justify-start text-[#0f1d31]">
                <div className=" text-center">
                  <p className="text-xl md:text-4xl mb-4 font-sans pt-4 md:pt-8 md:mr-8">
                    {t('insurance_type')}
                  </p>
                  <p className="text-xs md:text-sm font-regular lg:text-base pb-8 md:mx-[20%] w-full md:w-[60%] text-center ">
                    {t('insurance_description')}
                  </p>

                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2  mx-[13%] lg:mx-[17%] mb-24 md:mb-8  gap-[4%]">
                <div className="bg-white rounded-[16px] shadow-xl">
                  <div className="white-background-color  rounded-t-[16px]">
                    <img
                      src={Woman}
                      alt=""
                      className="rounded-t-2xl"
                    />
                  </div>
                  <div className="mb-10 lg:pt-4 px-10">
                    <p className=" text-center py-6 text-sm md:text-md lg:text-lg font-semibold text-[#0f1d31]">
                      {t('life_insurance')}
                    </p>
                    <div className="space-y-3">
                      {filteredJeteProducts.map((product) => (
                        <button
                          key={product.id}
                          className="font-normal pointer font-medium text-[#1a2027] rounded-[8px] border-[1px] text-[12px] lg:text-base py-3 container button-bg hover:bg-[#161c24] hover:text-white  mb-2"
                          onClick={() => handleButtonClick(product)}
                        >
                          {product.name}

                        </button>

                      ))}
                    </div>
                  </div>
                </div>

                <div className="bg-white shadow-xl rounded-[16px]">
                  <div className="white-background-color rounded-t-[16px]">
                    <img
                      src={House}
                      alt=""
                      className="rounded-t-2xl"
                    />
                  </div>
                  <div className="mb-4 md:mb-0 md:pt-2 lg:pt-4   px-10">
                    <p className="text-center py-6 text-sm md:text-md lg:text-lg font-semibold text-[#0f1d31]">
                      {t('property_insurance')}
                    </p>
                    <div className="space-y-3 mb-4">
                      {filteredProneProducts.map((product) => (
                        <button
                          key={product.id}
                          className="font-medium pointer rounded-[8px] text-[#1a2027] border-[1px] text-[12px] lg:text-base py-3 container button-bg hover:bg-[#161c24] hover:text-white mb-4"
                          onClick={() => handleButtonClick(product)}
                        >
                          {product.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="bg-white shadow-xl rounded-[16px] ">
                  <div className="white-background-color rounded-t-[16px]">
                    <img
                      src={Car}
                      alt=""
                      className="rounded-t-2xl"
                    />
                  </div>
                  <div className="mb-10 pt-3 px-10">
                    <p className="text-center py-6 text-sm md:text-md lg:text-lg font-semibold text-[#0f1d31]">
                      {t('vehicle_insurance')}
                    </p>

                    <div className="space-y-3">
                      {filteredAutomjetProducts.map((product) => (
                        <button
                          key={product.id}
                          className="font-medium pointer rounded-[8px] text-[#1a2027] border-[1px] text-[12px] lg:text-base py-3 container button-bg hover:bg-[#161c24] hover:text-white mb-4"
                          onClick={() => handleButtonClick(product)}
                        >
                          {product.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mb-24 md:mb-0">
                  <div className="bg-white shadow-xl rounded-[16px] r-0 h-full">
                    <div className="rounded-t-[16px] ">
                      <img
                        src={Sethoscope}
                        alt=""
                        className="rounded-t-2xl"
                      />
                    </div>
                    <div className="mb-10 md:pt-3 px-10">
                      <p className="text-center py-6 text-sm md:text-md lg:text-lg font-semibold text-[#0f1d31]">
                        {t('insurance_health')}
                      </p>
                      <div className="mb-4 space-y-3">
                        {filteredShendetDheAksidenteProducts.map((product) => (
                          <button
                            key={product.id}
                            className="font-medium pointer rounded-[8px] text-[#1a2027] border-[1px] text-[12px] lg:text-base py-3 container button-bg hover:bg-[#161c24] hover:text-white"
                            onClick={() => handleButtonClick(product)}
                          >
                            {product.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {isBannerOpen && (
          <div className='border fixed bottom-0  left-0 right-0 bg-white h-[70px] md:h-[124px] rounded-3xl flex items-center md:ps-10 md:m-20 z-50'>
            <p className='text-black pl-4 md:pl-0 text-[6px] md:text-sm lg:text-base'>
              {t('we_use_cookies')}
            </p>
            <div className='flex lg:pl-36 md:mr-4 mx-auto'>
              <div className='pl-4'>
                <button className='bg-[#F7EE00] text-center hover:bg-[#E0D815] text-[#171D25] text-[10px] md:text-base pr-2 rounded-3xl md:w-[192px] h-[54px] md:ms-10 font-bold flex items-center justify-center' onClick={handleAcceptCookies}>
                  <span className='mr-2'>
                    <img src={CookieIcon} alt='CookieLogo' className='hidden md:block md:h-6 md:w-6' />
                  </span>
                  {t('accept_cookies')}
                </button>
              </div>
              <div className="pl-2" style={{ whiteSpace: 'nowrap' }}>
                <button className="bg-white text-[#171D25] border px-2 md:px-0 text-[10px] md:text-base rounded-3xl inline-flex md:w-[142px] h-[54px] font-bold items-center justify-center">
                  <span className="md:mr-2"></span>
                  {t('learn_more')}
                </button>
              </div>
            </div>
            <button
              onClick={() => setIsBannerOpen(false)} // Close the banner on button click
              type="button"
              className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none mx-auto"
              data-te-modal-dismiss0
              aria-label="Close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-7 w-7 mr-4 md:mr-8">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

        )}
      </div>
      <div className="relative mt-[25%] md:mt-[10%] lg:mt-[0%] bottom-0 container mx-auto">
        <Footer landing="landing" />
      </div>
    </div>
  );
};

export default Home;

