import React, { useState, useEffect } from "react";
import RaiffeisenSmallLogo from "../../assets/icons/raiffeisen-small-logo.png";
import quantix from "../../assets/icons/quantix-logo.png";
import { Link } from "react-router-dom";
import FacebookIcon from "../../assets/icons/Facebook.svg";
import InstagramIcon from "../../assets/icons/Instagram.svg";
import LinkedinIcon from "../../assets/icons/Linkedin.svg";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import enTranslations from '../../locales/en/translation.json';
import alTranslations from '../../locales/al/translation.json';
import Cookies from 'js-cookie';


const Footer = ({ landing }) => {
  const { i18n } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const [languageClicked, setLanguageClicked] = useState(false);
  const [selectedValue, setSelectedValue] = useState('al');
  const selectedTranslations = selectedValue === 'en' ? enTranslations : alTranslations;

  const { t } = useTranslation();

  const handleClick = () => {
    setClicked(!clicked);
    i18next.changeLanguage(landing.target.value);
  };
  
const handleLanguageClick = () => {
  setLanguageClicked(!languageClicked);
};

  const handleSelectChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedValue(newLanguage);
    i18n.changeLanguage(newLanguage);
    Cookies.set("selectedValue", newLanguage);
  };

  useEffect(() => {
    const savedValue = Cookies.get("selectedValue");
    if (savedValue) {
      setSelectedValue(savedValue);
      // i18n.changeLanguage(savedValue);
    }
  }, [i18n]);

  return (
    <form>
      {landing !== "landing" && (
        <div className="w-full text-white flex items-center md:justify-center justify-start py-8 bg-[#161c24]">
<img
  src={RaiffeisenSmallLogo}
  alt=""
  className={landing !== "landing" ? "h-8 mr-3 md:mr-0 pl-4 md:pl-20 lg:pl-[30%]" : ""}
/>
        <div className="pt-1 font-regular pl-2 md:pl-0 text-xs">
          {t('copyright')}
          <a href="https://www.thequantix.com/">
            <span className="underline text-green-500">
              <img className="inline-block pb-1" src={quantix} alt="Quantix Website" />
            </span>
          </a>
        </div>
      </div>      
      )}
      {landing === "landing" && (
        <footer className="bg-white lg:pr-4 md:pl-2">
          <div className="container mx-auto w-full mt-[-5%] p-1 lg:py-8 lg:mt-0">
            <div className="md:flex md:justify-between md:pt-6">
              <div className="mt-4 md:mt-0">
                <a href="/" className="flex items-center">
                  <img src={RaiffeisenSmallLogo} alt="" className="h-8 mr-3 md:mr-0 pl-4 md:pl-20 lg:pl-[30%]"
                  />
                </a>
                <div className="inline-block mt-9 ml-[-7%] lg:ml-14 md:flex pl-1 lg:pl-0 md:items-center ">
                  <Link
                    to="https://www.facebook.com/RIBKosova/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={FacebookIcon}
                      className="inline-block cursor-pointer pl-6 md:pl-20 lg:pl-0"
                      alt=""
                    />
                  </Link>
                  <Link
                    to="https://www.instagram.com/banka_raiffeisen/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={InstagramIcon}
                      className="inline-block cursor-pointer"
                      alt=""
                    />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/raiffeisen-insurance-broker-kosov%C3%AB/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={LinkedinIcon}
                      className="inline-block cursor-pointer lg:pr-16 "
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="grid mr-[-7%] lg:mr-[4%] grid-cols-2 gap-1 sm:gap-6 lg:grid-cols-3 pl-4 md:pl-8 lg:pl-0">
                <div className="lg:ml-[90%] text-[#212b36]">
                  <h2 className="mb-6 text-xs font-sans uppercase ">{t('link')}</h2>
                  <ul className="font-medium mb-4">
                    <li className="whitespace-nowrap text-sm font-regular">
                      <Link to="/about-us" className="hover:underline ">
                        {t('about_us')}
                      </Link>
                    </li>
                    <li className="mt-4 text-sm font-regular">
                      <Link to="/help" className="hover:underline">
                        {t('help')}
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="lg:ml-[25%] text-[#212b36]">
                  <h2 className="mb-2 font-sans md:mb-6 text-sm font-extrabold uppercase test">
                    {t('legal')}
                  </h2>
                  <ul className="font-medium">
                    <li className="mb-4 text-sm font-regular">
                      <Link to="/terms-of-use" className="hover:underline">
                        {t('terms_conditions')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" className="font-regular text-sm hover:underline">
                        {t('privacy_policy')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="lg:ml-[-20%]">
                  <Link to="/contact" className="font-sans hover:underline">
                    <h2 className="mb-2 md:mb-6 text-xs font-semibold uppercase ">
                      {t('contact')}
                    </h2>
                  </Link>

                  <ul className="font-regular text-sm">
                    <li className="mb-4">
                      <p>{t('phone_number')}: +383(0)38 222 222 337</p>
                    </li>
                    <li className="mb-4">
                      <a
                        href="https://mail.google.com/mail/u/0/?pli=1#inbox?compose=GTvVlcSDbSMrTKQTBpTnKCGpcDXHSxkfkgDcbFfhbptrtPdHJpNMmvxfBSmsCdLTzPxKzWqRwjBSF"
                        className="hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Email: info.broker@raiffeisen-kosovo.com
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.google.com/maps/place/Raiffeisen+Bank/@42.6567293,21.150071,17z/data=!4m10!1m2!2m1!1sRobert+Doll+99,+Prishtina+10000,+Kosovo!3m6!1s0x13549eef09133ead:0xac866d616b3c71f!8m2!3d42.6567295!4d21.1544482!15sCidSb2JlcnQgRG9sbCA5OSwgUHJpc2h0aW5hIDEwMDAwLCBLb3Nvdm-SAQRiYW5r4AEA!16s%2Fg%2F12vrjfm99?entry=ttu"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:underline text-black"
                      >
                        <p>{t('street')}</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sm:flex sm:items-center sm:justify-between ml-1 md:ml-0 mt-10 mb-6">
              <span className="text-sm sm:text-center  md:pl-16 lg:pl-16">
                <div className="pt-3 font-regular ml-2">
                  {t('copyright')}
                  <span className="ml-1">
                    <a href="https://www.thequantix.com/">
                      <img
                        className="inline-block"
                        src={quantix}
                        alt="Quantix Website"
                      />
                    </a>
                  </span>
                </div>
              </span>
            </div>
          </div>
        </footer>
      )}
    </form>
  );
};

export default Footer;
