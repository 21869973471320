import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import InsurexLogoX from "../../assets/images/insurex-logo-x-green.png";
import Background from "../../assets/images/raiffeisen-bg.png";
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import config from '../../config';
import Cookies, { set } from 'js-cookie';
import Success from '../../assets/icons/success-icon.svg';
import { Link, useNavigate } from 'react-router-dom';

const initialValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const onSubmit = (values) => {
};

const validate = (values) => {
  let errors = {};
  const currentPasswordValidationMessage = "Current password is required!";
  if (!values.currentPassword) {
    errors.currentPassword = currentPasswordValidationMessage;
  }
  const newPasswordValidationMessage = "New password is required!";

  if (!values.newPassword) {
    errors.newPassword = newPasswordValidationMessage;
  } else if (values.newPassword.length < 8) {
    errors.newPassword = "New password must be at least 8 characters long!";
  } else if (!/[A-Z]/.test(values.newPassword)) {
    errors.newPassword = "New password must contain at least one uppercase letter!";
  } else if (
    !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(values.newPassword)
  ) {
    errors.newPassword = "New password must contain at least one symbol!";
  }

  const confirmPasswordValidationMessage = "New password is required!";
  if (!values.confirmPassword) {
    errors.newPassword = confirmPasswordValidationMessage;
  } else if (values.confirmPassword.length < 8) {
    errors.confirmPassword = "Password must be at least 8 characters long!";
  } else if (!/[A-Z]/.test(values.confirmPassword)) {
    errors.confirmPassword =
      "Password must contain at least one uppercase letter!";
  } else if (
    !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(values.confirmPassword)
  ) {
    errors.confirmPassword = "Password must contain at least one symbol!";
  }
  return errors;
};

const ChangePasword = () => {
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState('al');
  const [isLoading, setIsLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };



  const handleChangePassword = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {

      if (newPassword !== confirmNewPassword) {
        setErrorMessage('Passwords didnt match');
        setIsLoading(false);
        return;
      }

      const response = await api.post('/users/api/change-password/', {
        old_password: oldPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      });
      if (response.status === 200) {
        const allCookies = Cookies.get();

        for (let cookieName in allCookies) {
          Cookies.remove(cookieName);
        }
        setShowSuccess(true);
      }
      if (response.status === 400) {
        setErrorMessage('Password is wrong or new password and confirm password did not match');
      }

    } catch (error) {
      setErrorMessage('Problems while changing password');
    }
    setIsLoading(false);
  };

  const handleSuccessClose = () => {
    setShowSuccess(false);
  };

  const YourCircularIndicatorComponent = () => {
    return (
      <div className="flex justify-center items-center">
        <div style={{ borderColor: '#FFDD00' }} className="w-6 h-6 border-t-4 border-yellow-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  };


  return (
    <div>
      <img src={Background} className="bg-[#726f6f] fixed w-full h-[109vh] object-cover" />
      <div className="relative">
        <Navbar type="authentication" landing="landing" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
      </div>
      <form onSubmit={handleChangePassword}>
        <div className="md:flex md:flex-col items-center justify-center px-6 md:py-8 mx-auto lg:py-0 relative">
          <div className="flex justify-center items-center">
            <img className=" pb-1.5" src={InsurexLogoX} alt="" />
          </div>
          <p className="text-[#9b9b9b] pb-8 text-sm up font-regular text-center">{t('insurance_platform')}</p>
          <div className="bg-white sm:max-w-md mb-14 md:mb-0 mt-6 rounded-md">
            <div className="px-6 space-y-4 md:space-y-6 sm:p-8">
              <div className="">
                <p className="text-[20px] font-semibold text-[#282d30] mb-6 font-bold mt-[20px] pt-10 md:pt-0">
                  {t('change_password_uppercase')}
                </p>
                <p className="text-xs font-medium text-[#282d30] mb-3">
                  {t('type_confirm_password')}
                </p>
                <div>
                  <div>
                    <input
                      className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-96 mt-4 text-sm"
                      onChange={(event) => setOldPassword(event.target.value)}
                      value={oldPassword}
                      type="password"
                      placeholder={t('currrent_pass')}
                      id="password"
                      name="currentPassword"
                    />
                  </div>
                  <div>
                    <input
                      className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-96 mt-4 text-sm"
                      onChange={(event) => setNewPassword(event.target.value)}
                      value={newPassword}
                      type="password"
                      placeholder={t('new_pass')}
                      id="newPassword"
                      name="newPassword"
                    />
                  </div>
                  <div>
                    <input
                      className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-96 mt-4 text-sm"
                      onChange={(event) => setConfirmNewPassword(event.target.value)}
                      value={confirmNewPassword}
                      type="password"
                      placeholder={t('confirm_password')}
                      id="confirmNewPassword"
                      name="confirmPassword"
                    />
                    {/* {formik.errors.confirmPassword ? (
                      <div className="text-red-600 text-xs mt-1">
                        {formik.errors.confirmPassword}
                      </div>
                    ) : null} */}
                  </div>
                </div>
                {errorMessage && <p className="text-sm font-medium text-[#ff0000]">{errorMessage}</p>}
              </div>
            </div>
            {showSuccess && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 pt-40">
                <div className="bg-white rounded-md  p-12">
                  <img src={Success} alt="" className='mx-auto my-auto block mb-4' />
                  <h1 className="mb-4 font-semibold text-[24px] text-center text-[#313a3c]"> {t('wiht_success')} </h1>
                  <p className="text-center text-sm font-medium pt-1"> {t('change_password_success')} </p>
                  <Link to="/login">
                    <button type="button" className="w-[380px] h-[52px] font-semibold rounded-md text-[#fff] uppercase bg-[#313a3c] hover:bg-[#374151] mt-4" onClick={handleSuccessClose}>
                      {t('continue')}
                    </button>
                  </Link>
                </div>
              </div>
            )}
            <div className="mb-1 px-6 md:px-8">
              <button
                type="submit"
                className=" text-[#ffffff] font-medium text-sm uppercase bg-[#313a3c] hover:bg-[#374151] rounded-md px-5 py-4 mr-2 w-72 md:w-96 mb-[64px] mt-6 md:mt-0"
              >
                {isLoading ? <YourCircularIndicatorComponent /> : t('change_password')}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="bottom-0 w-full  fixed">
        <Footer />
      </div>
    </div>
  );
};

export default ChangePasword;
