import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import api from '../../services/api';
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Background from "../../assets/images/raiffeisen-bg.png";
import InsurexLogoX from "../../assets/images/insurex-logo-x-green.png";

const NonRBClientRegister = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [password, setPassword] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [legal_number, setLegal_number] = useState("");
  const [bank_account_number] = useState("");
  const [address, setAddress] = useState("");
  const [business_number, setBusiness_number] = useState("");
  const [birth_date, setBirth_date] = useState("");
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [agree, setAgree] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);


  const checkboxHandler = () => {
    setAgree(!agree);
    setShowError(false);
  };

  const handleButtonClick = () => {
    if (!agree) {
      setShowError(true);
    } else {
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(Array.from(files));
  };



  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    checkPasswordStrength(event.target.value);
  };

  const checkPasswordStrength = (password) => {
    if (password.length === 0) {
      setPasswordStrength('');
      setPasswordError('Fjalëkalimi është i detyrueshëm.');
    } else if (password.length < 8) {
      setPasswordStrength('weak');
      setPasswordError('Fjalëkalimi duhet të jetë të paktën 8 karaktere i gjatë.');
    } else {
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      let errors = [];

      if (!hasUpperCase) {
        errors.push('Fjalëkalimi duhet të përmbajë të paktën një shkronjë të madhe.');
      }

      if (!hasLowerCase) {
        errors.push('Fjalëkalimi duhet të përmbajë të paktën një shkronjë të vogël.');
      }

      if (!hasNumber) {
        errors.push('Fjalëkalimi duhet të përmbajë të paktën një numër.');
      }

      if (!hasSymbol) {
        errors.push('Fjalëkalimi duhet të përmbajë të paktën një simbol.');
      }

      if (errors.length === 0) {
        setPasswordStrength('strong');
        setPasswordError('');
      } else {
        setPasswordStrength('weak');
        setPasswordError(errors[0]);
      }
    }


  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {

      const formData = new FormData();
      formData.append("username", username);
      formData.append("email", email);
      formData.append("role", "8");
      formData.append("gender", gender);
      formData.append("phone_number", phone_number);
      formData.append("password", password);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("legal_number", legal_number);

      selectedFiles.forEach((file) => {
        formData.append(`id_photo`, file);
      });

      const response1 = await api.post(`/internal-users/api/internal-users/create/`, formData, {
        username: username,
        email: email,
        gender: gender,
        phone_number: phone_number,
        password: password,
        first_name: first_name,
        last_name: last_name,
        legal_number: legal_number,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const response2 = await api.post(`/customers/api-create/`, {
        address: address,
        birth_date: birth_date,
        bank_account_number: bank_account_number,
        email: email,
        gender: gender,
        phone_number: phone_number,
        first_name: first_name,
        last_name: last_name,
        pep: "1",
        legal_number: legal_number,
        business_number: business_number,
        user: response1.data.id
      });

      if (response1.status === 200 && response2.status === 200) {
        Cookies.set("username", username);
        Cookies.set("first_name", first_name);
        Cookies.set("last_name", last_name);
        Cookies.set("email", email);
        Cookies.set("gender", gender);
        Cookies.set("phone_number", phone_number);
      }

      setIsLoading(false);
      setErrorMessage("");
      navigate(`/login?username=${username}`);


    } catch (error) {
      setIsLoading(false);
      setErrorMessage("An error occurred. Please try again later.");
    }
  };


  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };


  return (
    <div className=" overflow-hidden">
      <img src={Background} alt="Background" className="bg-[#726f6f] fixed w-full  object-cover h-[1210px] md:h-full" />
      <div className="relative">
        <Navbar type="authentication" landing="landing" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
      </div>
      <div className="md:flex md:flex-col items-center justify-center px-6 md:py-6 mx-auto lg:py-0 relative ">
      <img className=" pb-4 mx-auto" src={InsurexLogoX} alt="" />
          <p className="text-[#9b9b9b] pb-8 text-sm font-regular text-center">{t('insurance_platform')}</p>
        <form onSubmit={handleSubmit}>
          <div className="bg-white mt-8 rounded-md mb-10 px-2.5">
            <div className="flex font-bold pt-4 md:pt-8 justify-center items-center">
              <h1 className="text-xl font-bold"> {t('set_up')} </h1>
            </div>
            <p className="text-center text-[#222b45] text-sm md:text-[15px] pt-2 font-medium"> {t('are_you_a_client')} </p>
            <div className="flex justify-center  space-x-8 pt-4">
              <div className="flex items-center">
                <Link to="/register">
                  <input
                    className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] checked:bg-black cursor-pointer mt-1"
                    type="radio"
                    name="pep"
                    value="pepNo"
                  />
                </Link>
                <label
                  htmlFor="inline-radio"
                  className="ml-2 text-[15px] font-medium  text-[#0f1d31] mt-[-3.5px]"
                >
                  {t('yes')}
                </label>
              </div>
              <div className="flex items-center ">
                <Link to="/register">
                  <input
                    className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                    type="radio"
                    name="pep"
                    value="pepYes"
                    checked
                  />
                </Link>
                <label
                  htmlFor="inline-radio"
                  className=" ml-2 text-[15px] font-medium text-[#0f1d31] mt-[-3.5px]"
                >
                  {t('no')}
                </label>
              </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3  justify-center gap-3 mt-4  items-center">
              <div className="md:ml-4 lg:ml-10">
                <input
                  className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-[320px] lg:w-[380px] h-[52px] text-sm"
                  onChange={(event) => setFirst_name(event.target.value)}
                  value={first_name}
                  type="text"
                  placeholder={t('name')}
                  id="first_name"
                  name="first_name"
                />
              </div>
              <div className="lg:ml-6">
                <input
                  className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-[320px] lg:w-[380px] h-[52px] text-sm"
                  onChange={(event) => setLast_name(event.target.value)}
                  value={last_name}
                  type="text"
                  placeholder={t('last_name')}
                  id="last_name"
                  name="last_name"
                />
              </div>

              <div className="md:ml-4 lg:ml-0">
                <input
                  className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-[320px] lg:w-[380px] h-[52px] text-sm"
                  onChange={(event) => setLegal_number(event.target.value)}
                  value={legal_number}
                  type="text"
                  placeholder={t('personal_number')}
                  id="legal_number"
                  name="legal_number"
                />
              </div>


              <div className="lg:ml-10">
                <input
                  className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-[320px] lg:w-[380px] h-[52px] text-sm"
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                  type="email"
                  placeholder="Email"
                  id="email"
                  name="email"
                />
              </div>
              <div className="md:ml-4 lg:ml-6">
                <input
                  className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-[320px] lg:w-[380px] h-[52px] text-sm"
                  onChange={(event) => setBirth_date(event.target.value)}
                  value={birth_date}
                  type="date"
                  placeholder={t('birth_date')}
                  id="birth_date"
                  name="birth_date"
                />
              </div>

              <div className="lg:ml-0">
                {/* <label
                  htmlFor="inline-radio"
                  className="block  text-xs font-bold text-[#0f1d31] font-regular mt-0.5 mb-2.5"
                >
                  Phone
                </label> */}
                <input
                  className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-[320px] lg:w-[380px] h-[52px] text-sm"
                  onChange={(event) => setPhone_number(event.target.value)}
                  value={phone_number}
                  type="text"
                  placeholder={t('phone_number')}
                  id="phone_number"
                  name="phone_number"
                />
              </div>
              <div className="md:ml-4 lg:ml-10">
                <input
                  className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-[320px] lg:w-[380px] h-[52px] text-sm"
                  onChange={(event) => setAddress(event.target.value)}
                  value={address}
                  type="address"
                  placeholder={t('adres')}
                  id="address"
                  name="address"
                />
              </div>
              <div className="lg:ml-6">
                <input
                  className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3 w-72 md:w-[320px] lg:w-[380px] h-[52px] text-sm"
                  onChange={(event) => setBusiness_number(event.target.value)}
                  value={business_number}
                  type="business_number"
                  placeholder={t('business_number')}
                  id="business_number"
                  name="business_number"
                />
              </div>
              <div className="md:ml-4 lg:ml-2">
                <div
                  className="flex"
                  onChange={(event) => setGender(event.target.value)}
                  value={gender}
                >
                  <div className="flex items-center mr-4 md:mt-2">
                    <input
                      className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] checked:bg-black cursor-pointer  mt-1"
                      type="radio"
                      name="gender"
                      value="1"
                    />
                    <label
                      htmlFor="inline-radio"
                      className=" ml-2 text-[13px] text-bold text-[#0f1d31] font-medium mt-1"
                    >
                      {t('male')}
                    </label>
                  </div>
                  <div className="flex items-center mr-4 md:mt-2">
                    <input
                      className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] checked:bg-black cursor-pointer  mt-1"
                      type="radio"
                      name="gender"
                      value="2"
                    />
                    <label
                      htmlFor="inline-radio"
                      className="ml-2 text-[13px] text-bold text-[#0f1d31] font-medium mt-1"
                    >
                      {t('female')}
                    </label>
                  </div>
                </div>
              </div>

              <div className="lg:ml-10 ">
                <input
                  className="text-[#0f1d31] font-regular bg-[#f5f6fa] p-3  w-full md:w-[320px] lg:w-[380px] h-[52px] text-sm"
                  onChange={(event) => setUsername(event.target.value)}
                  value={username}
                  type="text"
                  placeholder={t('username')}
                  id="username"
                  name="username"
                />
              </div>
              <div className="flex justify-center font-regular md:ml-2  items-center">
                <input
                  className={`text-[#0f1d31] bg-[#f5f6fa] p-3  w-full md:w-[320px] lg:w-[380px] h-[52px] text-sm${passwordStrength === 'weak' && 'border-red-500'} ${passwordStrength === 'strong' && 'border-green-500'}`}
                  onChange={handlePasswordChange}
                  value={password}
                  type="password"
                  placeholder={t('password')}
                  id="password"
                  name="password"
                  required
                />
              </div>
              {passwordError && (
                <p className="text-red-500 mt-2 text-sm flex ">
                  {passwordError}
                </p>
              )}
            </div>
            <div className="ml-4 md:ml-4 lg:ml-10 relative">
              <p className="text-[#0f1e2f] text-[12px] font-medium pt-3">{t('image_upload')}</p>
              <label htmlFor="myFile" className="custom-file-upload block text-xs font-bold text-[#0f1d31]">
                <div className="my-2 w-72 md:max-w-[130px] font-medium py-3 px-3 border-[1px] border-[#86b1ad] border-dashed rounded-[3px] cursor-pointer bg-[#f7f8f9] text-[#86b1ad]">
                  {t('upload_images')}
                </div>
                <input type="file" id="myFile" name="myFile" onChange={handleFileChange} multiple className="hidden" required />
              </label>
              <div className="mt-3">
                {selectedFiles.map((file, index) => (
                  <div key={index} className="bg-white max-w-[300px] py-3 px-3 border-[1px] border-[#e5e9f2] rounded-[3px]">
                    <p className="text-sm whitespace-wrap">{file.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex ml-5 md:ml-10 mt-4 font-medium md:mt-7 text-[8px] md:text-base text-left mb-4 mr-auto">
              <input
                onChange={checkboxHandler}
                id="agree"
                type="checkbox"
                value=""
                name="remember"
                className="h-4 w-4 mt-1 text-[#313a3c] border-gray-300 rounded accent-yellow-300	"
              />
              <label
                htmlFor="link-checkbox"
                className="ml-2  mt-[2px] text-xs md:text-sm text-[#313a3c]"
              >
                {t('completing_steps')}
                <span className="underline font-bold  mx-1">
                  <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
                    {t('the_terms_of_use')}
                  </Link>
                </span>
                {t('and')}
                <span className="underline font-medium  mx-1">
                  <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                    {t('privacy')}
                  </Link>
                </span>
                .
              </label>
            </div>
            {showError && (
              <p className="text-red-500 mb-2 text-sm flex ml-[3%]">
                {t('accept_terms_use_and_privacy_policy')}
              </p>
            )}
            <div className="flex md:justify-center font-semibold md:items-center">
              <button
                onClick={handleButtonClick}
                type="submit"
                className="text-white font-medium text-sm ml-4   lg:ml-auto lg:mr-10  uppercase bg-[#313a3c] hover:bg-[#374151] rounded-md px-5 py-4 mr-2  w-72 md:w-[320px] lg:w-[380px] h-[52px]  mb-10"
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </div>
            {errorMessage && (
              <p className="flex justify-center text-red-500">
                {errorMessage}
              </p>
            )}
          </div>
        </form>
      </div>
      <div className="bottom-0  bg-[#161c24] relative w-full">
        <Footer />
      </div>
    </div>
  );
};

export default NonRBClientRegister;
