import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Raiffeisenbg from "../../assets/images/raiffeisenbg.svg";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';


const TermsOfUse = () => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <>
      <div className="overflow-hidden text-justify z-50">
        {isLoggedIn ? (
          <div className="bg-[#222627] fixed z-50 w-full">
            <Navbar
              type="authentication"
              authType="loggedIn"
              landing="landing"
              selectedValue={selectedValue}
              handleSelectChange={handleSelectChange}
            />
          </div>
        ) : (
          <div className="bg-white shadow-md fixed z-50 w-full">
            <Navbar
              selectedValue={selectedValue}
              handleSelectChange={handleSelectChange}
            />
          </div>
        )}
        <img src={Raiffeisenbg} className=" absolute w-full h-[1000px] object-cover bg-[#f8fafc]" />
        <div className="mt-[5%] container py-28 px-10 md:px-24 lg:px-0 select-none relative">
          <h1 className="text-[22px] font-sans">
            {t('brokerx_terms')}
          </h1>
          <p className="py-2 inline-block font-regular">
            {t('please_read')}
          </p>
          <p className="py-2 font-regular">
            {t('using_this_website')}
          </p>
          <p className="py-2 font-regular">
            {t('terms_conditions_raiffeisen')}
          </p>
          <p className="py-2 font-regular">
            {t('any_person_purchase')}
          </p>
          <p className="py-2 font-regular">
            {t('these_terms')}
          </p>
          <p className="py-2 font-regular">
            {t('your_responsibility')}
          </p>
          <p className="py-2 font-regular">
            {t('information_provided')}
          </p>
          <p className="py-2 font-sans text-xl">
            {t('insurance_products')}
          </p>
          <p className="py-2 font-regular">
            {t('raiffeisen_insurance_broker')}
          </p>
          <p className="py-2 font-sans text-2xl">
            {t('user_and_representation')}
          </p>
          <p className="py-2 font-sans text-xl">
            {t('using_the_website')}
          </p>
          <p className="py-2 font-regular">
            {t('legal_age')}
          </p>
          <p className="py-2 font-regular">
            {t('legally_authorized')}
          </p>
          <p className="py-2 font-regular">
            {t('all_information')}
          </p>
          <p className="py-2 font-regular">
            {t('you_will_not_acces')}
          </p>
          <p className="py-2 font-regular">
            {t('provide_us_information')}
          </p>
          <p className="py-2 font-sans text-xl">
            {t('your_acceptance_terms_conditions')}
          </p>
          <p className="py-2 font-regular">
            {t('pruchasing_our_insurance')}
          </p>
          <p className="py-2 font-sans text-xl"> {t('user_registration')} </p>
          <p className="py-2 font-regular">
            {t('required_to_register')}
          </p>
          <p className="py-2 font-regular">
            {t('by_registering')}
          </p>
          <p className="py-2 font-regular">
            {t('by_agreeing')}
          </p>
          <p className="py-2 font-regular"> {t('you_choose')} </p>
          <p className="py-2 font-regular"> {t('no_longer_insecured')} </p>
          <p className="py-2 font-regular">
            {t('regarding_online_services')}
          </p>
          <p className="py-2 font-sans text-xl"> {t('prohibited_actions')} </p>
          <p className="py-2 font-regular">
            {t('may_not_use')}
          </p>
          <p className="py-2 font-regular"> {t('user_of_site')} </p>
          <p className="py-2 font-regular">
            {t('not_systematically_collect_data')}
          </p>
          <p className="py-2 font-regular">
            {t('unathuroized_manner')}
          </p>
          <p className="py-2 font-regular">
            {t('may_not_use_web_advertise')}
          </p>
          <p className="py-2 font-regular">
            {t('not_deceive')}
          </p>
          <p className="py-2 font-regular">
            {t('may_not_sell')}
          </p>
          <p className="py-2 font-regular">
            {t('engage')}
          </p>
          <p className="py-2 font-regular">
            {t('harass_insult')}
          </p>
          <p className="py-2 font-regular">
            {t('delete_notifications')}
          </p>
          <p className="py-2 font-regular">
            {t('copy_adapt')}
          </p>
          <p className="py-2 font-regular">
            {t('upload_transmit')}
          </p>
          <p className="py-2 font-regular">
            {t('upload_transmit_material')}
          </p>
          <p className="py-2 font-regular">
            {t('use_the_website')}
          </p>
          <p className="py-2 font-regular">
            {t('unlawful_unauthorized_purpose')}
          </p>
          <p className="py-2 font-sans text-xl"> {t('use_your_account')} </p>
          <p className="py-2 font-regular">
            {t('on_this_website')}
          </p>
          <p className="py-2 font-regular">
            {t('attached')}
          </p>
          <p className="py-2 font-regular">
            {t('creation_distribution_transmission')}
          </p>
          <p className="py-2 font-regular">
            {t('creator_owner')}
          </p>
          <p className="py-2 font-regular">
            {t('obtain_written')}
          </p>
          <p className="py-2 font-regular">
            {t('atached_data_no_false')}
          </p>
          <p className="py-2 font-regular">
            {t('data_not_ridicule')}
          </p>
          <p className="py-2 font-regular">
            {t('data_does_not_advocate')}
          </p>
          <p className="py-2 font-regular">
            {t('data_does_not_violate')}
          </p>
          <p className="py-2 font-regular">
            {t('data_does_not_infringe_upon_rights')}
          </p>
          <p className="py-2 font-regular">
            {t('data_does_not_contain_any_material')}
          </p>
          <p className="py-2 font-regular">
            {t('data_does_not_violete_any_law')}
          </p>
          <p className="py-2 font-regular">
            {t('data_does_not_otherwise')}
          </p>
          <p className="py-2 font-regular">
            {t('website_in_violation')}
          </p>
          <p className="py-2 font-sans text-xl">
            {t('third_party')}
          </p>
          <p className="py-2 font-regular">
            {t('third_party_website')}
          </p>
          <p className="py-2 font-regular">
            {t('please_note')}
          </p>
          <p className="py-2 font-regular">
            {t('furthermore')}
          </p>
          <p className="py-2 font-regular">
            {t('inclusion')}
          </p>
          <p className="py-2 font-regular">
            {t('review_the_applicable')}
          </p>
          <p className="py-2 font-regular">
            {t('we_do_not_endorse_the_products')}
          </p>
          <p className="py-2 font-sans text-xl"> {t('ratings')} </p>
          <p className="py-2 font-regular">
            {t('leave_comments_ratings')}
          </p>
          <p className="py-2 font-regular">
            {t('first_hand_experience')}
          </p>
          <p className="py-2 font-regular">
            {t('no_insults')}
          </p>
          <p className="py-2 font-regular">
            {t('no_discriminatory')}
          </p>
          <p className="py-2 font-regular">
            {t('references_to_illegal_activities')}
          </p>
          <p className="py-2 font-regular">
            {t('provide_negative_comments')}
          </p>
          <p className="py-2 font-regular">
            {t('dont_post_false_statements')}
          </p>
          <p className="py-2 font-regular">
            {t('cant_organize_campaign')}
          </p>
          <p className="py-2 font-regular">
            {t('accept_reject_delete')}
          </p>
          <p className="py-2 font-regular">
            {t('we_dont_take_responsibility')}
          </p>
          <p className="py-2 font-sans text-xl"> {t('social_networks')} </p>
          <p className="py-2 font-regular">
            {t('third_party_accounts')}
          </p>
          <p className="py-2 font-regular">
            {t('represent_and_warrant')}
          </p>
          <p className="py-2 font-regular">
            {t('allowing_us')}
          </p>
          <p className="py-2 font-regular">
            {t('please_note_your_relationship')}
          </p>
          <p className="py-2 font-sans text-xl"> {t('advertisers')} </p>
          <p className="py-2 font-regular">
            {t('we_may_allow')}
          </p>
          <p className="py-2 font-regular">
            {t('advertisements_on_the_website')}
          </p>
          <p className="py-2 font-sans text-xl"> {t('website_management')} </p>
          <p className="py-2 font-regular">
            {t('we_reserve_right')}
          </p>
          <p className="py-2 font-regular">
            {t('monitor_the_website')}
          </p>
          <p className="py-2 font-regular">
            {t('take_appropriate_legal_action')}
          </p>
          <p className="py-2 font-regular">
            {t('our_judgment')}
          </p>
          <p className="py-2  font-sans text-xl"> {t('validity_terms_conditions')} </p>
          <p className="py-2 font-regular">
            {t('these_terms_conditions')}
          </p>
          <p className="py-2 font-regular">
            {t('limiting_interfering')}
          </p>
          <p className="py-2 font-regular">
            {t('terminate_suspend')}
          </p>
          <p className="py-2 font-regular">
            {t('terminating_suspending')}
          </p>
          <p className="py-2 font-sans text-xl"> {t('changes_dsicontinuations')} </p>
          <p className="py-2 font-regular">
            {t('rights_to_modify')}
          </p>
          <p className="py-2 font-regular">
            {t('we_cant_guarantee')}
          </p>
          <p className="py-2 font-regular">
            {t('we_have_no_liability')}
          </p>
          <p className="py-2 font-sans text-xl">
            {t('exeptions_limitations')}
          </p>
          <p className="py-2 font-regular">
            {t('information_about_insurance_products')}
          </p>
          <p className="py-2 font-regular">
            {t('website_and_services_is_at_own_risk')}
          </p>
          <p className="py-2 font-regular">
            {t('using_our_website_to_purchase')}
          </p>
          <p className="py-2 font-regular">
            {t('we_dont_offer_any_guarantee')}
          </p>
          <p className="py-2 font-regular">
            {t('errors_inaccuracies')}
          </p>
          <p className="py-2 font-regular">
            {t('unauthorized_access')}
          </p>
          <p className="py-2 font-regular">
            {t('interruption')}
          </p>
          <p className="py-2 font-regular">
            {t('any_virus')}
          </p>
          <p className="py-2 font-regular">
            {t('any_error')}
          </p>
          <p className="py-2 font-regular">
            {t('we_dont_provide_warranties')}
          </p>
          <p className="py-2 font-regular">
            {t('shall_we_be_liable')}
          </p>
          <p className="py-2 font-regular">
            {t('not_responsible')}
          </p>
          <p className="py-2 font-sans text-xl"> {t('it_is_your_responsibility')} </p>
          <p className="py-2 font-regular">
            {t('defend_and_indemnify')}
          </p>
          <p className="py-2 font-regular">
            {t('violation_of_terms_conditions')}
          </p>
          <p className="py-2 font-regular">
            {t('violation_of_any_applicable_law')}
          </p>
          <p className="py-2 font-regular"> {t('use_of_our_website')} </p>
          <p className="py-2 font-regular">
            {t('agree_to_defend')}
          </p>
          <p className="py-2 font-regular">
            {t('you_understand')}
          </p>
          <p className="py-2 font-sans text-xl">
            {t('communication_electronic_transactions')}
          </p>
          <p className="py-2 font-regular">
            {t('completing_the_necessary_forms')}
          </p>
          <p className="py-2 font-regular">
            {t('you_are_aware')}
          </p>
          <p className="py-2 font-regular">
            {t('based_on_these_terms_conditions')}
          </p>
          <p className="py-2  font-sans text-xl"> {t('right_to_appeal')} </p>
          <p className="py-2 font-regular">
            {t('services_metting_customers')}
          </p>
          <p className="py-2 text-xl  font-sans">
            {t('resolution')}
          </p>
          <p className="py-2 font-regular">
            {t('these_terms_conditions_and_the_use_of_the_website')}
          </p>
          <p className="py-2 font-regular">
            {t('any_provision')}
          </p>
          <p className="py-2 font-regular">
            {t('event_of_a_dispute')}
          </p>
          <p className="py-2 font-regular">
            {t('negotiations_are_not_sufficient')}
          </p>
          <p className="py-2 font-sans text-xl">{t('contact')}</p>
          <p className="py-2 font-regular">
            {t('resolve_a_complaining')}
          </p>
          <p className="py-2 font-regular">Raiffeisen Insurance Broker</p>
          <p className="py-2 font-regular">{t('adress')}</p>
          <p className="py-2 font-regular"> {t('mobile_number')} </p>
          <p className="py-2 font-regular">Email: info.broker@raiffeisen-kosovo.com</p>
        </div>
        <div className="relative mt-10">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
