import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import api from '../../services/api';
import Navbar from '../../components/navbar/Navbar';
import Arrow from '../../assets/icons/left-arrow.svg';
import Background from '../../assets/images/Background.svg';
import InfoLogo from '../../assets/icons/info-icon.png';
import Footer from '../../components/footer/Footer';

const ProductDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');
  const productIndex = params.productIndex;
  const [loan_info_status, setLoanInfoStatus] = useState('');
  const [health_declaration_status, setHealthDeclarationStatus] = useState('');
  const [morgtage_status, setMortgageStatus] = useState('');
  const [vehicle_details, setVehicleDetails] = useState('');
  const [show_loan_info_data, setShowLoanInfoData] = useState(true);
  const [show_health_declaration_data, setHealthDeclarationData] = useState(true);
  const [show_mortgage_data, setMortgage] = useState(true);
  const [show_vehicle_details_data, setVehicleDetailsData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [requestResponseList, setRequestResponseList] = useState(null);
  const [profileDataList, setProfileDataList] = useState(null);
  const [productDetailsResponseList, setProductDetailsResponseList] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const legal_number_from_api = Cookies.get('legal_number');
  const bankAccountNumberLocally = Cookies.get('bank_account_number_locally');

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      await fetchRequestDetailsApi();
      await fetchProductDetailsApi();
    };

    fetchData();
    setIsLoading(false);

    if (!bankAccountNumberLocally.startsWith('15') || loan_info_status === 2) {
      setShowLoanInfoData(false);
    }
    if (health_declaration_status === 2) {
      setHealthDeclarationData(false);
    }
    if (morgtage_status === 2) {
      setMortgage(false);
    }
    if (vehicle_details === 2) {
      setVehicleDetailsData(false);
    }
  }, [
    loan_info_status,
    health_declaration_status,
    morgtage_status,
    vehicle_details,
  ]);


  const fetchRequestDetailsApi = async () => {
    try {
      const requestDetailsResponse = await api.get(
        `/requests/details/api/${productIndex}/`
      );
      if (requestDetailsResponse.status === 200) {
        setRequestResponseList(requestDetailsResponse.data.requestz);
        Cookies.set('productId', requestDetailsResponse.data.requestz.product);
      } else if (requestDetailsResponse.status === 403) {
        setErrorMessage(
          'You do not have permissions to view other the requests of the others!'
        );
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(
        'You do not have permissions to view other the requests of the others!'
      );
      setIsLoading(false);
    }
  };

  const fetchProductDetailsApi = async () => {
    let productId = Cookies.get('productId');

    try {
      const productDetailsResponse = await api.get(
        `/products/details/api/${productId}/`
      );
      if (productDetailsResponse.status === 200) {
        setProductDetailsResponseList(productDetailsResponse.data);
      }

      const loanInfo = productDetailsResponse.data.loan_info;
      const healthDeclaration = productDetailsResponse.data.health_statement;
      const mortgageStatus = productDetailsResponse.data.mortgage;
      const vehicleDetails = productDetailsResponse.data.vehicle_details;

      setLoanInfoStatus(loanInfo);
      setHealthDeclarationStatus(healthDeclaration);
      setMortgageStatus(mortgageStatus);
      setVehicleDetails(vehicleDetails);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleBackArrowClick = () => {
    navigate(-1);
  };

  const fetchApiProfileData = useCallback(async () => {
    try {
      const response = await api.get(
        `/customers/${legal_number_from_api}/customer-api-detail-from-database`
      );
      if (response.status === 200) {
        setProfileDataList(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [legal_number_from_api]);

  useEffect(() => {
    fetchApiProfileData();
  }, [fetchApiProfileData]);

  const CircularProgressIndicator = () => {
    return (
      <div className='flex justify-center items-center'>
        <div
          style={{ borderColor: '#FFDD00' }}
          className='w-20 h-20 border-t-4 border-yellow-500 border-solid rounded-full animate-spin'></div>
      </div>
    );
  };

  useEffect(() => {
    fetchApiProfileData();
  }, [fetchApiProfileData]);

  return (
    <div className='overflow-hidden'>
      <div className='bg-[#222627] z-50 w-full'>
        <Navbar
          type='authentication'
          authType='loggedIn'
          landing='landing'
          selectedValue={selectedValue}
          handleSelectChange={handleSelectChange}
        />
      </div>
      <img
        src={Background}
        alt='Background'
        className='absolute w-full transform rotate-180 '
      />
      <div className='bg-[#f5f6fa] h-full'>
        <div className='lg:w-2/3 lg:mx-auto container'>
          {productDetailsResponseList ? (
            <div className='flex flex-column pt-4  container mx-auto'>
              <button
                onClick={handleBackArrowClick}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}>
                <img alt='Arrow' className='pl-4 md:pl-8 lg:pl-0 pr-2' src={Arrow} />
              </button>
              <h1 className='text-[24px] text-left pt-2 md:pt-0 md:text-[32px] font-semibold text-[#171d25]'>
                {productDetailsResponseList.name}
              </h1>
            </div>
          ) : (
            <CircularProgressIndicator />
          )}
          {isLoading ? (
            <CircularProgressIndicator /> // Show loading indicator while data is being fetched
          ) : (
            <div className='pb-[200px]'>
              <div className='bg-white shadow-xl  pl-10 overflow-auto mx-[5%] lg:mx-0 py-10 mt-10 rounded-xl relative'>
                {errorMessage && (
                  <p className='text-sm font-medium text-red-700'>
                    {errorMessage}
                  </p>
                )}
                <div className='bg-[#f5f6fa] lg:w-6/7 w-11/12 h-[64px] right-2 md:right-0 relative rounded-lg flex mb-6'>
                  <img
                    src={InfoLogo}
                    alt='InfoLogo'
                    className='absolute inset-y-0 left-4 flex items-center mt-3'
                  />
                  <p className='text-[#212b36] font-regular absolute inset-y-0 left-16 text-[8px] md:text-xs lg:text-base flex items-center'>
                    {t('data_automatically_collected_from_bank')}
                  </p>
                </div>
                <div className=''>
                  <p className='text-[#171d25] font-semibold text-[14px] pb-4  md:text-[22px] md:pb-[31px]'>
                    {' '}
                    {t('insured')}{' '}
                  </p>
                  <table className=''>
                    <thead>
                      <tr>
                        <th className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap flex'>
                          {t('name')}
                        </th>
                        <th className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                          {t('personal_number')}
                        </th>
                        {/* <th className="px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] whitespace-nowrap text-left">{t('business_number')}</th> */}
                        <th className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                          {t('phone')}
                        </th>
                        <th className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                          Email
                        </th>
                        <th className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                          {t('adres')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {profileDataList ? (
                        <tr>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] whitespace-nowrap font-medium flex'>
                            {profileDataList.first_name}{' '}
                            {profileDataList.last_name}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] whitespace-nowrap font-medium text-left'>
                            {profileDataList.legal_number}
                          </td>
                          {/* <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] whitespace-nowrap font-medium text-left">{business_number}</td> */}
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] whitespace-nowrap font-medium text-left'>
                            {profileDataList.phone}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] whitespace-nowrap font-medium text-left'>
                            {profileDataList.email}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] whitespace-nowrap font-medium text-left'>
                            {profileDataList.address}
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td
                            colSpan='5'
                            className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] whitespace-nowrap text-center'>
                            <CircularProgressIndicator />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {show_loan_info_data && (
                    <div>
                      <p className='text-[#171d25] font-semibold text-[14px] md:text-[22px] pt-10 pb-[24px]'>
                        {t('loan_agreement')}
                      </p>
                      <table className=''>
                        <thead>
                          <tr>
                            <th className='px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('loan_id')}
                            </th>
                            <th className='px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('initial_amount')}
                            </th>
                            <th className='px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('unpaid_amount')}
                            </th>
                            <th className='px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('period')}
                            </th>
                            {/* <th className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">SFE</th> */}
                            <th className='px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('branch_code')}
                            </th>
                            <th className='px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('target_code')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              ---
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              ---
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              ---
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              ---
                            </td>
                            {/* <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left">SFE</td> */}
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              ---
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              ---
                            </td>
                            {/* <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left">---</td> */}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>

                {/* <div>
                                <p className='text-[#171d25] font-semibold text-[14px] md:text-[22px] pt-8 md:pt-16 pb-4 md:pb-[31px]'>{t('benefficiary')}</p>
                                <table className="">
                                    <tbody>
                                        <tr>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('name')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('personal_number')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('business_number')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('phone')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('city')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('adres')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left"></td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left">Ambra Kelmendi</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left">---</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left">---</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left">044 123 123</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left">{t('prishtin')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left">{t('street')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left"></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className='text-[#171d25] font-semibold text-[14px] md:text-[22px] pt-10 pb-[24px]'>{t('loan_agreement')}</p>
                                <table className="">
                                    <tbody>
                                        <tr>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('loan_id')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('initial_amount')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('unpaid_amount')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('period')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">SFE</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('branch_code')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left">{t('target_code')}</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm font-regular text-[#b0bac9] whitespace-nowrap text-left"></td>
                                        </tr>
                                        <tr>
                                            <td className="px-4 py-2 text-[10px] md:text-sm  text-[#0f1d31] font-medium whitespace-nowrap text-left">---</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm  text-[#0f1d31] font-medium whitespace-nowrap text-left">0.00 €</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm  text-[#0f1d31] font-medium whitespace-nowrap text-left">0.00 €</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm  text-[#0f1d31] font-medium whitespace-nowrap text-left">02/04/2021 - 02/04/2022</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm  text-[#0f1d31] font-medium whitespace-nowrap text-left">SFE</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm  text-[#0f1d31] font-medium whitespace-nowrap text-left">0000</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm  text-[#0f1d31] font-medium whitespace-nowrap text-left">0000</td>
                                            <td className="px-4 py-2 text-[10px] md:text-sm  text-[#0f1d31] font-medium whitespace-nowrap text-left"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> */}
              </div>
              {show_vehicle_details_data && (
                <div className='bg-white pl-10  shadow-xl  overflow-auto mx-[5%] lg:mx-0 py-10 mt-10 rounded-xl relative'>
                  {requestResponseList ? (
                    <div>
                      <p className='text-[#171d25] font-semibold text-[14px] md:text-[22px] pb-4 md:pb-[31px]'>
                        {t('vehicle_details')}
                      </p>
                      <table className=''>
                        <tbody>
                          <tr>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                              {t('vin_number')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                              {t('plate_number')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                              {t('vehicle_category')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                              {t('manufacturer')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                              {t('model')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                              {t('technical_details')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                              {t('purpose_of_usage')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                              {t('production_year')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                              {t('coverage_tetrriory')}
                            </td>
                          </tr>
                          <tr>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.vin_number}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.plate_number}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.vehicle_category === 1
                                ? 'Sedan'
                                : requestResponseList.vehicle_category === 2
                                ? 'Coupe'
                                : requestResponseList.vehicle_category === 3
                                ? 'Wagon'
                                : requestResponseList.vehicle_category === 4
                                ? 'Hatchback'
                                : requestResponseList.vehicle_category === 5
                                ? 'Convertible'
                                : requestResponseList.vehicle_category === 6
                                ? 'SUV'
                                : requestResponseList.vehicle_category === 7
                                ? 'Minivan'
                                : requestResponseList.vehicle_category === 8
                                ? 'Pickup'
                                : requestResponseList.vehicle_category === 9
                                ? 'Motorcycle'
                                : requestResponseList.vehicle_category === 10
                                ? 'Van'
                                : requestResponseList.vehicle_category === 11
                                ? 'Bus'
                                : requestResponseList.vehicle_category === 12
                                ? 'Truck'
                                : '-'}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.vehicle_brand}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.model}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.technical_details}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.purpose_usage === 1
                                ? t('personal')
                                : requestResponseList.purpose_usage === 2
                                ? t('work')
                                : requestResponseList.purpose_usage === 3
                                ? t('transport')
                                : '-'}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.production_year}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.coverage_territory === 1
                                ? t('kosovo')
                                : requestResponseList.coverage_territory === 2
                                ? t('region')
                                : requestResponseList.coverage_territory === 3
                                ? t('europe')
                                : '-'}
                            </td>
                          </tr>
                          <tr className='pt-10'>
                            <td className='text-sm pl-4 font-semibold text-[#b0bac9]'>
                              {t('note')}
                            </td>
                          </tr>
                          <tr>
                          <td
                              colSpan='4'
                              className='text-sm text-[#0f1d31] font-medium pl-4 pt-1'>
                              {requestResponseList.vehicle_notes}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <CircularProgressIndicator />
                  )}
                </div>
              )}
              <div className='bg-white mx-[5%] lg:mx-0 shadow-xl   overflow-x-auto pl-10 py-10 mt-10 rounded-xl relative'>
                {requestResponseList ? (
                  <div className='container'>
                    <p className='text-[#171d25] font-semibold text-[14px] md:text-[22px] pb-4 md:pb-[31px]'>
                      {t('security_details')}
                    </p>
                    <table className=''>
                      <tbody>
                        <tr>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                            {t('policy_date')}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                            {t('insurance_period')}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                            {t('first_payment')}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                            {t('payment_method')}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                            {t('insurance_amount')}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                            {t('number_of_installments')}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                            {t('payment_method')}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                            {t('insurance_amount')}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#b0bac9] font-regular whitespace-nowrap text-left'>
                            {t('number_of_installments')}
                          </td>
                        </tr>
                        <tr>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                            {requestResponseList.policy_date}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                            {requestResponseList.insurance_period_from} -{' '}
                            {requestResponseList.insurance_period_to}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                            {requestResponseList.first_payment_date}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                            {requestResponseList.payment_method === 1
                              ? t('monthly')
                              : requestResponseList.payment_method === 2
                              ? t('yearly')
                              : 'Online'}
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                            {requestResponseList.sum_insured} €
                          </td>
                          <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                            {requestResponseList.number_instalments}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <CircularProgressIndicator />
                )}
              </div>

              {show_health_declaration_data && (
                <div className='bg-white pl-10 shadow-xl  overflow-auto py-10 mt-10 mx-[5%] lg:mx-0 rounded-xl relative'>
                  {requestResponseList ? (
                    <div>
                      <p className='text-[#171d25] font-semibold text-[14px]  md:text-[22px] pb-4 md:pb-[31px]'>
                        {t('health_declaration')}
                      </p>
                      <table className=''>
                        <tbody>
                          <tr>
                            <td className='px-4 py-2 font-regular text-[10px] md:text-sm text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('health_problems')}
                            </td>
                            <td className='px-4 py-2 font-regular text-[10px] md:text-sm text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('health_problems_in_last_five_years')}
                            </td>
                            <td className='px-4 py-2 font-regular text-[10px] md:text-sm text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('critical_illnesses')}
                            </td>
                            <td className='px-4 py-2 font-regular text-[10px] md:text-sm text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('disabilities')}
                            </td>
                          </tr>
                          <tr>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.health_problems === 1
                                ? t('yes')
                                : t('no')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.health_problems_last_five_years ===
                              1
                                ? t('yes')
                                : t('no')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.critical_diseases === 1
                                ? t('yes')
                                : t('no')}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.disabilities === 1
                                ? t('yes')
                                : t('no')}
                            </td>
                          </tr>
                          <tr className='pt-10'>
                            <td className='text-sm font-semibold pl-4 text-[#b0bac9]'>
                              {t('note')}
                            </td>
                          </tr>
                          <tr>
                          <td
                              colSpan='3'
                              className='text-sm pl-4 font-medium  text-[#0f1d31] pt-1'>
                              {requestResponseList.health_declaration_notes}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <CircularProgressIndicator />
                  )}
                </div>
              )}
              {show_mortgage_data && (
                <div className='bg-white mx-[5%] lg:mx-0 shadow-xl  overflow-auto pl-10 py-10 mt-10 rounded-xl relative'>
                  {requestResponseList ? (
                    <div>
                      <p className='text-[#171d25] font-semibold text-[22px] pb-[31px]'>
                        {t('mortgage_data')}
                      </p>
                      <table className=''>
                        <tbody>
                          <tr>
                            <td className='px-4 py-2 font-regular text-[10px] md:text-sm text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('aps_number')}
                            </td>
                            <td className='px-4 py-2 font-regular text-[10px] md:text-sm text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('name_insured_property')}
                            </td>
                            <td className='px-4 py-2 font-regular text-[10px] md:text-sm text-[#b0bac9] whitespace-nowrap text-left'>
                              {t('insured_property')}
                            </td>
                          </tr>
                          <tr>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.aps_insured_number}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.name_property_insured}{' '}
                            </td>
                            <td className='px-4 py-2 text-[10px] md:text-sm text-[#0f1d31] font-medium whitespace-nowrap text-left'>
                              {requestResponseList.insured_property_address}
                            </td>
                          </tr>
                          <tr className='pt-10'>
                            <td className='text-sm text-[#b0bac9] pl-4 font-semibold'>
                              {t('note')}
                            </td>
                          </tr>
                          <tr>
                          <td
                              colSpan='2'
                              className='text-sm font-medium pl-4 text-[#0f1d31] pt-1'>
                              {requestResponseList.mortgage_data_notes}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <CircularProgressIndicator />
                  )}
                </div>
              )}

              {/* <div className='bg-white pl-10 py-6 mt-10 rounded-xl mx-[5%] lg:mx-0 relative'>
                            <div>
                                <p className='text-[#171d25] font-semibold text-[22px] pb-2 '> {t('documents')} </p>
                            </div>
                            <div className="">
                                {files.map((file, index) => (
                                    <div
                                        key={file.name} className="flex flex-columns bg-white max-w-[300px] py-3 px-3  border-[1px] border-[#e5e9f2] rounded-[3px]">
                                        <p className="text-sm whitespace-wrap">{file.name}</p>
                                        <p className="text-[#b0bac9] text-xs">
                                            {file.size}
                                        </p>
                                        <div className="ml-auto pl-5">
                                            <button
                                                className="" onClick={() => handleFileDelete(index)}>
                                                <img src={XButton} alt="x-button" className="mx-auto w-[21px] px-1 py-1 h-[21px] bg-[#f8fafc] my-3 right-0" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div> */}
            </div>
          )}
        </div>
      </div>
      <div className='relative bg-[#fff] bottom-0 container mx-auto'>
        <Footer landing='landing' />
      </div>
    </div>
  );
};

export default ProductDetails;
