import React, { useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import { useTranslation } from 'react-i18next';
import XButton from '../../assets/icons/x-icon.svg';
import PersonalNumber from '../../assets/icons/PersonalNumber.png';
import Calendar from '../../assets/icons/calendar-icon.svg';
import PolicyNumber from '../../assets/icons/policy-number.svg';
import InfoLogo from '../../assets/icons/info-icon.png';
import Footer from '../../components/footer/Footer';

const ReportedClaim = () => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');
  const [files, setFiles] = useState([]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const newFiles = Array.from(selectedFiles).map((file) => {
      const fileType = file.type;
      const fileSize = file.size;
      let sizeInBytes = fileSize;
      let sizeInKb = (fileSize / 1024).toFixed(2);
      let sizeInMb = (fileSize / (1024 * 1024)).toFixed(2);
      let size;
      if (fileSize < 1024) {
        size = `${sizeInBytes} bytes`;
      } else if (fileSize >= 1024 && fileSize < 1024 * 1024) {
        size = `${sizeInKb} KB`;
      } else if (fileSize >= 1024 * 1024) {
        size = `${sizeInMb} MB`;
      }
      return {
        file,
        name: file.name,
        size,
        type: fileType,
      };
    });
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDelete = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  return (
    <div className='overflow-hidden bg-[#f5f6fa]'>
      <div className='bg-white fixed w-full shadow-md z-50'>
        <Navbar
          type='authentication'
          authType='loggedIn'
          selectedValue={selectedValue}
          handleSelectChange={handleSelectChange}
        />
      </div>
        <div className='space-y-8 container'>
          <div className='bg-white shadow-xl rounded-xl w-11/12 p-8 mt-36'>
            <div className='flex '>
              <div className='flex-grow'>
                <p className='font-semibold text-[22px] text-[#20253a]'>
                  {t('claim')} 119896
                </p>
                <p className='font-medium text-base text-[#838c9b]'>
                  20/02/2023
                </p>
              </div>
              <div className=''>
                <button className='text-[#7cb8b2] hover:bg-[#e5eeec] bg-[#f0f8f6] px-[22px] py-[11px] rounded-lg font-medium text-[15px] mr-4'>
                  {t('confirm')}
                </button>
                <button className='bg-[#fbefef] hover:bg-[#ede0e0] text-[#da6262] px-[22px] py-[11px] rounded-lg text-[15px]'>
                  {t('reject_complain')}
                </button>
              </div>
            </div>
            <p className='text-[14px] text-[#0f1d31] w-5/6 font-sans leading-tight py-6'>
              Molestie ac purus eget vitae vestibulum eget risus viverra. Turpis
              tortor nisl felis risus cursus magna. Viverra mi ullamcorper
              pellentesque nunc nibh habitasse donec eu. In nisi ornare
              elementum viverra velit dis nibh.
            </p>
            <div>
              <p className='text-[#838c9b] text-sm mb-2.5'>{t('documents')}</p>
              <div className='flex'>
                <button className=''>
                  <div className='flex border border-[#e5e9f2] py-2.5 px-4 rounded-md'>
                    <div className='text-xs pr-2'>Document.pdf</div>
                    <svg
                      className='download-icon'
                      width='12'
                      height='18'
                      viewBox='0 0 18 22'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        className='download-arrow'
                        d='M13 9L9 13M9 13L5 9M9 13V1'
                        stroke='#333333'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M1 17V18C1 18.7956 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H14C14.7956 21 15.5587 20.6839 16.1213 20.1213C16.6839 19.5587 17 18.7956 17 18V17'
                        stroke='#333333'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className='bg-white shadow-xl rounded-xl w-11/12 p-8'>
            <div className='flex '>
              <div className='flex-grow'>
                <p className='font-semibold text-[22px] text-[#20253a]'>
                  {t('obverse')}
                </p>
              </div>
            </div>

            <div className='mt-4'>
              <label
                htmlFor='message'
                className='text-base text-[#0f1e2f] leading-6 font-medium'>
                {' '}
                {t('note')}{' '}
              </label>
              <div>
                <textarea
                  type='text'
                  id='message'
                  rows='2'
                  className='w-full px-4 py-[14px] mt-2 bg-[#f7f8fb] text-[#0f1e2f] text-[15px] leading-6 rounded-md'
                  placeholder={t('note')}></textarea>
              </div>
            </div>
            <div>
              <p className='text-[#0f1e2f] text-sm mb-2.5'>{t('documents')}</p>

              <label
                htmlFor='myFile'
                className='custom-file-upload block text-xs font-bold text-[#0f1d31]'>
                <div className='my-3 max-w-[154px] py-3 px-3 border-[1px] border-[#86b1ad] border-dashed rounded-[3px] cursor-pointer bg-[#f7f8f9] text-[#86b1ad]'>
                  {t('upload_documents')}
                </div>
                <input
                  type='file'
                  id='myFile'
                  name='myFile'
                  onChange={handleFileChange}
                  hidden
                  multiple
                />
              </label>
            </div>
            <div className='flex flex-wrap -mx-4 gap-4 pl-3'>
              {files.map((file, index) => (
                <div
                  key={file.name}
                  className='flex border border-[#e5e9f2] py-2.5 px-4 rounded-md'>
                  <div>
                    <p className='text-sm whitespace-wrap'>{file.name}</p>
                    <p className='text-[#b0bac9] text-xs'>{file.size}</p>
                  </div>
                  <div className='ml-auto pl-5'>
                    <button
                      className=''
                      onClick={() => handleFileDelete(index)}>
                      <img
                        src={XButton}
                        alt='x-button'
                        className=' w-[21px] px-1 h-[21px] ml-4'
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='bg-white shadow-xl rounded-xl w-11/12 p-8'>
            <div className='flex '>
              <div className='flex-grow'>
                <p className='font-semibold text-[22px] text-[#20253a]'>
                  {t('police_data')}
                </p>
              </div>
              <div className=''>
                <button className='bg-[#eeeeee] hover:bg-[#f4f4f4] text-[#333333] px-[22px] py-[6px] rounded-md text-[14px]'>
                  {t('details')}
                </button>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-9 mt-12'>
              <div className='flex flex-rows'>
                <img src={PolicyNumber} className='w-11 h-11' alt='' />
                <div className='ml-2'>
                  <p className='text-[#313a3c] font-semibold'>
                    12345678
                    <br />
                    <span className='text-[#979797] font-normal text-[14px]'>
                      {t('policy_number')}
                    </span>
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <img src={PersonalNumber} className='w-11 h-11' alt='' />
                <div className='ml-2'>
                  <p className='text-[#313a3c] font-semibold'>
                    Sigal
                    <br />
                    <span className='text-[#979797] font-normal text-[14px]'>
                      {t('insurance_company')}
                    </span>
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <img src={PersonalNumber} className='w-11 h-11' alt='' />
                <div className='ml-2'>
                  <p className='text-[#313a3c] font-semibold'>
                    Ambra Kelmedi
                    <br />
                    <span className='text-[#979797] font-normal text-[14px]'>
                      {t('insured')}
                    </span>
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <img src={Calendar} className='w-11 h-11' alt='' />
                <div className='ml-2'>
                  <p className='text-[#313a3c] font-semibold'>
                    20/03/2023 - 20/03/2024
                    <br />
                    <span className='text-[#979797] font-normal text-[14px]'>
                      {t('insurance_period')}
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <p className='text-[#838c9b] text-sm mb-2.5'>
                  {t('documents')}
                </p>
                <div className='flex'>
                  <button className=''>
                    <div className='flex border border-[#e5e9f2] py-2.5 px-4 rounded-md'>
                      <div className='text-xs pr-2'>Document.pdf</div>
                      <svg
                        className='download-icon'
                        width='12'
                        height='18'
                        viewBox='0 0 18 22'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          className='download-arrow'
                          d='M13 9L9 13M9 13L5 9M9 13V1'
                          stroke='#333333'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M1 17V18C1 18.7956 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H14C14.7956 21 15.5587 20.6839 16.1213 20.1213C16.6839 19.5587 17 18.7956 17 18V17'
                          stroke='#333333'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='bg-white shadow-xl rounded-xl w-11/12 p-8'>
            <div className='flex '>
              <div className='flex-grow'>
                <p className='font-semibold text-[22px] text-[#20253a]'>
                  {t('insured')}
                </p>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5  mt-8'>
              <div className='flex flex-rows'>
                <div className=''>
                  <span className='text-[#979797] text-[14px]'>
                    {t('personal_number')}
                  </span>
                  <p className='text-[#313a3c] font-bold text-[14px]'>
                    12345678
                    <br />
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <div className=''>
                  <span className='text-[#979797] text-[14px]'>
                    {t('name')}
                  </span>
                  <p className='text-[#313a3c] font-bold text-[14px]'>
                    Alba Kelmendi
                    <br />
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <div className=''>
                  <span className='text-[#979797] text-[14px]'>
                    {t('phone_number')}
                  </span>
                  <p className='text-[#313a3c] font-bold text-[14px]'>
                    044 123 456
                    <br />
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <div className=''>
                  <span className='text-[#979797] text-[14px]'>
                    {t('city')}
                  </span>
                  <p className='text-[#313a3c] font-bold text-[14px]'>
                    Prishtine
                    <br />
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <div className=''>
                  <span className='text-[#979797] text-[14px]'>
                    {t('adres')}
                  </span>
                  <p className='text-[#313a3c] font-bold text-[14px]'>
                    Rr Rexhep Krasniqi
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className='bg-[#f5f6fa]  h-[64px] relative rounded-xl mt-8'>
              <img
                src={InfoLogo}
                alt='InfoLogo'
                className='absolute inset-y-0 left-4 flex items-center mt-3'></img>
              <p className='text-[#212b36] absolute text-base font-medium leading-4 inset-y-0 left-16 flex items-center'>
                {t('data_automatically_collected_from_bank')}
              </p>
            </div>
            <div className='flex '>
              <div className='flex-grow'>
                <p className='font-semibold text-[22px] text-[#20253a] mt-16'>
                 {t('claim_data')}
                </p>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5  mt-8'>
              <div className='flex flex-rows'>
                <div className=''>
                  <span className='text-[#979797] text-[14px]'>
                    {t('the_date_of_story')}
                  </span>
                  <p className='text-[#313a3c] font-bold text-[14px]'>
                    20/03/2023
                    <br />
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <div className=''>
                  <span className='text-[#979797] text-[14px]'>
                    {t('invoice_number')}
                  </span>
                  <p className='text-[#313a3c] font-bold text-[14px]'>
                    123456789
                    <br />
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <div className=''>
                  <span className='text-[#979797] text-[14px]'>
                    {t('head_of_service')}
                  </span>
                  <p className='text-[#313a3c] font-bold text-[14px]'>
                    Alba Kelmendi
                    <br />
                  </p>
                </div>
              </div>
              <div className='flex flex-rows'>
                <div className=''>
                  <span className='text-[#979797] text-[14px]'>
                    {t('value')}
                  </span>
                  <p className='text-[#313a3c] font-bold text-[14px]'>
                    0.00 €
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className='flex flex-rows mt-8'>
              <div className=''>
                <span className='text-[#979797] text-[14px] '>
                  {t('description')}
                </span>
                <p className='text-[#313a3c] font-bold text-[14px] mt-3 leading-tight w-11/12'>
                  Molestie ac purus eget vitae vestibulum eget risus viverra.
                  Turpis tortor nisl felis risus cursus magna. Viverra mi
                  ullamcorper pellentesque nunc nibh habitasse donec eu. In nisi
                  ornare elementum viverra velit dis nibh.
                </p>
              </div>
            </div>
            <div>
              <div className='flex '>
                <div className='flex-grow'>
                  <p className='font-semibold text-[22px] text-[#20253a] mt-16'>
                    {t('documents')}
                  </p>
                </div>
              </div>
              <div className='flex mt-6'>
                <button className=''>
                  <div className='flex border border-[#e5e9f2] py-2.5 px-4 rounded-md'>
                    <div className='text-xs pr-2'>Document.pdf</div>
                    <svg
                      className='download-icon'
                      width='12'
                      height='18'
                      viewBox='0 0 18 22'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        className='download-arrow'
                        d='M13 9L9 13M9 13L5 9M9 13V1'
                        stroke='#333333'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M1 17V18C1 18.7956 1.31607 19.5587 1.87868 20.1213C2.44129 20.6839 3.20435 21 4 21H14C14.7956 21 15.5587 20.6839 16.1213 20.1213C16.6839 19.5587 17 18.7956 17 18V17'
                        stroke='#333333'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className='bg-white shadow-xl rounded-xl w-11/12 p-8'>
            <div className='flex mb-10'>
              <div className='flex-grow'>
                <p className='font-semibold text-[22px] text-[#20253a]'>
                  {t('product_information')}
                </p>
              </div>
            </div>
            <div>
              <div className='bg-white border border-[#f3f4f5] shadow-lg rounded-xl px-10 py-8 mb-8'>
                <p className='text-lg md:text-2xl font-bold pb-3'>
                  Mbrojtje nga dëmet materiale
                </p>
                <p className='text-[#171d25] text-base'>
                  Sigurimi kasko do të mbulojë dëmet materiale të automjetit
                  tuaj në raste të caktuara, siç janë aksidentet, vandalismi,
                  grabitjet, etj. Kjo do të ndihmojë të shmangni shpenzimet e
                  mëdha që mund të lidhen me riparimin ose zëvendësimin e
                  automjetit tuaj.
                </p>
              </div>

              <div className='bg-white border border-[#f3f4f5] shadow-lg rounded-xl px-10 py-8 '>
                <p className='text-lg md:text-2xl font-bold pb-3'>
                  Mbrojtje nga dëmet materiale
                </p>
                <p className='text-[#171d25] text-base'>
                  Sigurimi kasko do të mbulojë dëmet materiale të automjetit
                  tuaj në raste të caktuara, siç janë aksidentet, vandalismi,
                  grabitjet, etj. Kjo do të ndihmojë të shmangni shpenzimet e
                  mëdha që mund të lidhen me riparimin ose zëvendësimin e
                  automjetit tuaj.
                </p>
              </div>
            </div>
          </div>
        </div>

      <div className='relative bottom-0 pt-16'>
        <Footer landing='landing' />
      </div>
    </div>
  );
};

export default ReportedClaim;
