import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Background from "../../assets/images/raiffeisen-bg.png";
import InsurexLogoX from "../../assets/images/insurex-logo-x-green.png";
import Success from '../../assets/icons/success-icon.svg';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import api from '../../services/api';

const Register = () => {
  const navigate = useNavigate();

  const [agree, setAgree] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorTest, setErrorTest] = useState('');
  const [personal_number, setPersonalNumber] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailSendSuccessfully, setEmailSendSuccessfully] = useState(false);
  const [smsSendSuccessfully, setSmsSendSuccessfully] = useState(false);

  const [hashedEmail, setHashedEmail] = useState('');
  const [hashedPhoneNumber, setHashedPhoneNumber] = useState('');
  const [warningFromApiCall, setWarningFromApiCall] = useState('');



  const rbClietRegister = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    if (!agree) {
      setShowError(true);
      return;
    }

    try {
      const rbkoRegisterResponse = await api.post(`/internal-users/api/rbko-customer/register/${personal_number}/`);
      if (rbkoRegisterResponse.status === 201) {
        setHashedEmail(rbkoRegisterResponse.data.email);
        setHashedPhoneNumber(rbkoRegisterResponse.data.phone);
        setWarningFromApiCall(rbkoRegisterResponse.data.Warning);
        console.log("Warning " + rbkoRegisterResponse.data.Warning);

        setEmailSendSuccessfully(true);
        setShowSuccess(true);
      }
      else if (rbkoRegisterResponse.status === 500) {
        setEmailSendSuccessfully(true);
        setShowSuccess(true);
        setErrorTest('Problems while creating account!')
      }

    } catch (error) {
      setErrorTest(error.message);
      console.error('erroriiiii' + error);
    }
    setIsLoading(false);
  }


  //create circular progress indicator
  const YourCircularIndicatorComponent = () => {
    return (
      <div className="flex justify-center items-center">
        <div style={{ borderColor: '#FFDD00' }} className="w-6 h-6 border-t-4 border-yellow-500 border-solid rounded-full animate-spin"></div>
      </div>
    );
  };


  const checkboxHandler = () => {
    setAgree(!agree);
    setShowError(false);
  };

  const handleButtonClick = (clickEvent) => {
    // setShowSuccess(true);
    if (agree) {
      rbClietRegister(clickEvent);
    } else {
      // setShowError(true);
    }
  };
  const handleSuccessClose = () => {
    setShowSuccess(false);
  };
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('al');

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="">
      <img src={Background} alt="" className="bg-[#726f6f] fixed w-full h-full object-cover overflow-hidden" />
      <div className="relative">
        <Navbar type="authentication" landing="landing" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
      </div>
      <div className="md:flex md:flex-col items-center justify-center px-6 md:py-6 mx-auto lg:py-0 relative ">
        <img className=" pb-4 mx-auto" src={InsurexLogoX} alt="" />
        <p className="text-[#9b9b9b] pb-8 text-sm font-regular text-center">{t('insurance_platform')}</p>
        <form onSubmit={rbClietRegister}>
          <div className="bg-white mt-8 md:mt-8 lg:mt-2 rounded-md mb-10 px-[30px] md:px-[56px] ">
            <div className="flex font-bold pt-8 justify-center items-center">
              <h1 className="text-md md:text-xl font-bold"> {t('set_up')} </h1>
            </div>
            <p className="text-center text-xs  md:text-[16px] pt-2 font-medium"> {t('are_you_a_client')} </p>
            <div className="flex justify-center  space-x-8 pt-4 pb-4">
              <div className="flex items-center">
                <Link to="/register">
                  <input
                    className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] checked:bg-black cursor-pointer mt-1"
                    type="radio"
                    name="pep"
                    value="pepNo"
                    checked
                  />
                </Link>
                <label
                  htmlFor="inline-radio"
                  className="ml-2 text-[13px] font-medium text-[#0f1d31] mt-[-3.5px]"
                >
                  {t('yes')}
                </label>
              </div>
              <div className="flex items-center">
                <Link to="/nonrbclient-register">
                  <input
                    className="bg-[#f8fafc] accent-[#222b45] pl-2 rounded-full w-[16px] h-[16px] filled cursor-pointer  mt-1"
                    type="radio"
                    name="pep"
                    value="pepYes"
                  />
                </Link>
                <label
                  htmlFor="inline-radio"
                  className=" ml-2 text-[13px] font-medium text-[#0f1d31] mt-[-3.5px]"
                >
                  {t('no')}
                </label>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <input className="text-[#0f1d31] bg-[#f5f6fa] p-3 w-full md:w-[380px] h-[52px] font-regular text-xs md:text-sm"
                onChange={(event) => setPersonalNumber(event.target.value)}
                value={personal_number}
                type="text" placeholder={t('personal_number')} id="legal_number"
                name="legal_number" />
            </div>
            <div className="flex items-center md:mt-2 ml-1">
              <input
                onChange={checkboxHandler}
                id="agree"
                type="checkbox"
                value=""
                name="remember"
                className="h-4 w-4 mt-1 text-[#313a3c] border-gray-300 rounded accent-yellow-300"
              />
              <label htmlFor="link-checkbox" className="ml-2 mt-6 font-medium text-sm text-[#313a3c] pt-4 md:pt-0 tracking-tighter">
                {t('completing_steps')}
                <br className="hidden md:inline"></br>
                <span className="underline font-bold  mx-1">
                  <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">{t('terms_of_use')}</Link>
                </span>
                {t('and')}
                <span className="underline font-bold  mx-1">
                  <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer"> {t('privacy')} </Link>
                </span>
                .
              </label>
            </div>

            {showError && (
              <p className="text-[#ff0000] mb-2 text-xs flex tracking-tighter font-regular mt-2">
                {t('accept_terms_use_and_privacy_policy')} <br></br> {t('and_privacy_policy')}
              </p>
            )}
            {errorTest && <p className="text-[] mb-2 text-sm font-semibold flex ml-[9%]">{errorTest}</p>}
            <div className="flex justify-center items-center">
              <button
                onClick={handleButtonClick}
                type="submit"
                className="focus:outline-none text-[#fff] bg-[#313a3c] hover:bg-[#374151] font-bold rounded-md text-sm  mx-auto py-4 mb-2 w-full md:w-[380px] uppercase mt-10">
                {isLoading ? <YourCircularIndicatorComponent /> : "Regjistrohu"}
              </button>
            </div>
            {showSuccess && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 pt-40">
                <div className="bg-white rounded-md p-12">
                  <img src={Success} alt="" className='mx-auto my-auto block mb-4' />
                  <h1 className="mb-4 font-semibold text-[24px] text-center text-[#313a3c]"> {t('wiht_success')} </h1>
                  <p className="text-center text-sm font-regular pt-1"> {t('data_has_been_sent')} </p>
                  {emailSendSuccessfully && (<div className=" text-center">
                    {/* <p className="text-center text-sm font-regular pt-1"> {t('to_email')} </p> */}
                    <p className="text-center text-sm font-regular text-[#657679] pl-1 pb-4 pt-1">{hashedEmail}.</p>
                    <p className="text-center text-sm font-regular text-[#FF0000] pl-1 pb-4 ">Warning: {warningFromApiCall}</p>
                  </div>)}
                  {smsSendSuccessfully && (<div className="flex flex-inline text-center">
                    <p className="text-center text-sm font-regular pl-[65px] pt-1"> {t('to_phone_number')} </p>
                    <p className="text-center text-sm font-regular text-[#657679] pl-1 pb-4 pt-1">{hashedPhoneNumber}.</p>
                  </div>)}
                  <Link to="/login">
                    <button type="button" className="w-[380px] h-[52px] rounded-md text-[#fff] center font-semibold uppercase bg-[#313a3c] hover:bg-[#374151] " onClick={handleSuccessClose}>
                      {t('continue')}
                    </button>
                  </Link>
                </div>
              </div>
            )}
            <Link to="/login">
              <div className="flex justify-center items-center">
                <button
                  type="submit"
                  className="focus:outline-none text-[#000] bg-[#fff]  hover:bg-[#d1d1d4] font-semibold rounded-md text-sm px-5 py-4 mb-6 w-full md:w-[380px] uppercase">
                  {t('log')}
                </button>
              </div>
            </Link>
          </div>
        </form>
      </div>
      <div className="fixed bottom-0 w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Register;
