// api.js
import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../config';

const api = axios.create({
    baseURL: `${config.API_BASE_URL}`,
    withCredentials: true,
});

// Request Interceptor
api.interceptors.request.use(config => {
    const token = Cookies.get('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Response Interceptor
api.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const refreshToken = Cookies.get("refresh_token");
        try {
            const response = await axios.post(`${config.API_BASE_URL}/users/api-token-refresh/`, {
                refresh: refreshToken,
            });

            Cookies.set("token", response.data.access, { expires: 1 });

            api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
            originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;

            return api(originalRequest);
        } catch (refreshError) {
            Cookies.remove("token");
            Cookies.remove("refresh_token");
            window.location.href = '/login';
            return Promise.reject(refreshError);
        }
    }

    return Promise.reject(error);
});

export default api;