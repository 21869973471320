import React,{useState,useEffect} from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'
import  Contactbg from '../../assets/images/Contact-bg.svg'
import Bg from "../../assets/images/raiffeisenbg.svg";
import MessageIcon from "../../assets/icons/message-icon.svg";
import PhoneIcon from "../../assets/icons/phone-icon.svg";
import LocationIcon from "../../assets/icons/location-icon.svg";
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie';

const Contact = () => {
    const {t} = useTranslation();
    const [selectedValue, setSelectedValue] = useState('al');
    const [isLoggedIn, setIsLoggedIn] = useState(false);

  
    const handleSelectChange = (event) => {
      setSelectedValue(event.target.value);
    };

    useEffect(()=>{
        const token = Cookie.get('token');
        if(token){
            setIsLoggedIn(true);
        }
        else{
            setIsLoggedIn(false);
        }
    })
    return (
        <div className='overflow-hidden'>
             {isLoggedIn ? (
                <div className="bg-white fixed w-full shadow-md z-50  ">
                    <Navbar type="authentication" authType="loggedIn" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
                </div>
            ) : (
                <div>
                    <Navbar selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
                </div>
            )}
            <div className="md:flex">
                <div className="md:w-1/2 h-[840px] bg-[#f5f6fa] "></div>
                <div className="md:w-1/2 h-[300px] md:h-[840px]  bg-[#161c24]">
                <div className="absolute flex">
                    <img src={Contactbg} alt="Contactbg" className="w-full hidden lg:block h-[840px]" />
                </div>
                <div className="absolute inset-0 px-6 md:px-0 md:flex mt-40 justify-center h-fit">
                    <div className='md:p-4 px-4 lg:px-8 lg:p-16 bg-white rounded-lg md:ml-4 lg:ml-0 lg:mb-28'>
                        <div>
                            <h1 className='text-2xl md:text-4xl text-[#171d25] lg:text-5xl leading-[64px] font-sans mb-8'>{t('contact_lowercasse')}</h1>
                            <p className='md:text-sm lg:text-base text-[#171d25] leading-6 font-regular  mb-8'> {t('questions_about_our_products')} </p>
                        </div>
                        <div className="md:grid md:grid-cols-2 gap-4 lg:gap-8">
                            <div>
                                <label htmlFor="name" className="text-base leading-6 font-medium text-[#171d25]"> {t('name_lastname')} </label>
                                <input type="text" id="name" placeholder={t('name_lastname')} className="mt-2 font-regular w-full px-4 py-[14px] bg-[#f7f8fb] text-[#0f1d31] text-[15px] leading-6"/>
                            </div>
                            <div className='mt-4 md:mt-0'>
                                <label htmlFor="surname" className="text-base leading-6 text-[#171d25] font-medium">Email</label>
                                <input type="email" id="name" placeholder='Email' className="mt-2 w-full px-4 py-[14px] font-regular bg-[#f7f8fb] text-[#0f1d31] text-[15px] leading-6"/>
                            </div>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="message" className="text-base leading-6 font-medium text-[#171d25]"> {t('message')} </label>
                            <textarea id="message" rows="6" className="w-full px-4 py-[14px] mt-2 bg-[#f7f8fb] font-regular resize-none max-h-full text-[#0f1d31] text-[15px] leading-6" placeholder={t('message')}></textarea>
                        </div>
                        <div>
                            <button type="submit" className="w-[124px] h-[46px] font-semibold mt-4 md:mt-6 text-[#171d25] mb-4 md:mb-0 rounded-md bg-[#f7ee00] hover:bg-[#fbf53a]">
                                {t('submit')}
                            </button>
                        </div>
                    </div>
                    <div className='ml-4 md:ml-[20px] lg:ml-[100px] items-center mt-44 md:mt-0 flex justify-center'>
                        <ul className="font-thin text-white ">
                            <li className="mb-10 flex items-center">
                                <img src={PhoneIcon} className="inline-block cursor-pointer pr-4" alt=""/>
                                <p className='md:text-sm lg:text-base font-regular text-[#ffffff]'> {t('mobile_number')} </p>
                            </li>
                            <li className="mb-10 flex items-center">
                                <img src={MessageIcon} className="inline-block cursor-pointer pr-4" alt="Messageicon"/>
                                <a href="https://mail.google.com/mail/u/0/?pli=1#inbox?compose=GTvVlcSDbSMrTKQTBpTnKCGpcDXHSxkfkgDcbFfhbptrtPdHJpNMmvxfBSmsCdLTzPxKzWqRwjBSF" className="hover:underline text-[#ffffff] font-regular text-base" target="_blank" rel="noopener noreferrer">
                                    Email: info.broker@raiffeisen-kosovo.com
                                </a>                            
                            </li>
                            <li className="flex items-center">
                                <img src={LocationIcon} className="inline-block cursor-pointer text-[#ffffff] pr-4" alt="Locationicon"/>
                                <a href="https://www.google.com/maps/place/Raiffeisen+Bank/@42.6567293,21.150071,17z/data=!4m10!1m2!2m1!1sRobert+Doll+99,+Prishtina+10000,+Kosovo!3m6!1s0x13549eef09133ead:0xac866d616b3c71f!8m2!3d42.6567295!4d21.1544482!15sCidSb2JlcnQgRG9sbCA5OSwgUHJpc2h0aW5hIDEwMDAwLCBLb3Nvdm-SAQRiYW5r4AEA!16s%2Fg%2F12vrjfm99?entry=ttu" target="_blank" rel="noopener noreferrer" className="font-regular hover:underline ">
                          {t('adress')}
                      </a>
                            </li>
                        </ul>
                    </div>
                </div>
                   
                    </div>

            </div>
            <div className="relative mt-[25%] md:mt-[10%] lg:mt-[0%] bottom-0 landing-font">
                <Footer landing="landing" />
            </div>
        </div>
    )
}
export default Contact
