import React, { useState } from "react";
import { useTranslation } from 'react-i18next'
import { useFormik } from "formik";
import Background from "../../assets/images/raiffeisen-bg.png";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import InsurexLogoX from "../../assets/images/insurex-logo-x-green.png";



const initialValues = {
  newPassword: "",
  confirmPassword: "",

};

const onSubmit = (values) => {
};


const validate = (values) => {
  let errors = {};
  const validationMessages = {
    required: "Password is required!",
    minLength: "Password must be at least 8 characters long!",
    uppercase: "Password must contain at least one uppercase letter!",
    symbol: "Password must contain at least one symbol!",
  };

  if (!values.newPassword) {
    errors.newPassword = validationMessages.required;
  } else if (values.newPassword.length < 8) {
    errors.newPassword = validationMessages.minLength;
  } else if (!/[A-Z]/.test(values.newPassword)) {
    errors.newPassword = validationMessages.uppercase;
  } else if (
    !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(values.newPassword)
  ) {
    errors.newPassword = validationMessages.symbol;
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = validationMessages.required;
  } else if (values.confirmPassword.length < 8) {
    errors.confirmPassword = validationMessages.minLength;
  } else if (!/[A-Z]/.test(values.confirmPassword)) {
    errors.confirmPassword = validationMessages.uppercase;
  } else if (
    !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(values.confirmPassword)
  ) {
    errors.confirmPassword = validationMessages.symbol;
  }
  return errors;

};

const

  ResetPassword = () => {
    const formik = useFormik({
      initialValues,
      validate,
      onSubmit,


    });
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState('al');

    const handleSelectChange = (event) => {
      setSelectedValue(event.target.value);
    };
    return (
      <div className="">
       <img src={Background} alt="" className="bg-[#726f6f] fixed w-full h-full object-cover overflow-hidden" />
        <div className="relative">
          <Navbar type="authentication" landing="landing" selectedValue={selectedValue} handleSelectChange={handleSelectChange} />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col items-center justify-center relative">
            <img className="pb-4 mx-auto" src={InsurexLogoX} alt="" />
            <p className="text-[#9b9b9b] pb-8 text-sm up font-regular text-center">{t('insurance_platform')}</p>
            <div className="bg-white mt-8 md:mt-8 lg:mt-2 rounded-md lg:mx-auto mb-10 px-[25px] md:px-[0px]">
              <div className="md:p-8"> 
              <div>
                <h1 className="font-bold pb-4 text-xl pl-1 font-sans md:pl-1 md:text-2xl pt-8 md:pt-0">
                  {t('reset_password')}
                </h1>
              </div>
              <div>
                <p className="text-sm md:text-md font-medium md:pl-1 text-[#282d30] font-bold  pb-4 md:pb-6 ">
                  {t('new_password')}
                </p>
                <div className="md:mx-auto">
                  <div>
                    <input className="text-[#0f1d31] bg-[#f5f6fa] font-regular p-3 w-72 md:w-[380px] h-[52px] text-sm"
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      type="password"
                      placeholder="Fjalëkalimi i ri"
                      id="password"
                      name="newPassword"
                    />
                    {formik.errors.newPassword ? (
                      <div className="text-red-600 text-xs mt-1">
                        {formik.errors.newPassword}
                      </div>
                    ) : null}
                  </div>
                  <div className="pt-4 pb-12">
                    <input
                      className="text-[#0f1d31] bg-[#f5f6fa] font-regular p-3 w-72 md:w-[380px] h-[52px] text-sm"
                      onChange={formik.handleChange}
                      value={formik.values.confirmPassword}
                      type="password"
                      placeholder="Konfirmo fjalëkalimin"
                      id="password"
                      name="confirmPassword"
                    />
                    {formik.errors.confirmPassword ? (
                      <div className="text-red-600 text-xs mt-1">
                        {formik.errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>
                </div>
              
              </div>
              <div className="flex justify-center items-center mb-10 md:mb-0">
              <button type="submit" className="w-72 md:w-[380px] h-[52px] rounded-md text-[#fff] mt-4   font-semibold uppercase bg-[#313a3c] hover:bg-[#374151]">
                {t('reset_password')}
              </button>
              </div>

            
               
              </div>
             
            </div>
          </div>
        </form>
        <div className="fixed bottom-0 w-full">
          <Footer />
        </div>
      </div>
    );
  };

export default ResetPassword;
